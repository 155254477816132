import AppDispatcher from '../utils/dispatcher/AppDispatcher'
import GAB from '../thirdparty/GAB'
import WebUtil from '../utils/WebUtil';
const uuidV4 = require('uuid/v4')

const kUserAuthChangedNotification = "kUserAuthChangedNotification";
var currentUser = null;
var deviceId = null;

class UserProvider {
  initialize = () => {
    if (!currentUser) {
      let strUser = localStorage.getItem("kCurrentUser")
      let user = strUser ? JSON.parse(strUser) : null
      currentUser = user && user.token ? user : null
    }
    deviceId = localStorage.getItem("kDeviceId")
    if (!deviceId) {
      deviceId = uuidV4()
      localStorage.setItem("kDeviceId", deviceId)
    }
  }

  userAuthChangedNotification = () => {
    return kUserAuthChangedNotification
  }

  userIsAdmin = () => {
    return currentUser.roleValue === "pearson-admin"
  }

  userHasPermission = (capability) => {
    var ret = null
    if (currentUser.roleValue === "pearson-admin") {
      ret = {capability: capability}
    }
    else if (currentUser.permissions) {
      currentUser.permissions.forEach(function(aPermission) {
        if (aPermission.capability === capability && aPermission.version === "v2") {
          ret = aPermission
        }
      })
    }

    return ret
  }

  isUserLoggedIn = () => {
    return currentUser != null
  }

  getCurrentUser = () => {
    return currentUser
  }

  getCurrentDeviceId = () => {
    return deviceId
  }

  login = (username, password, cb) => {
    GAB.login(username, password, deviceId, function(statusCode, error, response) {
      onLoginResponse(response ? response.access_token : null, cb)
    })
  }

  logout = () => {
    let token = currentUser.token
    GAB.logout(token, deviceId, function(statusCode, error, response) {
      setCurrentUser(null)
    })
  }

  fetchUsersByUIDs = (uids, callback) => {
    let token = currentUser.token
    GAB.fetchUsersByUIDs(uids, token, deviceId, callback)
  } 
  findUser = (data, callback) => {
    let token = currentUser.token
    GAB.findUser(data, token, deviceId, callback)
  } 

  isUserCanAccessQIKIKAdmin = () => {
    return currentUser.permissions.find(data => {if(data.capability == 'manage_product') return data});
  }

}
export default new UserProvider();

function onLoginResponse(token, cb) {
  if (token) {
    GAB.getUser(token, deviceId, function(error, statusCode, user) {
      GAB.getPermissions(token, deviceId, function(error, statusCode, permissions) {
        onUserReceived(token, user, permissions, cb)
      })
    })
  }
  else {
    cb(null, {status: "auth failure. could not get a token."})
  }
}

function onUserReceived (token, user, permissions, cb) {
  if (user) {
    user.token = token
    user.permissions = permissions
  }
  setCurrentUser(user)

  cb(currentUser, null)
}

function setCurrentUser(user) {
  console.log("calling set current user", user)
  currentUser = user

  let strUser = currentUser ? JSON.stringify(currentUser) : "{}"
  localStorage.setItem("kCurrentUser", strUser)
  if (!currentUser) {
    localStorage.setItem("kBatchesSearchQuery", "")
  }

  AppDispatcher.postNotification(kUserAuthChangedNotification, {user: currentUser})

}