import React from 'react';
import UserProvider from '../../Providers/UserProvider'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import BatchesList from '../BatchesList/BatchesList'
import BatchesProvider from '../../Providers/BatchesProvider'
import TextField from 'material-ui/TextField';
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Popover from 'material-ui/Popover';
import FlatButton from 'material-ui/FlatButton';
import SettingsProvider from '../../Providers/SettingsProvider'

class BatchesDashboard extends React.Component {
  state = {
    batches: [],
    searchText: localStorage.getItem("kBatchesSearchQuery"),
    pIndex: 0,
    pSize: 20,
    addBatchOptionsOpen: false
  };

  componentWillMount = () => {
    this.loadBatches(this.state.pIndex)
    AppDispatcher.addObserver("kReloadBatches", this, this.onReloadBatchesNotification)
  };

  componentWillUnmount =() => {
    AppDispatcher.removeObserver("kReloadBatches", this, this.onReloadBatchesNotification)
  };

  onReloadBatchesNotification = (notification) => {
    this.setState({
      batches: []
    })
    this.loadBatches(this.state.pIndex)
  };

  onStepBack = () => {
    if (this.state.pIndex > 0) {
      this.loadBatches(this.state.pIndex - 1)
    }
  }

  onStepForward = () => {
    if (this.state.pSize === this.state.batches.length) {
      this.loadBatches(this.state.pIndex + 1)
    }
  }

  loadBatches = (page) => {
    const THIS = this
    const user = UserProvider.getCurrentUser()
    const userId = SettingsProvider.getBool("limitToCurrentUser") ? user.uid : null

    let reqFilters = {}
    reqFilters.name = this.state.searchText

    this.setState({
      batches: []
    })

    BatchesProvider.searchBatches(userId, reqFilters, page, this.state.pSize, function(error, statusCode, batchData, filters) {
      if (statusCode === 200 && batchData) {
        if (batchData.page.pIndex === page 
          // && name === query.name) 
          && (
            ((filters.name && filters.name === reqFilters.name) || !filters.name)
          )
        )
        {
          THIS.setState({
            batches: batchData && batchData.batches ? batchData.batches : [],
            pIndex: page,
            total: batchData.page.total || 0
          })
        }
      }
    })
  }

  onSearchNow = () => {
    this.loadBatches(0)
  };

  handleSearchTextChange = (e) => {
    const value = e.target.value;
    localStorage.setItem("kBatchesSearchQuery", value)
    this.setState({
      searchText: value,
    });
  };

  render() {
    return (
      <MuiThemeProvider>
        <div>
          <br/>
          <div className="w3-padding">
          <TextField className="w3-left w3-white w3-wide w3-bar-item w3-button" 
            hintText="search"
            value={this.state.searchText}
            onChange={this.handleSearchTextChange}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                // Do code here
                ev.preventDefault();
                this.onSearchNow(ev);
              }
            }}            
          />
          <FlatButton
            className="w3-right w3-white w3-wide w3-bar-item w3-button"
            style={{zIndex:"0"}}
            label="+ ADD"
            primary={true}
            onClick={this.onAddBatchClicked}
          />

          </div>
          <br/><br/>
          <div  id="batches-list">
          {<BatchesList batches={this.state.batches} total={this.state.total} page={this.state.pIndex} pageSize={this.state.pSize} cbPrev={this.onStepBack.bind(this)} cbNext={this.onStepForward.bind(this)}/>}
          </div>
          {this.renderBatchCreateOptions()}
        </div>
        </MuiThemeProvider>
    );
  }

  //Batch Create Options PopOver
  onAddBatchClicked = (e) => {
    this.setState({
      addBatchOptionsOpen: true,
      addBatchOptionsAnchor: e.currentTarget
    })
  }

  closeAddBatchPopup = () => {
    this.setState({
      addBatchOptionsOpen: false
    })
  };

  renderBatchCreateOptions = () => {  
    var items = []

    if (UserProvider.userHasPermission("create_asset") || UserProvider.userHasPermission("create_cartridge")) {
      items.push(<MenuItem 
        primaryText="Create Empty Batch" 
        key="tsv-add-empty-batch"
        id="tsv-add-empty-batch" 
        onClick={this.onCreateEmptyBatch} />)  
    }



    let ret = <Popover
              open={this.state.addBatchOptionsOpen}
              anchorEl={this.state.addBatchOptionsAnchor}
              anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
              targetOrigin={{horizontal: 'left', vertical: 'top'}}
              onRequestClose={this.closeAddBatchPopup}
            >
              <div>
              <p className="w3-padding w3-white">Allowed Actions: </p>
                <Menu>
                  {items}
                </Menu>
              </div>
            </Popover>
  
    return ret
  };    

  //END - Batch Create Options PopOver

  onCreateEmptyBatch = (e) => {

    this.setState({
      addBatchOptionsOpen: false
    })

    let user = UserProvider.getCurrentUser()
    BatchesProvider.createEmptyBatch(user.uid, function(error, statusCode, response) {
      if (statusCode == 200 && response && response.id) {
        AppDispatcher.postNotification("kOpenBatch", response)
        return
      }
      alert(JSON.stringify(error))
    })
  }

}

export default BatchesDashboard;
