import WebUtil from '../utils/WebUtil'
import HawkHeaders from '../utils/HawkHeaders'
const uuidV4 = require('uuid/v4')

let apipaths = {
    getBatchById: "/v1/batch/id/{id}",
    getBatchByUID: "/v1/batch/uid/{uid}",
    createBatch: "/v1/batch",
    fetchBatches: "/v1/batches/fetch?pIndex={pIndex}&count={count}",
    fetchSaBatches: "/v1/batches/fetchForSaId?pIndex={pIndex}&count={count}&saId={saId}&fromDate={fd}&toDate={td}",
    fetchItems: "/v1/items/fetch?pIndex={pIndex}&count={count}",
    fetchItemsAll: "/v1/items/fetch",
    deleteBatch: "/v1/batch/{batchId}",
    updateBatch: "/v1/batch/id/{id}",
    deleteBatchItem: "/v1/item/{itemId}",
    directEnqueueTSVPayload: "/v0/direct/enqueue/{payloadType}/textpayload/{delimiter}?userId={userId}",
    v0AssetsToBatch: "/v0/batch/{batchId}/assets",
    v0CartridgeAssetsToBatch: "/v0/batch/{batchId}/cartridge-assets/links",
    assetToAssetLinkToBatch: "/v0/batch/{batchId}/asset-asset/links",
    v1ItemsToBatch: "/v1/batch/{batchId}/items",
    submitBatch: "/v1/batch/{batchId}/submit",
    v1LinksToBatch: "/v1/batch/{batchId}/links"
}

class ParkingLot {

    getBatchById = (batchId, callback) => {
        var path = apipaths.getBatchById
        path = path.replace("{id}", batchId)

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'GET') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "GET",
            url: parkinglot_url() + path,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, (statusCode, responseString) => {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            if(statusCode === 404) {
                this.getBatchByIdFromCac(batchId, callback);
            } else {
                callback(error, statusCode, response)
            }
        })     
    }
    getBatchByIdFromCac = (batchId, callback) => {
        var path = apipaths.getBatchById
        path = path.replace("{id}", batchId)

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_ca_url(), path, hawkId(), hawkSecret(), 'GET') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "GET",
            url: parkinglot_ca_url() + path,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })     
    }
    getBatchByUID = (uid, callback) => {
        var path = apipaths.getBatchByUID
        path = path.replace("{uid}", uid)

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'GET') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "GET",
            url: parkinglot_url() + path,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })     
    }

    submitBatch = (batchId, callback) => {
        var path = apipaths.submitBatch
        path = path.replace("{batchId}", batchId)

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'PUT') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "PUT",
            url: parkinglot_url() + path,
            headers: headers
        }


        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })        

    }
    addV1LinksToBatch = (type, batchId, items, callback) => {
        // if (["asset-entitlement"].indexOf(type) < 0) {
        //     callback({error: "invalid type"}, 400, null)
        //     return
        // }
        var payload = {links: items}
        var path = apipaths.v1LinksToBatch

        path = path.replace("{batchId}", batchId)
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "POST",
            url: parkinglot_url() + path,
            headers: headers, 
            body: payload
        }


        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })        
    }
    addV1ItemsToBatch = (type, batchId, items, callback) => {
        var payload = {items: items}
        var path = apipaths.v1ItemsToBatch

        path = path.replace("{batchId}", batchId)
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "POST",
            url: parkinglot_url() + path,
            headers: headers, 
            body: payload
        }


        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })        
    }

    addV0ItemsToBatch = (type, batchId, items, callback) => {
        var payload = {}
        var path = ""
        switch(type) {
            case "assets": {
                path = apipaths.v0AssetsToBatch
                payload.assets = items
                break
            }
            case "cartridge-assets": {
                path = apipaths.v0CartridgeAssetsToBatch
                payload.links = items
                break
            }
            case "asset-asset": {
                path = apipaths.assetToAssetLinkToBatch
                payload.links = items
                break
            }
            default: {
                callback({error: "invalid type"}, 400, null)
                return
            }
        }
        path = path.replace("{batchId}", batchId)
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "POST",
            url: parkinglot_url() + path,
            headers: headers, 
            body: payload
        }


        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })
    }
    
    createEmptyBatch = (userId, callback) => {
        var path = apipaths.createBatch
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };
        let batchName = uuidV4()
        let payload = {
            batch: {
              name: batchName,
              createdBy: userId,
              description: batchName
            }
          }

        let request = {
            method: "POST",
            url: parkinglot_url() + path,
            headers: headers, 
            body: payload
        }   
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })           
    }

    updateBatch = (batchId, query, callback) => {
        var path = apipaths.updateBatch
        path = path.replace("{id}", batchId)
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'PUT') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let payload = {
            batch: query
        }
        let request = {
            method: "PUT",
            url: parkinglot_url() + path,
            headers: headers, 
            body: payload
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })        

    }
    fetchBatches = (pIndex, count, query, callback) => {

        var path = apipaths.fetchBatches
        path = path.replace("{pIndex}", pIndex)
        path = path.replace("{count}", count)
        


        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let payload = {
            query: query ? query : {}
        }

        let request = {
            method: "POST",
            url: parkinglot_url() + path,
            headers: headers, 
            body: payload
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var batches = null
            var error = null
            try {
                batches = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, batches)
        })
    }

    fetchSaBatches = (pIndex, count, saId, query, fromDate, toDate, callback) => {

        var path = apipaths.fetchSaBatches
        path = path.replace("{pIndex}", pIndex)
        path = path.replace("{count}", count)
        path = path.replace("{saId}", saId)
        path = path.replace("{fd}", fromDate)
        path = path.replace("{td}", toDate)

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_ca_url(), path, hawkId(), hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let payload = {
            query: query ? query : {}
        }

        let request = {
            method: "POST",
            url: parkinglot_ca_url() + path,
            headers: headers, 
            body: payload
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var batches = null
            var error = null
            try {
                batches = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, batches)
        })
    }

    fetchBatchItems = (pIndex, count, query, callback) => {

        var path = apipaths.fetchItems
        if((pIndex === null) && (count === null)){
            path = apipaths.fetchItemsAll;
        }
        path = path.replace("{pIndex}", pIndex)
        path = path.replace("{count}", count)
        


        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let payload = {
            query: query ? query : {}
        }

        let request = {
            method: "POST",
            url: parkinglot_url() + path,
            headers: headers, 
            body: payload
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, (statusCode, responseString) => {
            var items = null
            var error = null
            try {
                items = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            console.log({items})
            if(items && items.items && items.items.length === 0) {
                this.fetchBatchItemsFromCac(pIndex, count, query, callback);
            } else {
                callback(error, statusCode, items)
            }            
        })
    }
    fetchBatchItemsFromCac = (pIndex, count, query, callback) => {

        var path = apipaths.fetchItems
        if((pIndex === null) && (count === null)){
            path = apipaths.fetchItemsAll;
        }
        path = path.replace("{pIndex}", pIndex)
        path = path.replace("{count}", count)
        


        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_ca_url(), path, hawkId(), hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let payload = {
            query: query ? query : {}
        }

        let request = {
            method: "POST",
            url: parkinglot_ca_url() + path,
            headers: headers, 
            body: payload
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var items = null
            var error = null
            try {
                items = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, items)
        })
    }

    submitTSVTextPayload = (payload, userId, payloadType, callback) => {
        if (["assets", "cartridge-assets"].indexOf(payloadType) < 0) {
            callback({error: "invalid type"}, 400, null)
            return
        }
        var path = apipaths.directEnqueueTSVPayload
        path = path.replace("{delimiter}", "tab")
        path = path.replace("{userId}", userId)
        path = path.replace("{payloadType}", payloadType)


        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'POST') 
            , Accept: "text/plain"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            , 'content-type': 'text/plain'
        };

        let request = {
            method: "POST",
            url: parkinglot_url() + path,
            headers: headers, 
            body: payload
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })


    }

    deleteBatchItem = (itemId, callback) => {

        var path = apipaths.deleteBatchItem
        path = path.replace("{itemId}",  itemId)

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'DELETE') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };


        let request = {
            method: "DELETE",
            url: parkinglot_url() + path,
            headers: headers, 
            body: null
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })
    }
    

    deleteBatch = (batchId, callback) => {

        var path = apipaths.deleteBatch
        path = path.replace("{batchId}",  batchId)

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'DELETE') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };


        let request = {
            method: "DELETE",
            url: parkinglot_url() + path,
            headers: headers, 
            body: null
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })
    }

    createBatchWithTag = (body, callback) => {
        var path = apipaths.createBatch
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };
        let payload = body

        let request = {
            method: "POST",
            url: parkinglot_url() + path,
            headers: headers, 
            body: payload
        }   
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error)
        })           

    }

    batchItemLinksToBatch = (batchId, items, callback) => {
        var payload = {links: items}
        var path = apipaths.v1LinksToBatch

        path = path.replace("{batchId}", batchId)
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(parkinglot_url(), path, hawkId(), hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "POST",
            url: parkinglot_url() + path,
            headers: headers, 
            body: payload
        }


        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
                response = response[0];
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error)
        })        
    }

}

export default new ParkingLot();

function parkinglot_url() {return process.env.REACT_APP_PARKINGLOT_PLA_URL}
function parkinglot_ca_url() {return process.env.REACT_APP_PARKINGLOT_CA_URL}
function hawkId() {return process.env.REACT_APP_PARKINGLOT_PLA_HAWK}
function hawkSecret() {return process.env.REACT_APP_PARKINGLOT_PLA_HAWK_SECRET}