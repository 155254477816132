import HealthAPI from '../thirdparty/HealthAPI';

const uuidV4 = require('uuid/v4')
var Promise = require('bluebird')



class HealthProvider {
    setCronState = (token, url) => {
        return HealthAPI.setCronState(token, url)
    }

    getSystemHealth = (token) => {
        let healthData = null

        return HealthAPI.getPipelineHealth()
            .then(function (health) {
                healthData = health
                return HealthAPI.getDispatchWorkers(token)
            })
            .then(function (workersConfig) {
                let ret = collateSystemHealthWithEnvironment(healthData, workersConfig)
                return ret
            })
    }
}

function collateSystemHealthWithEnvironment(health, workers) {
    if (health.parkingLot) {
        health.parkingLot.serviceUrl = process.env.REACT_APP_PARKINGLOT_PLA_URL
    }
    if (health.dispatcher) {
        health.dispatcher.serviceUrl = process.env.REACT_APP_DISPATCHER_URL
    }
    health.workers.forEach(function (aWorker) {
        let workerEnv = null
        workers.list.forEach(function (anEnv) {
            if (!workerEnv && anEnv.name === aWorker.name) {
                workerEnv = anEnv
            }
        })
        if (workerEnv) {
            aWorker.nodes = workerEnv.nodes
            aWorker.enabled = workerEnv.enabled
        }
    })

    let crons = []
    if (health.parkingLot && Array.isArray(health.parkingLot.crons)) {
        health.parkingLot.crons.forEach(function (aCron) {
            crons.push({
                id: "parkinglot:" + aCron.name,
                group: "ParkingLot",
                name: aCron.name,
                displayName: "parkinglot:" + aCron.name,
                status: aCron.status,
                lastDate: aCron.lastDate,
                timeSinceLastDate: aCron.timeSinceLastDate,
                serviceUrl: health.parkingLot.serviceUrl
            })
        })
    }
    if (health.dispatcher && Array.isArray(health.dispatcher.crons)) {
        health.dispatcher.crons.forEach(function (aCron) {
            crons.push({
                id: "dispatcher:" + aCron.name,
                name: aCron.name,
                group: "Dispatcher",
                displayName: "dispatcher:" + aCron.name,
                status: aCron.status,
                lastDate: aCron.lastDate,
                timeSinceLastDate: aCron.timeSinceLastDate,
                serviceUrl: health.dispatcher.serviceUrl
            })
        })
    }

    if (health.workers) {
        health.workers.forEach(function(aWorker) {
            if (Array.isArray(aWorker.crons)) {
                aWorker.crons.forEach(function(aCron) {
                    crons.push({
                        id: aWorker.name + ":" + aCron.name,
                        group: aWorker.name,
                        name: aCron.name,
                        displayName: aWorker.name + ":" + aCron.name,
                        status: aCron.status,
                        lastDate: aCron.lastDate,
                        timeSinceLastDate: aCron.timeSinceLastDate,
                        serviceUrl: aWorker.nodes[0]
                    })
        
                })
            }
        })
    }

    return { health: health, crons: crons }
}

export default new HealthProvider();

