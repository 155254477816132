import React from 'react';
import UserProvider from '../../Providers/UserProvider'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import FlatButton from 'material-ui/FlatButton';
import List from 'material-ui/List';
import ListItem from 'material-ui/List/ListItem';
import TextField from 'material-ui/TextField';
import HealthProvider from '../../Providers/HealthProvider'
import CronsList from "./CronsList"
import LinearProgress from 'material-ui/LinearProgress';

class HealthCheckDashboard extends React.Component {
  state = {
    tokenText: '',
    loading: false,
    health: null
  };

  componentWillMount = () => {
    AppDispatcher.addObserver("kCronStatusChanged", this, this.onCronStatusChanged)
  };

  componentWillUnmount =() => {
    AppDispatcher.removeObserver("kCronStatusChanged", this, this.onCronStatusChanged)
  };

  onCronStatusChanged = (notification) => {
    this.onLoadHealthNow()
  }


  onLoadHealthNow = () => {
    let THIS = this
    THIS.setState({
      loading: true
    })
    HealthProvider.getSystemHealth(this.state.tokenText)
    .then(function(health) {
      THIS.setState({health: health, loading:false})
    }).catch(function(error) {
      THIS.setState({health: null, loading:false})
      alert(error)
    })
  };

  handleTokenTextChange = (e) => {
    const value = e.target.value;
    this.setState({
      tokenText: value,
    });
  };


  render() {
    let crons = this.state.health && this.state.health.crons ? this.state.health.crons : []

    return (
      <MuiThemeProvider>
        <div>
        <br/>
        <div className="w3-padding">
        <TextField className="w3-left w3-white w3-wide w3-bar-item w3-button" 
            hintText="dispatch-token"
            value={this.state.tokenText}
            inputStyle={{ color:'#FFFFFF00' }}
            onChange={this.handleTokenTextChange}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                // Do code here
                ev.preventDefault();
                this.onLoadHealthNow(ev);
              }
            }}            
        />
        <FlatButton style={{zIndex:"0"}} className="w3-left w3-white w3-wide w3-bar-item w3-button" 
         label="Load" primary={true} 
        onClick={function() {
          this.onLoadHealthNow()
        }.bind(this)}  
        />
        <br/><br/><br/>        
        {this.state.loading ? <LinearProgress mode="indeterminate"/> : ""} 
          
        <div className="w3-padding" id="crons-list">
          {<CronsList 
              crons={crons} token={this.state.tokenText}
              />}
        </div>          
        </div>

        </div>
        </MuiThemeProvider>
    );
  }

}

export default HealthCheckDashboard;
