import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'


class PopupPresenter extends React.Component {
  state = {
      open: null
  };

  componentWillMount = () => {
  };

  componentDidUpdate = () => {
    if (this.state.open !== true && this.props.show) {
        this.setState({
            open: true
        })
    }
  }

  componentWillUnmount =() => {
  };


  render() {
    return (
        <MuiThemeProvider>
        <div>
          {this.renderPopup()}
        </div>
        </MuiThemeProvider>        
    );
  }

  onClose = () => {
    this.setState({
      open: false
    })
    this.props.onClose()
  };

  renderPopup = () => {
    let title = this.props.title || "no-title available"
    let actions = []

    actions.push(<FlatButton
        label="Done"
        primary={false}
        onClick={this.onClose}
      />
    )

    let  ret = <Dialog
          title={title}
          actions={actions}
          modal={true}
          style={{whiteSpace:"pre", fontFamily: "monospace"}}
          contentStyle={{width: "90%", maxWidth: "none", height:"80%", maxHeight:"none"}}
          autoDetectWindowHeight={true}
          open={this.state.open}
          onRequestClose={this.onClose}
          autoScrollBodyContent={true}
        >
            <div>
                {this.props.content}
            </div>


        </Dialog>
  
        return ret
  };  
}



export default PopupPresenter;
