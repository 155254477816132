import QikikAPI from '../thirdparty/QikikAPI';
class QikikAdminProvider {

  getServiceDirectory = () => {
    // if(localStorage.getItem('serviceDirectory'))
    // return;
    return QikikAPI.getServiceDirectory()
      .then(function (response) {
          localStorage.setItem('serviceDirectory', JSON.stringify(response[0]));
      })
  }

  getCourseList = (idx) => {
    // if(localStorage.getItem('serviceDirectory')){
    //      return QikikAPI.getCourses(idx);
    // }else{
     
      return QikikAPI.getServiceDirectory()
      .then(function (response) {
          localStorage.setItem('serviceDirectory', JSON.stringify(response[0]));
          return QikikAPI.getCourses(idx);
      })
    // }
  }

  createCourse = (body, callback) => {
    return QikikAPI.createCourse(body, callback);
  } 

  deleteCourse = (body, callback) => {
    return QikikAPI.deleteCourse(body, callback);
  } 

  editCourse = (body, courseId, callback) => {
    return QikikAPI.editCourse(body, courseId, callback);
  } 

  getCourseById = (courseId, callback) => {
    return QikikAPI.getCourseById(courseId, callback);
  }

  createCartridge = (body, callback) => {
    return QikikAPI.createCartridge(body, callback);
  }

  linkCartridge = (body, callback) => {
    return QikikAPI.linkCartridge(body, callback);
  }

  submitToTOC = (body, callback) => {
    return QikikAPI.submitToTOC(body, callback);
  }

  tocTaskStatus = (body, callback) => {
    return QikikAPI.tocTaskStatus(body, callback);
  }

  getFullTocTree = (tocId, callback) => {
    return QikikAPI.getFullTocTree(tocId, callback);
  }

  linkToCourse = (uuid, courseId, callback) => {
    return QikikAPI.linkToCourse(uuid, courseId, callback);
  }

  updatePanel = (tocId, nodeId, body, callback) => {
    return QikikAPI.updatePanel(tocId, nodeId, body, callback);
  }
  
  updateTOC = (tocId, body, callback) => {
    return QikikAPI.updateTOC(tocId, body, callback);
  }

  publishADAP = (body, callback) => {
    return QikikAPI.publishADAP(body, callback);
  }

  getCountries = (callback) => {
    return QikikAPI.getCountries(callback);
  }

  fetchAssetDetails = (body, callback) => {
    return QikikAPI.fetchAssetDetails(body, callback);
  }

  editAssetDetails = (assetId, body, callback) => {
    return QikikAPI.editAssetDetails(assetId, body, callback);
  }

  removeAssetDetails = (tocId, nodeId, callback) => {
    return QikikAPI.removeAssetDetails(tocId, nodeId, callback);
  }

  reorderAssetDetails = (tocId, nodeId, orderNumber, callback) => {
    return QikikAPI.reorderAssetDetails(tocId, nodeId, orderNumber, callback);
  }

  insertNode = (id, parentNodeId, body, callback) => {
    return QikikAPI.insertNode(id, parentNodeId, body, callback);
  }
}
export default new QikikAdminProvider();
