import React from 'react';
import QikikAdminProvider from '../../providers/QikikAdminProvider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import {map} from 'lodash';
import axios from 'axios';
import BatchesProvider from '../../../../Providers/BatchesProvider'
import HawkHeaders from '../../../../utils/HawkHeaders'

export default class AssetsPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      offset: 0,
      src: null,
      selectedIndex: 0,
      asset: null,
      course: {},
      batch: {}
    };

    this.onPreviewClick = this.onPreviewClick.bind(this);
  }

  componentWillMount() {
    //Delay in opening the popup as it takes few seconds to get the file uploaded in S3. 
    // Immediately opening the file downloads it instead of opening the html
    setTimeout(() => {
      this.setState({isOpen: this.props.open});
    }, 2000);
    
  }

  componentDidMount() {
    let {previewUrls, assets} = this.props.data;
    if (previewUrls && previewUrls.length > 0) {
      this.setState({src: previewUrls[0], asset: assets[0]});
    }
  }

  handleClick(offset) {
    this.setState({ offset });
  }



  onPreviewClick = (event) => {
    let index = event.target.value;
    let {previewUrls} = this.props.data;
    let {assets} = this.props.data;
    this.setState({src: previewUrls[index], selectedIndex: index, asset: assets[index]});
  }

  generatePulseAuthorization = (uri, method) => {
    const authorization_header = HawkHeaders.generateMacHeader(process.env.REACT_APP_PARKINGLOT_PLA_URL, uri, process.env.REACT_APP_PARKINGLOT_PLA_HAWK, process.env.REACT_APP_PARKINGLOT_PLA_HAWK_SECRET, method);
    const config = { headers: { 'Content-Type': 'application/json','Authorization': authorization_header}}
    return config;
  }

  getCourseById = (response) => {
    try {
      if(response){
        this.setState({
            course: response
        }, () => {
          console.log("course by Id", response);
        })
      }
    } catch(err){
      console.log(err);
    }
  }

  tocCartridgeAssets = (response) => {
    console.log("ParkingLot Provider:::", response);
    let toc_nodes = [{
        "type": "toc-nodes",
        "assetdocxUID": `${response.metadata.assetdocxUID}`,
        "productModelId": `${this.props.node.productModelId}`,
        "tocId": `${this.props.node.tocId}`,
        "parentNodeId": `${this.props.node.nodeId}`
    }]
    this.setState({"tocCartridgeAssetsUID":response.uid});
    BatchesProvider.batchItemAndLinks(response.batchId, toc_nodes, this.tocNodes)
  }


  tocNodes = (response) => {
    let adapt_toc = [{
        "type": "adapt-toc",
        "tocNodesUID": `${response.uid}`,
        "tocCartridgeAssetsUID": `${this.state.tocCartridgeAssetsUID}`,
        "productModelId": `${this.props.node.productModelId}`,
        "tocId": `${this.props.node.tocId}`,
        "parentNodeId": `${this.props.node.nodeId}`
    }]
    BatchesProvider.batchItemAndLinks(response.batchId, adapt_toc, this.adaptToc)
  }

  adaptToc = (response) => {
    if(response){
      localStorage.setItem('batchId', response.batchId);
      console.log("reponse adapt toc", response);
      this.hideLoader();
      this.props.setIsBatchNotSuccess(response.batchId);
      this.props.onClose();
    }
  }

  onCreateAsset = async () => {
    let {assets} = this.props.data;
    console.log("assetPreview", this.props);
    let config = { headers: { 'content-type': 'application/json'}};
    this.showLoader();
    await QikikAdminProvider.getCourseById(this.props.courseId, this.getCourseById);
    
    let response = await axios.post('/api/create/docx/upload', {filename: localStorage.getItem('docxFileName')}, config);
    localStorage.setItem('modified_s3_url', response.data.Location);

    if(localStorage.getItem("batchId") == null || localStorage.getItem("batchId") == undefined || localStorage.getItem("batchId") == ""){
      await this.props.postBatch();
    }

    const batchitem_header = await this.generatePulseAuthorization(`/v1/batch/${localStorage.getItem("batchId")}/items`, 'POST');
    let batchitem_data = {
        "items": [{
            "type": "assetdocx",
            "metadata": {
                "url": `${localStorage.getItem("modified_s3_url")}`,
                "countryCode": "IN"
            }
        }]
    }
    let batchResponse = await axios.post(`${process.env.REACT_APP_PARKINGLOT_PLA_URL}/v1/batch/${localStorage.getItem("batchId")}/items`, batchitem_data, batchitem_header);
    let batch_toc_cartridge_assets1 = [{
        "type": "toc-cartridge-assets",
        "assetdocxUID": `${batchResponse.data.created[0].uid}`,
        "cartridgeId": `${this.state.course.cartridgeIds[0]}`
    }]
    BatchesProvider.batchItemAndLinks(batchResponse.data.created[0].batchId, batch_toc_cartridge_assets1, this.tocCartridgeAssets);
    // axios.post('/api/create/asset', assets, config).then((response) => {
    //   if (response.data && response.data.status == 'failed') {
    //     this.props.setIsBatchNotSuccess(response.data.batchId);
    //   }else {
    //     this.props.setDisablePublish(false);
    //   } 
    //   //TODO: use the response and the assets to TOC before publish     
    //   console.log('create asset response', response);
    // });
  }

  purgePreviewContents = () => {
    let {previewFolderNames} = this.props.data;
    let config = { headers: { 'content-type': 'application/json'}};
    axios.post('/api/purge/previewcontent', previewFolderNames, config).then((response) => {
    });
  }

  onClose = () => {
    this.purgePreviewContents();
    this.props.onClose();
  }

  hideLoader() {
    document.getElementById("loader").style.display = "none";
  }

  showLoader() {
    document.getElementById("loader").style.display = "block";
  }

  render() {
    console.log('this.state.asset', this.state.asset);
    return(
      <MuiThemeProvider>
        <div>
          <Dialog
          title={<Header previewUrls={this.props.data.previewUrls} onPreviewClick={this.onPreviewClick} selectedIndex={this.state.selectedIndex}/>}
          modal={true}
          actions={<Footer onClose={this.onClose} onCreateAsset={this.onCreateAsset} />}
          style={{whiteSpace:"pre", fontFamily: "monospace"}}
          contentStyle={{width: "90%", maxWidth: "none", height:"100%", maxHeight:"100%"}}
          autoDetectWindowHeight={true}
          open={this.state.isOpen}
          onRequestClose={this.onClose}
          autoScrollBodyContent={true}        >
            <div>
                <Body src={this.state.src} asset={this.state.asset}/>
            </div>
        </Dialog>
        </div>
        </MuiThemeProvider>                           
    )
  }
}

class AssetPreviewer extends React.Component {

  render(){
    return (
      <div>
        <Body />
      </div>
    )
  }

}

const Header = ({previewUrls, onPreviewClick, selectedIndex=0}) => {
  return (
    <div>
      <span style={{"height": "45px", "paddingLeft": "28px", "paddingTop":"10px"}}> Asset Preview </span>
      <select style={{width: "100px", marginLeft: "10px"}} onChange={onPreviewClick}> 
      {
        map(previewUrls, (url, index) => <option key={index} value={index}> {index+1}</option>)
      }
      </select>
    </div>
  )
}

const Body = ({src, asset}) => {
  return (
    <div>
    <iframe style={{"height":"342px", "width": "830px", "float":"left", "scrollBehavior" : "auto" }} src={src}/>
    {asset && <div style={{"width":"300px", "float":"right"}} >
      <table style={{"border": "1px solid #4e5bda", "width": "100%", "padding":"2px"}}>
          <tr colspan="2"><td style={{"align": "center"}}>Metadata</td></tr>
          <tr><td>Name</td><td>{asset.name}</td></tr>
          <tr><td>Description</td><td>{asset.description}</td></tr>
          <tr><td>Type</td><td>{asset.activityData.type}</td></tr>
          <tr><td>Content URN</td><td>{asset.contentUrn}</td></tr>
          <tr><td>Country Code</td><td>{asset.countryCode}</td></tr>
          <tr><td>Purposes</td><td>{asset.hasOwnProperty("purposes") ? asset.purposes.join(",") : ''}</td></tr>
          <tr><td>Topic Name</td><td>{asset.topic.name}</td></tr>
          <tr><td>Sub-topic Name</td><td>{asset.topic.subtopic.name}</td></tr>
        </table>
     </div>}
    </div>
  )
}

const Footer = ({onClose, onCreateAsset}) => {
  return (
    <div>
      <FlatButton label="Create Asset" primary={false} onClick={onCreateAsset} style={{"backgroundColor": "#4caf50"}}/>
      &nbsp;&nbsp;&nbsp;<FlatButton label="Close" primary={false} onClick={onClose} style={{"backgroundColor": "#3f51b5"}} />
    </div>
  )
}