import React from 'react';
import {getThemes} from '../../utils/utils'
import QikikAdminProvider from '../../providers/QikikAdminProvider';
import Dialog from 'material-ui/Dialog';

export default class SubtopicPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tocId: this.props.node.tocId, 
      nodeId: this.props.node.nodeId,
      parentNodeId: this.props.node.parentNodeId,
      name: this.props.node.name.en, 
      description: this.props.node.description.en,
      thumbnail: this.props.node.metadata.thumbnail ? this.props.node.metadata.thumbnail : '',
      colorTheme: this.props.node.metadata.themes,
      tags: this.props.node.hasOwnProperty("tags") ? this.props.node.tags.join("|") : [],
      isNewAsset: false,
      assetType: ''
    };
    this.title = {'docx': 'Upload Docx file', 'video': 'Upload Video file'}
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      name: nextProps.node.name.en,
      description: nextProps.node.description.en,
      parentNodeId: nextProps.node.parentNodeId,
      thumbnail: nextProps.node.metadata.thumbnail ? nextProps.node.metadata.thumbnail : '',
      colorTheme: nextProps.node.metadata.themes ? nextProps.node.metadata.themes : '',
      tags: nextProps.node.tags ? nextProps.node.tags.join("|") : ''
     });
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value}, () => console.log(this.state.name));
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.showLoader();
    let reqBody = {
                    "name": {
                        "en": this.state.name
                    },
                    "description": {
                      "en": this.state.description
                    },
                    "metadata": {
                      "themes": this.state.colorTheme,
                      "thumbnail": this.state.thumbnail
                    },
                    "tags": this.state.tags.split("|")
                  }
    QikikAdminProvider.updatePanel(this.state.tocId, this.state.nodeId, reqBody, this.onUpdateSubtopicPanel);
  }

  onUpdateSubtopicPanel = (response) => {
    this.props.stateupdate();
    if(response && response.id){
        this.props.updateToc(response.tocId);
    }
  }

  addAsset = (event, assetType) => {
    console.log(event.target.id);
    //this.setState({isNewAsset: true, assetType, title: this.title[assetType] })
    this.props.addAsset(event.target.id);
  }

  addSubtopic = (event) => {
    alert("add sub topic");

    event.preventDefault();
    let reqBody = {
                    "name": {
                        "en": this.state.name
                    },
                    "description": {
                      "en": this.state.description
                    },
                    "type": "container",
                    "label": "subtopic",
                    "metadata": {
                      "themes": this.state.colorTheme,
                      "thumbnail": this.state.thumbnail
                    },
                    "tags": this.state.tags.split("|")
                  }
    QikikAdminProvider.insertNode(this.state.tocId, this.state.parentNodeId, reqBody, this.onAddSubtopicPanel);

  }

  onAddSubtopicPanel = (response) => {
    if(response && response.id){
        console.log(this.props.uuid);
        this.props.updateToc(this.props.uuid);
    }
  }

  onClose = () => {
    this.setState({isNewAsset: false, assetType: '', title: ''});
  }

  render() {
    let {isNewAsset, title, assetType} = this.state;
    console.log('isNewAsset', isNewAsset)
    return (
      <div className="p-20">
        <h3>Sub-topic Panel</h3>
        <div className="w-50">
          <form  onSubmit={this.handleSubmit}>
            <label for="fname">Name</label>
            <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />

            <label for="lname">Description</label>
            <input type="text" name="description" value={this.state.description} onChange={this.handleChange} />

            <label for="colorTheme">Color Theme</label>
            <select id="colorTheme" name="colorTheme" value={this.state.colorTheme} onChange={this.handleChange}>
              {getThemes()}
            </select>

            <label for="thumbnail">Thumbnail</label>
            <input type="text" name="thumbnail" value={this.state.thumbnail} onChange={this.handleChange} />
            
            <label for="tags">Tags</label>
            <input type="text" name="tags" value={this.state.tags} onChange={this.handleChange} />
            
            <br />
            <input type="submit" value="Submit" />
          </form>
        </div>
        {isNewAsset && <FileSelector title={title} assetType={assetType} onClose={this.onClose}/>}
        <div className="button-container">
          <button id="addAsset" onClick={this.addAsset} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Add asset [docx]</button>
          <button id="addExistingAsset" onClick={this.addAsset} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Add existing asset [assetId]</button>
          {/* <button id="addAssetViaURL" onClick={this.addAsset} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Add asset via URL</button> */}
          <button id="uploadVideo" onClick={this.addAsset} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Upload video</button>
          {/* <button id="addSubtopic" onClick={this.addSubtopic} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Add Sub-Topic</button> */}
        </div>
      </div>
    );
  }
}

const FileSelector = ({assetType, title, onClose}) => {
  return (
    <Dialog
          title={title}
          modal={true}
          style={{whiteSpace:"pre", fontFamily: "monospace"}}
          contentStyle={{width: "90%", maxWidth: "none", height:"80%", maxHeight:"none"}}
          autoDetectWindowHeight={true}
          onRequestClose={onClose}
          autoScrollBodyContent={true}
          open={true}
        >
      <input type='file' name="myfile"/> 
    </Dialog>
  )
}