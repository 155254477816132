import React from 'react';
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import IconButton from 'material-ui/IconButton';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import ActionInfo from 'material-ui/svg-icons/action/info-outline';
import ActionStop from 'material-ui/svg-icons/action/pan-tool';
import ActionStart from 'material-ui/svg-icons/action/rowing';
import ActionEdit from 'material-ui/svg-icons/editor/mode-edit';
import HealthProvider from '../../Providers/HealthProvider';
import "./cronslist.css"

import {
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import { FlatButton } from 'material-ui';
const uuidV4 = require('uuid/v4')
//https://v0.material-ui.com/#/components/table
class CronListItem extends React.Component {

  state = {
  }

  componentWillMount = () => {
  };

  componentWillUnmount =() => {
  };

  startCron = (e) => {
    let cron = this.props.cron
    let token = this.props.token

    HealthProvider.setCronState(token, cron.serviceUrl + "/environment/cron/" + cron.name + "/start")
    .then(function(response) {
      AppDispatcher.postNotification("kCronStatusChanged", cron)
    })
  }

  stopCron = (e) => {
    let cron = this.props.cron
    let token = this.props.token
    HealthProvider.setCronState(token, cron.serviceUrl + "/environment/cron/" + cron.name + "/stop")
    .then(function(response) {
      AppDispatcher.postNotification("kCronStatusChanged", cron)
    })
  }



  render() {
    let cron = this.props.cron

    let actions = []

    actions.push(
      <FlatButton label="Start" style={{zIndex:"0"}} onClick={this.startCron} />
    )
    actions.push(
      <FlatButton label="Stop" style={{zIndex:"0"}} onClick={this.stopCron} />
    )

    let timeSinceLast = cron ? (cron.timeSinceLastDate + " s") : "n/a"

    return (
      <TableRow key={cron.id}>
        <TableRowColumn className="cronid">{cron.group}</TableRowColumn>
        <TableRowColumn className="cronname">{cron.name}</TableRowColumn>
        <TableRowColumn className="crondescription">{timeSinceLast}</TableRowColumn>
        <TableRowColumn className="cronstatus">{cron.status}</TableRowColumn>
        <TableRowColumn className="cronactions">{actions}</TableRowColumn>
      </TableRow>
    )
  }
}

export default CronListItem;