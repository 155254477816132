import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import BatchesProvider from '../../Providers/BatchesProvider'
import UserProvider from '../../Providers/UserProvider'
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import {blue300, indigo900} from 'material-ui/styles/colors';

import Chip from 'material-ui/Chip';
const styles = {
  chip: {
    margin: 4,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

class BatchCollaborators extends React.Component {
  state = {
    searchText: "",
    users: null,
    searchResults: null
  };

  handleRequestDelete = (user) => {
    if (!BatchesProvider.canRemoveBatchCollaborators(this.props.batch)) {
      alert("you don't have permission to remove collaborators from this batch")
      return
    }

    if (user.userId === this.props.batch.createdBy) {
      alert(user.name + " is owner of the batch")
      return
    }
    var newCollaborators = []
    if (this.props.batch.collaborators) {
      newCollaborators = newCollaborators.concat(this.props.batch.collaborators)
    }

    if (newCollaborators.indexOf(user.userId) < 0) {
      alert("error: user is not a collaborator.")
      return
    }
    newCollaborators.splice(newCollaborators.indexOf(user.userId), 1)

    let THIS = this
    BatchesProvider.updateBatchCollaborators(this.props.batch.id, newCollaborators, function(error, statusCode, updatedBatch) {
      if (statusCode === 200 && updatedBatch.id === THIS.props.batch.id) {
        THIS.props.batch.collaborators = updatedBatch.collaborators
        var finalUsers = THIS.state.users
        finalUsers.splice(finalUsers.indexOf(user), 1)
        THIS.setState({
          users: finalUsers
        })
      }
    })
  }

  handleRequestAdd = (user) => {
    if (!BatchesProvider.canEditBatchCollaborators(this.props.batch)) {
      alert("you don't have permission to add collaborators to this batch")
      return
    }
    if (user.userId === this.props.batch.createdBy) {
      alert(user.name + " is owner of the batch")
      return
    }
    var newCollaborators = []
    if (this.props.batch.collaborators) {
      newCollaborators = newCollaborators.concat(this.props.batch.collaborators)
    }

    if (newCollaborators.indexOf(user.userId) >= 0) {
      alert("user already a collaborator")
      return
    }
    newCollaborators.push(user.userId)

    let THIS = this
    BatchesProvider.updateBatchCollaborators(this.props.batch.id, newCollaborators, function(error, statusCode, updatedBatch) {
      if (statusCode === 200 && updatedBatch.id === THIS.props.batch.id) {
        THIS.props.batch.collaborators = updatedBatch.collaborators
        var finalUsers = []
        if (THIS.state.users) {
          finalUsers = finalUsers.concat(THIS.state.users)
        }
        finalUsers.push(user)
        THIS.setState({
          users: finalUsers
        })
      }
    })
  }

  componentDidMount = () => {
    this.loadBatchCollaborators()
  }

  loadBatchCollaborators = () => {
    let THIS = this
    BatchesProvider.loadBatchCollaborators(this.props.batch.id, function(error, statusCode, batch, list) {
      var users = []
      if (!error && statusCode === 200 && batch && batch.id === THIS.props.batch.id) {
        users = list
      }
      THIS.props.batch.collaborators = []
      users.forEach(function(u) {
        THIS.props.batch.collaborators.push(u.userId)
      })
      THIS.setState({
        users: users
      })

    })
  }

  onSearchNow = () => {
    let value = this.state.searchText
    let THIS = this
    UserProvider.findUser(value, function(error, statusCode, list) {
      var users = []
      if (!error && statusCode === 200 && Array.isArray(list) && list.length > 0) {
        users = list
      }
      THIS.setState({
        searchResults: list
      })
    })
  };

  handleSearchTextChange = (e) => {
    const value = e.target.value;
    this.setState({
      searchResults: null,
      searchText: value
    });
  };


  render() {
    let strJSON = JSON.stringify(this.props.batch, null, 4)
    var status_message = "loading..."
    var chips = ""
    if (this.state.users) {
      status_message = this.state.users.length === 0 ? "No Collaborators" : ""
      chips = this.collaboratorChips()
    }

    let searchResults = this.state.searchResults
    var searchResultDiv = ""
    if (searchResults) {
      searchResultDiv = <div>
      <div>Search Results (click to add)</div>  
         <div style={styles.wrapper}>
            {this.searchResultChips()}
          </div>
      </div>
    }


    return (
        <MuiThemeProvider>
        <div className="w3-white">
          <div style={{width:"100%"}}>
            <TextField 
              style={{width:"100%"}}
              hintText="type username or email to add as collaborators"
              value={this.state.searchText}
              onChange={this.handleSearchTextChange}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                  this.onSearchNow(ev);
                }
              }}            
          />

          </div>
          {searchResultDiv}
          <div>
            <div>Current Collaborators:</div>
            <div style={styles.wrapper}>
              {chips}
            </div>
          </div>

          <div style={{height:"100px"}} className="w3-center">
            {status_message}
          </div>
        </div>
        </MuiThemeProvider>        
    );
  }

  searchResultChips = () => {
    var chips = []
    if (!this.state.searchResults) {
      return chips
    }
    if (this.state.searchResults.length === 0) {
      return "no matching users"
    }

    let THIS = this
    this.state.searchResults.forEach(function(aUser) {

      let aChip = <Chip
                  key={aUser.userId}
                  backgroundColor={blue300}
                  onClick={function(e) {
                    this.handleRequestAdd(aUser)
                  }.bind(THIS)}
                  style={styles.chip}
                >
                {aUser.name}
              </Chip>

        chips.push(aChip)
    })

    return chips

  }

  collaboratorChips = () => {
    var chips = []
    if (!this.state.users) {
      return chips
    }
    let THIS = this
    this.state.users.forEach(function(aUser) {
      let aChip = <Chip
            key={aUser.userId}
            onRequestDelete={function(e) {
              this.handleRequestDelete(aUser)
            }.bind(THIS)}
            style={styles.chip}
            >
              {aUser.name}
          </Chip>

        chips.push(aChip)
    })

    return chips
  }
}



export default BatchCollaborators;
