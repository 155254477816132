import React from 'react';
import QIKIK_CONST from '../constants/constants';

export const getCountryList = () =>{
    return QIKIK_CONST.COUNTRY.map((data, i)=>{return  <option key={i} value={data.code}>{data.name}</option>})
}

export const getThemes = () => {
    return QIKIK_CONST.THEMES.map((data, i)=>{return  <option key={i} value={data}>{data}</option>})
}

export const getAssetTypeList = () =>{
    return QIKIK_CONST.ASSETTYPE.map((data, i)=>{return  <option key={i} value={data}>{data}</option>})
}

export const getDateTime = () => {

    let d = new Date;
    let formatCurentDateAndTime = (number) => {
        if(number <= 9) {
          return '0' + number;
        } else {
          return number;
        }
    }
    
    let curentDateAndTime = [ '-',
      formatCurentDateAndTime(d.getMonth()+1),
      formatCurentDateAndTime(d.getDate()),
      formatCurentDateAndTime(d.getFullYear()),
      formatCurentDateAndTime(d.getHours()),
      formatCurentDateAndTime(d.getMinutes()),
      formatCurentDateAndTime(d.getSeconds())].join('');

    return curentDateAndTime;
}