import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import SettingsProvider from '../../Providers/SettingsProvider'
import crypto from "crypto"

import BatchesProvider from '../../Providers/BatchesProvider'
import UserProvider from '../../Providers/UserProvider'

const passcode = "349b83c572"
//https://v0.material-ui.com/#/components/toggle
const styles = {
  block: {
    maxWidth: 250,
  },
  toggle: {
    marginBottom: 16,
    width: "40%",
  },
  thumbOff: {
    backgroundColor: '#ffcccc',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: 'red',
  },
  trackSwitched: {
    backgroundColor: '#ff9d9d',
  },
  labelStyle: {
    color: 'red',
  },
};

class AdminSettings extends React.Component {
  state = {
    txtCode: "",
    limitToCurrentUser: SettingsProvider.getBool("limitToCurrentUser"),
    enablAllBatchItemsView: SettingsProvider.getBool("enablAllBatchItemsView"),
    controlsEnabled:false
  };

  
  componentDidMount = () => {
  }

  handleSearchTextChange = (e) => {
    const value = e.target.value;
    var cipher = crypto.createCipher("aes-256-ctr", "d6F3Efeq")
    var crypted = cipher.update(value,'utf8','hex')

    this.setState({
      txtCode: value,
      controlsEnabled: (crypted === passcode)
    });


    console.log(crypted)
  };

  handleToggle() {
    this.setState({allBatchesToggle: !this.state.allBatchesToggle});
    console.log(this.state.allBatchesToggle)
  }

  render() {

    return (
        <MuiThemeProvider>
        <div className="w3-white">
          <div>
            Code: 
            <TextField 
              className="w3-center"
              style={{width:"20%"}}
              // type="password"
              // autoComplete='off'
              inputStyle={{ textAlign: 'center', color:'white' }}
              value={this.state.searchText}
              onChange={this.handleSearchTextChange}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                }
              }}            
          />
          </div>
          <p></p>
          <p></p>
          <div>
            <table>
              <tr>
                <td>Limit to current user</td>
                <td>
                  <Toggle
                    thumbStyle={styles.thumbOff}
                    disabled={!this.state.controlsEnabled}
                    trackStyle={styles.trackOff}
                    thumbSwitchedStyle={styles.thumbSwitched}
                    trackSwitchedStyle={styles.trackSwitched}
                    labelStyle={styles.labelStyle}
                    toggled={this.state.limitToCurrentUser}
                    onToggle={function() {
                      let newValue = !this.state.limitToCurrentUser
                      SettingsProvider.setBool("limitToCurrentUser", newValue)
                      this.setState({limitToCurrentUser: newValue})
                    }.bind(this)}
                  />                  
                </td>
              </tr>
              <tr>
                <td>Show menu (all batch items)</td>
                <td>
                  <Toggle
                    disabled={!this.state.controlsEnabled}
                    // thumbStyle={styles.thumbOff}
                    // trackStyle={styles.trackOff}
                    // thumbSwitchedStyle={styles.thumbSwitched}
                    // trackSwitchedStyle={styles.trackSwitched}
                    // labelStyle={styles.labelStyle}
                    toggled={this.state.enablAllBatchItemsView}
                    onToggle={function(){
                      let newValue = !this.state.enablAllBatchItemsView
                      SettingsProvider.setBool("enablAllBatchItemsView", newValue)
                      this.setState({enablAllBatchItemsView: newValue})

                    }.bind(this)}
                  />                  
                </td>
              </tr>
            </table>
          </div>

        </div>
        </MuiThemeProvider>        
    );
  }
}



export default AdminSettings;
