import React from 'react';
import {
    TableHeaderColumn,
    TableRow
  } from 'material-ui/Table';

let preColumns = [{
        cssClass: "itemid",
        name: "ID",
        tag: "itemId"
    }, {
        cssClass: "itemUID",
        name: "UID",
        tag: "itemUID"
    }, {
        cssClass: "itemtype",
        name: "Type",
        tag: "itemType"
    }, {
        cssClass: "itemname",
        name: "Name:",
        tag: "itemName"
    }, {
        cssClass: "itemstatus remove-overflow",
        name: "Status",
        tag: "itemStatus"
    }
]
let postColumns = [{
        cssClass: "itemstatus",
        name: "Attempt(s)",
        tag: "itemAttempts"
    }, {
        cssClass: "itemstatus",
        name: "Result(s)",
        tag: "itemResult"
    }, {
        cssClass: "itemactions",
        name: "Actions",
        tag: "actions"
    }
]

let columnConfigs = {
    "default": [
        {
            cssClass: "itemid",
            name: "ID",
            tag: "itemId"
        }, {
            cssClass: "itemUID",
            name: "UID",
            tag: "itemUID"
        }, {
            cssClass: "itemtype",
            name: "Type",
            tag: "itemType"
        }, {
            cssClass: "itemstatus remove-overflow",
            name: "Status",
            tag: "itemStatus"
        }, {
            cssClass: "itemname",
            name: "Name:",
            tag: "itemName"
        }, {
            cssClass: "itemstate",
            name: "(state)",
            tag: "itemState"
        },
        {
            cssClass: "itemstatus",
            name: "Attempt(s)",
            tag: "itemAttempts"
        }, {
            cssClass: "itemstatus",
            name: "Result(s)",
            tag: "itemResult"
        }, {
            cssClass: "itemactions",
            name: "Actions",
            tag: "actions"
        }
    ],
    "cartridge": [
        {
            cssClass: "itemid",
            name: "ID",
            tag: "itemId"
        },{
            cssClass: "itemid",
            name: "CARTRIDGE ID",
            tag: "itemcartridgeID"
        }, { //added
            cssClass: "itemUID",
            name: "CARTRIDGE UID",
            tag: "itemUID"
        }, {
            cssClass: "itemtype",
            name: "Type",
            tag: "itemType"
        }, {
            cssClass: "itemname",
            name: "Name:",
            tag: "itemName"
        }, {
            cssClass: "itemstatus remove-overflow",
            name: "Status",
            tag: "itemStatus"
        },{
            cssClass: "itemname",
            name: "Subject",
            tag: "itemSubject"
        }, {
            cssClass: "itemname",
            name: "Grade",
            tag: "itemGrade"
        }, {
            cssClass: "itemname",
            name: "Schools",
            tag: "itemSchools"
        },{
            cssClass: "itemstatus",
            name: "Attempt(s)",
            tag: "itemAttempts"
        }, {
            cssClass: "itemstatus",
            name: "Result(s)",
            tag: "itemResult"
        }, {
            cssClass: "itemactions",
            name: "Actions",
            tag: "actions"
        }
    ],
    "asset": [{
            cssClass: "itemid",
            name: "ID",
            tag: "itemId"
        }, {
            cssClass: "itemtype",
            name: "Type",
            tag: "itemType"
        }, {
            cssClass: "assetid",
            name: "Asset ID",
            tag: "itemassetID"
        },{
            cssClass: "itemUID",
            name: "Asset UID",
            tag: "itemUID"
        }, {
            cssClass: "itemname",
            name: "Name:",
            tag: "itemName"
        }, {
            cssClass: "itemstatus remove-overflow",
            name: "Status",
            tag: "itemStatus"
        },{
            cssClass: "itemcontent",
            name: "Content Type",
            tag: "contentType"
        }, {
            cssClass: "itemstatus",
            name: "Section",
            tag: "itemSection"
        }, {
            cssClass: "itemstatus",
            name: "Topic",
            tag: "itemTopic"
        }, {
            cssClass: "itemstatus",
            name: "Sub Topic",
            tag: "itemSubtopic1"
        }, {
            cssClass: "itemstatus",
            name: "Purpose",
            tag: "itemPurpose"
        }, {
            cssClass: "rplus",
            name: "R+ Book Id",
            tag: "rplus"
        }, {
            cssClass: "rplus",
            name: "R+ Product Id",
            tag: "rplusprd"
        },{
            cssClass: "rplus",
            name: "R+ Product link",
            tag: "rplusprdlink"
        }, {
            cssClass: "rplus",
            name: "R+ Zip",
            tag: "rplusaol"
        },{
            cssClass: "itemstatus",
            name: "Attempt(s)",
            tag: "itemAttempts"
        }, {
            cssClass: "itemstatus",
            name: "Result(s)",
            tag: "itemResult"
        }, {
            cssClass: "itemactions",
            name: "Actions",
            tag: "actions"
        }, {
            cssClass: "itemactions",
            name: "Source URL",
            tag: "sourceUrl"
        }

    ],    
    "cartridge-asset": [
        {
            cssClass: "itemid",
            name: "ID",
            tag: "itemId"
        }, {
            cssClass: "itemtype",
            name: "Type",
            tag: "itemType"
        }, {
            cssClass: "itemUID",
            name: "CARTRIDGE ID",
            tag: "itemcartridgeID"
        }, {
            cssClass: "itemUID",
            name: "CARTRIDGE UID",
            tag: "itemcartridgeUID"
        }, {
            cssClass: "itemUID",
            name: "ASSET ID",
            tag: "itemassetID"
        }, {
            cssClass: "itemUID",
            name: "ASSET UID",
            tag: "itemassetUID"
        }, {
            cssClass: "itemname",
            name: "Name:",
            tag: "itemName"
        }, {
            cssClass: "itemstatus remove-overflow",
            name: "Status",
            tag: "itemStatus"
        }, {
            cssClass: "itemstatus",
            name: "Section",
            tag: "itemSection"
        }, {
            cssClass: "itemstatus",
            name: "Purpose",
            tag: "itemPurpose"
        }, {
            cssClass: "itemstatus",
            name: "Topic",
            tag: "itemTopic"
        }, {
            cssClass: "itemstatus",
            name: "Sub Topic",
            tag: "itemSubtopic1"
        },{
            cssClass: "itemstatus",
            name: "Attempt(s)",
            tag: "itemAttempts"
        }, {
            cssClass: "itemstatus",
            name: "Result(s)",
            tag: "itemResult"
        }, {
            cssClass: "itemactions",
            name: "Actions",
            tag: "actions"
        }
    ]    
}
class ItemsDisplayConfig {
    dataPoints = (type) => {
        return columnConfigs[type] ? columnConfigs[type] : columnConfigs["default"]
    }

    headerRow = (type) => {
        let ret = []
        this.dataPoints(type).forEach(function(point) {
            ret.push(<TableHeaderColumn className={point.className}>{point.name}</TableHeaderColumn>)
        })

        return ret
    }

}

export default new ItemsDisplayConfig();
  