import React from 'react';
import AssetsPanel from './components/ManageToc/AssetsPanel'
import ConceptPanel from './components/ManageToc/ConceptPanel1'
import SubtopicPanel from './components/ManageToc/SubtopicPanel'
import TopicPanel from './components/ManageToc/TopicPanel'
import ParentPanel from './components/ManageToc/ParentPanel'
import ToCRenderer from './components/QikToCDashboard/ToCRenderer/ToCRenderer2'
import CreateAssets from './components/ManageToc/CreateAssets';
import QikikAdminProvider from './providers/QikikAdminProvider';
import UserProvider from '../../Providers/UserProvider';
import { getDateTime } from './utils/utils';
import AssignmentProvider  from '../../Providers/AssignmentProvider';
import AdapProvider  from '../../Providers/AdapProvider';
import Loading from '../../loading.gif';

const uuidV4 = require('uuid/v4');
let swapData = [];
export default class  extends React.Component {
  intervalID;
  constructor(props) {
    super(props);
    console.log(this.props, "props");
    this.state = {value: '', nodeType: '', isPublishDisabled: true, batch:this.props.batch, counter:0, coursePlanId:this.props.course.coursePlanId};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getNodeType = this.getNodeType.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    
    this.setState({batch:true});
      this.showLoader();
      this.getData();
    
  }

  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }

  getData() {
        
        this.setState({counter:this.state.counter+1});
        console.log("counter", this.state.counter)  ;
        this.intervalID = setTimeout(this.getData.bind(this), 10000);
        this.props.tocHandler(this.intervalID,( status) =>{
          if(status=== "completed") {
            clearTimeout(this.intervalID);  
            this.setState({isPublishDisabled: false, batch:false}); 
          }
        })
        
        this.setState({isPublishDisabled: true})
      
  }
  
  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  async getNodeType(node) {
    let myToC = JSON.parse(JSON.stringify(node))
    myToC.uuid = myToC.uuid || uuidV4()
    myToC.type = "container"
    myToC.label = "ToC"
    if (node.type === 'asset') {
      this.fetchAssetDetails(node.assetId);
    }
    this.setState({ 
      node: node,
      toc: myToC,
    });
  }

  addAsset = (type) => {
    if(type != "cancel") {
      this.setState({ addAssetType: type});
    } else {
      this.setState({ addAssetType: null});
    }
  }

  publishADAP = () => {
  //  this.showLoader();
    this.swapInAPIData();
    let reqBody = {
      "cartridge": {
        "name": "qikik-" + this.props.fileName + getDateTime(),
        "createdBy": UserProvider.getCurrentUser().name
      }
    }
    QikikAdminProvider.createCartridge(reqBody, this.onCreateCartridge);
  }
  
  submitBatch = () => {
    this.showLoader();
    let toc =this.props.toc;
    //this.setState({isPublishDisabled:true, batch:true});
    let course = this.props.course;
    let courseId = course.courseId ;
    let levelId = course.levelId, subjectId= course.subjectId 
    // this.showLoader();
    AssignmentProvider.getAssignmentsByToc('pulse', toc, courseId, subjectId, levelId)
    .then( coursePlanPayload  =>{
        return  AdapProvider.publish(courseId, coursePlanPayload);
    })
    .then( coursePlanResponse =>{
      this.setState({coursePlanId:coursePlanResponse.coursePlanId});
      return new Promise( (resolve, reject) =>{
        QikikAdminProvider.editCourse({coursePlanId: coursePlanResponse.coursePlanId},course.id, (error, statusCode, response) =>{

          if(statusCode === 200){
            return resolve( response);
          } else {
            console.log(" updating course object error", error);
            return reject(error);
          }

        })
      })
    })
    .then( response => {
      this.hideLoader();
      console.log("successfully updated.", response);
    })
    .catch( err =>{
      console.log("Error: while creating the coursePlan", err);
      this.hideLoader();
      alert("Publishing to ADAP failed.");
    })




    
    // this.swapInAPIData();
    // if(localStorage.getItem("batchId") !== null && localStorage.getItem("batchId") !== undefined && localStorage.getItem("batchId") != ""){
    //   this.setState({batch: true});
    //   BatchProvider.submitBatch(localStorage.batchId, this.submitBatchCompleted);
    // } else {
    //   alert("No Batch found for process");
    //   this.hideLoader();
    // }
  }

  submitBatchCompleted = (response) => {
    // if(response.id){
      this.setState({batch: true}, () => {
        alert("Batch processed with ID: " + localStorage.getItem("batchId"));
        localStorage.removeItem("batchId");
        console.log("Batch Submit reponse::", response)
        this.componentDidMount()
        //this.getData();
      })
    // }
  }

  hideLoader() {
    document.getElementById("loader").style.display = "none";
  }

  showLoader() {
    document.getElementById("loader").style.display = "block";
  }

  onCreateCartridge = (response) => {
    if(response && response.id) {
      let body = {
          "cartridge": {
          "id": response.id,
          "assetIds": this.getAssetIds()
        }
      }
      QikikAdminProvider.linkCartridge(body, this.onLinkCartridge);
    }
  }

  onLinkCartridge = (response) => {
    if(response.statusCode != 400) {
      QikikAdminProvider.publishADAP(this.props.toc.uuid, this.onPublishADAP);
    } else {
      alert(response.message);
    }
  }

  getAssetIds = () => {
    let assetIDs = [];
    let assets = this.props.assetsJSON;
    if(assets != undefined) {
      assets.forEach(function(asset) {
        if (asset.assetId) {
          assetIDs.push(asset.assetId);
        }
      })
    } else {
      let toc = this.props.toc;
      let subTopics = toc.nodes[0].nodes[0].nodes;
      console.log("toc", subTopics, subTopics.length);
      subTopics.forEach(function(subTopic) {
        if (subTopic) {
          subTopic.nodes.forEach(function(sub) {
            assetIDs.push(sub.assetId);
          })
        }
      })
    }
    return assetIDs;
  }

  onPublishADAP = (response) => {
    if(response && response.catalog_id){
      QikikAdminProvider.linkToCourse(this.props.toc.uuid, this.props.courseId, this.onlinkToCourse);
    }
  }

  onlinkToCourse = (response) => {
    if(response && response.structureId){
      this.hideLoader();
      alert('Published to ADAP');
    }
  }

  fetchAssetDetails = (assetId) => {
    let resBody = {
      "assetIds": [
        assetId
      ]
    }
    QikikAdminProvider.fetchAssetDetails(resBody, this.onFetchAssetDetails);
  }

  onFetchAssetDetails = (response) => {
    if(response && response.assets && response.assets.length > 0) {
      this.setState({
        purpose: response.assets[0].purposes ? response.assets[0].purposes.join(",") : '',
        difficulty: response.assets[0].difficulty ? response.assets[0].difficulty : '',
        bloomsTaxonomy: response.assets[0].bloomsTaxonomy ? response.assets[0].bloomsTaxonomy : ''
       });
    }
  }

  onCancel = () => {
    this.setState({ addAssetType: ''});
  }

  setDisablePublish = (flag) => {
    this.setState({isPublishDisabled: flag})
  }

  swapInAPI = (data) => {
    swapData = data;
  }

  swapInAPIData = () => {
    // for (var i = 0; i < swapData.length; i++) {
    //   console.log(swapData[i]);
    //   QikikAdminProvider.reorderAssetDetails(swapData[i].tocId, swapData[i].nodeId, swapData[i].orderNumber, this.onReorderAsset);
    // }
  }

  onReorderAsset = (response) => {
    if(response) {
      console.log(response);
    }
  }

  stateupdate=()=>{
    this.setState({node:""})
  }

  render() {
    return (
      <div className="toc-conatiner">
        <div className="toc-header">
          {'Manage TOC'}
          <button 
            id="addAsset"
            onClick={this.submitBatch} 
            disabled={(this.props.batchStatus == "completed" && this.state.coursePlanId != "" && this.state.coursePlanId != undefined && this.state.coursePlanId != "undefined") ? true : (this.props.batchStatus == "completed") ? false : true}
            className="w3-button w3-medium w3-left-align w3-indigo w3-hover-blue publishADAPButton tooltip">
              Publish {this.state.batch ? <img src={Loading} width="25px" /> : ''} {(this.state.coursePlanId != "" && this.state.coursePlanId != undefined && this.state.coursePlanId != "undefined") ? <sub>(Course Plan Already Created)</sub> : ''}
          </button>
        </div>
        <div className="toc-body">
          <div className="toc-tree">
            <ToCRenderer toc={this.props.toc} getNodeType={this.getNodeType} swapInAPI={this.swapInAPI} disableEdit={this.state.batch} batchStatus={this.props.batchStatus} swapInAPIData={this.swapInAPIData} />
          </div>
          <div className="toc-preview">

            { (this.state.addAssetType == 'addAsset' || this.state.addAssetType == 'addExistingAsset' || 
               this.state.addAssetType == 'addAssetViaURL' || this.state.addAssetType == 'uploadVideo') ?
              <CreateAssets courseId={this.props.courseId} node={this.state.node} batch={this.state.batch} assetType={this.state.addAssetType} onCancel={this.onCancel} setDisablePublish={this.setDisablePublish} /> :
              (this.state.node && this.state.node.type == 'container' && this.state.node.label == 'concept') ? 
              <ConceptPanel showLoader={this.showLoader} onCancel={this.onCancel} updateTopicToc={this.props.updateTopicToc} structureId={this.props.structureId} uuid={this.props.uuid} updateToc={this.props.updateToc} node={this.state.node} /> : (this.state.node && this.state.node.type == 'container' && this.state.node.label == 'topic') ?
              <TopicPanel hideLoader={this.hideLoader} showLoader={this.showLoader} structureId={this.props.structureId} uuid={this.props.uuid} updateToc={this.props.updateToc} node={this.state.node} addAsset={this.addAsset} /> : (this.state.node && this.state.node.type == 'container' && this.state.node.label == 'subtopic') ?
              <SubtopicPanel showLoader={this.showLoader} stateupdate={this.stateupdate} structureId={this.props.structureId} uuid={this.props.uuid} updateToc={this.props.updateToc} node={this.state.node} addAsset={this.addAsset} /> : (this.state.node && this.state.node.type == 'asset' && (this.state.node.label == 'activity' || this.state.node.label == 'video')) ?
              <AssetsPanel  showLoader={this.showLoader} stateupdate={this.stateupdate} structureId={this.props.structureId} uuid={this.props.uuid} fetchAssetDetails={this.fetchAssetDetails} updateToc={this.props.updateToc} node={this.state.node} purpose={this.state.purpose} difficulty={this.state.difficulty} bloomsTaxonomy={this.state.bloomsTaxonomy}/> : 

              (this.state.node && !this.state.node.hasOwnProperty("label") && !this.state.node.hasOwnProperty("type")) ?
              <ParentPanel showLoader={this.showLoader} updateTopicToc={this.props.updateTopicToc} stateupdate={this.stateupdate} structureId={this.props.structureId} uuid={this.props.uuid} fetchAssetDetails={this.fetchAssetDetails} updateToc={this.props.updateToc} node={this.state.node} purpose={this.state.purpose} difficulty={this.state.difficulty} bloomsTaxonomy={this.state.bloomsTaxonomy}/> : ''

            }
          </div>
        </div>
      </div>
    );
  }
}
