import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import BatchesProvider from '../../Providers/BatchesProvider'
import UserProvider from '../../Providers/UserProvider'
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import csvToJson from 'csvtojson'
import ErrorBlock from '../common/ErrorBlock'


class AddItemsToBatchDialog extends React.Component {
  state = {
      open: null,
      text: "",
      showError: false,
      statusCode: 200,
      errorData: [],
      errorText: '',
      batchUpdateRequired: false,
      itemType: "assets",
      template: {
        assets: process.env.REACT_APP_ASSET_INGESTION_TEMPLATE,
        "cartridge-assets": process.env.REACT_APP_CARTRIDGE_ASSET_INGESTION_TEMPLATE,
        cartridges: process.env.REACT_APP_CARTRIDGE_INGESTION_TEMPLATE
      }
  };

  componentWillMount = () => {
  };

  componentDidUpdate = () => {
    if (this.state.open !== true && this.props.show) {
        this.setState({
            open: true,
            text: ""
        })
    }
  }

  componentWillUnmount =() => {
  };


  render() {
    return (
        <MuiThemeProvider>
        <div>
          {this.renderPopup()}
        </div>
        </MuiThemeProvider>        
    );
  }

  handleTextChange = (e) => {
    const value = e.target.value;
    this.setState({
      text: value,
    });
  }

  onClose = () => {

    this.setState({
      open: false,
      showError: false,
      errorData: []
    })

    this.props.callback(this.state.batchUpdateRequired)
  };
  createErrorBox = () => {
    let error = this.state.errorData;
    return error.map((errorObj)=>{
      return <li>{(errorObj.error ? errorObj.error+' ( '+errorObj.item.uid+' ) ' : errorObj.message) }</li>
    })
  }
  onAddItemsResult = (error, statusCode, response) => {
    let errorData = [];
    let showError = this.state.showError;
    let errorText = this.state.errorText;
    if (statusCode === 200) {
      var itemsAdded = response.created ? response.created.length : (response.items ? response.items.length : 0)
      let text = "Added " + itemsAdded + " " + this.state.itemType
      if (response.error) {
        text = "";
        errorData = response.error;
        if(errorData.constructor === Object && errorData.invalidAttributes && errorData.invalidAttributes.uid && Array.isArray(errorData.invalidAttributes.uid)){
          errorData = errorData.invalidAttributes.uid;
        }
        showError = true;
        errorText = `Error(s) found. ${errorData.length} Expected item(s) were not added due to below errors.`
      } else {
        showError = false;
      }
      this.setState({
        text: text,
        statusCode,
        errorText,
        errorData,
        showError,
        batchUpdateRequired:true
      })
      return
    }  
    else {
      showError = true;
      errorText = (response && response.error) ? response.error : "Something went wrong."
      this.setState({
        showError,
        errorText,
        statusCode,
      })

    }  

  }
  onAddItemsAssetEntitlement = (error, statusCode, response) => {
    let errorData = [];
    let showError = this.state.showError;
    let errorText = this.state.errorText;
    if (statusCode === 200) {
      var itemsAdded = Array.isArray(response) ? response.length : 0
      let text = "Added " + itemsAdded + " " + this.state.itemType
      if(error || !response){
        text = "";
        showError = true;
        errorText = error ? error : "Something went wrong.";
      }
      else if(response.error) {
        text = "";
        errorData = response.error;
        if(errorData.constructor === Object && errorData.invalidAttributes && errorData.invalidAttributes.uid && Array.isArray(errorData.invalidAttributes.uid)){
          errorData = errorData.invalidAttributes.uid;
        }
        showError = true;
        errorText = `Error(s) found. ${errorData.length} Expected item(s) were not added due to below errors.`
      }
      else{
        showError = false;
      }
      this.setState({
        text: text,
        statusCode,
        errorText,
        errorData,
        showError,
        batchUpdateRequired:true
      })
    }  
    else {
      showError = true;
      errorText = (response && response.reason) ? 
        response.reason : (response.invalidLinks ? "Links are invalid" : "Something went wrong.")
      this.setState({
        showError,
        errorText,
        statusCode,
      })

    }  
  }
  modifyEachObjectInList = (items) => {
    return items.map(item => {
      if((item.assetId || item.assetUID) && (item.userId || item.userUID)){
        item.type = 'rplusasset-user';
      }
      else if((item.assetId || item.assetUID) && (item.schoolId || item.schoolUID)){
        item.type = 'rplusasset-school';
      }  
      else if((item.cartridgeId || item.cartridgeUID) && (item.schoolId || item.schoolUID)){
        item.type = 'rpluscartridge-school';
      }
      let newItem = {}
      Object.keys(item).forEach(key => {
        if(item[key])
          newItem[key] = item[key] 
      });
      return newItem;
    });
  }
  onAddItemsToBatch = (items) => {
    let user = UserProvider.getCurrentUser()
    let THIS = this

    switch(this.state.itemType) {
      case "assets": {
        BatchesProvider.addV0AssetsToBatch(items, this.props.batchId, this.onAddItemsResult)
        break
      }
      case "cartridges": {
        BatchesProvider.addV0CartridgesToBatch(user.uid, items, this.props.batchId, this.onAddItemsResult)
        break
      }
      case "cartridge-assets": {
        BatchesProvider.addV0CartridgeAssetsToBatch(items, this.props.batchId, this.onAddItemsResult)
        break
      }
      case "asset-asset": {
        BatchesProvider.addAssetToAssetLinkToBatch(items, this.props.batchId, this.onAddItemsResult)
        break
      }
      case "asset-entitlement": {
        if(this.state.itemType === 'asset-entitlement'){
          items = this.modifyEachObjectInList(items);
        }
        BatchesProvider.addAssetEntitlementLinksToBatch(items, this.props.batchId, this.onAddItemsAssetEntitlement)
        break;
      }
    }
  }
  
  onSaveClicked = () => {
    let tsvPayload = this.state.text
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: "\t"
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
      this.onAddItemsToBatch(itemsFromCSV)
    })             
  }

  abilitiesToRender = () => {
    let abilities = []
    if (UserProvider.userHasPermission("create_asset")) {
      abilities.push(
        <FlatButton label="assets" primary={this.state.itemType === "assets"} 
        onClick={function() {
          this.setState({
            itemType: "assets", 
            text: "",
            showError: false,
            errorData: []
          })
        }.bind(this)}  
        />
      )  
    }

    if (UserProvider.userHasPermission("create_cartridge")) {
      abilities.push(
        <FlatButton label="cartridges" primary={this.state.itemType === "cartridges"} 
        onClick={function() {
          this.setState({
            itemType: "cartridges", 
            text: "",
            showError: false,
            errorData: []
          })
        }.bind(this)}
        />
      )  
    }

    if (UserProvider.userHasPermission("create_cartridge")) {
      abilities.push(
        <FlatButton label="cartridge assets" primary={this.state.itemType === "cartridge-assets"} 
        onClick={function() {
          this.setState({
            itemType: "cartridge-assets", 
            text: "",
            showError: false,
            errorData: []
          })
        }.bind(this)}

        />
      )  
    }

    if (UserProvider.userHasPermission("create_asset")) {
      abilities.push(
        <FlatButton label="asset-to-asset" primary={this.state.itemType === "asset-asset"} 
        onClick={function() {
          this.setState({
            itemType: "asset-asset", 
            text: "",
            showError: false,
            errorData: []
          })
        }.bind(this)}  
        />
      )  
    }
    if (UserProvider.userHasPermission("create_asset")) {
      abilities.push(
        <FlatButton label="asset-entitlement" primary={this.state.itemType === "asset-entitlement"} 
        onClick={function() {
          this.setState({
            itemType: "asset-entitlement", 
            text: "",
            showError: false,
            errorData: []
          })
        }.bind(this)}  
        />
      )  
    }

    return abilities

  }

  renderPopup = () => {
    let payloadType = this.state.itemType
    let batchName = this.props.batchName

    let title = "Add TSV (" + payloadType + ") to batch: " + batchName.toUpperCase()

    let abilities = this.abilitiesToRender()

    let actions = []
    actions.push(<FlatButton
            label="Add"
            disabled={!abilities.length}
            primary={true}
            onClick={this.onSaveClicked}
          />
    )

    actions.push(<FlatButton
        label="Done"
        primary={false}
        onClick={this.onClose}
      />
    )

    let  ret = <Dialog
          title={title}
          actions={actions}
          modal={true}
          contentStyle={{width: "90%", maxWidth: "none"}}
          autoDetectWindowHeight={true}
          open={this.state.open}
          onRequestClose={this.onClose}
          autoScrollBodyContent={true}
        >
            <div>
                <div className="w3-padding">
                  <div className="w3-left">
                  Add: 
                  {abilities}
                </div>
                  <a href={this.state.template[payloadType]} target="_blank" className="w3-right"> (download template) </a>
                </div>
                { this.state.showError &&
                  <ErrorBlock
                    text={this.state.errorText}
                  >
                    { (this.state.statusCode == 200) &&
                      <ol>
                        { this.createErrorBox() }
                      </ol>
                    }  
                  </ErrorBlock>
                }
                <div className="w3-padding">
                <TextField className=""
                    hintText={abilities.length ? "TSV (copy/paste from XLS)" : "You don't have permissions to add items to this batch"}
                    disabled={!abilities.length}
                    fullWidth={true}
                    value={this.state.text}
                    onChange={this.handleTextChange}
                    multiLine={true}
                    style = {{width: "100%"}}
                />
                </div>
            </div>


        </Dialog>
  
        return ret
  };  
}



export default AddItemsToBatchDialog;
