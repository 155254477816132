import _ from 'lodash';
import BatchesProvider from "./BatchesProvider.js"
import { Promise } from "bluebird";
import { v4 as uuidv4 } from 'uuid';
import ARS from "./../thirdparty/ARS";
class CartridgeProvider {

    CreateCartridge = (batchId, cartridgeId, name, subject, grade, fragmentType, createdBy) => {
        let payload =
            [{
            "createdBy": createdBy,
                uid: cartridgeId,
                "type": "cartridge",
                "metadata": {
                    cartridgeId:cartridgeId,
                    "name": name+ uuidv4(),
                    "subject": subject,
                    "grade": grade,
                   
                }
            }]

        if (fragmentType) {
            payload[0].metadata.externalIds = [
                {
                  "originId": fragmentType,
                  "source": "Century"
                }
              ]
        }

        return BatchesProvider.addBatchItem(batchId, payload);
    }
    AddAssetsToCartridge = (username, batchId, topics, subtopics, assets, cartridges) => {
        
        return Promise.map([...cartridges.learn, ...cartridges.practice, ...cartridges.check], (cartridge) => {
      let query = {};
            query[cartridge.field] = cartridge.name;
            let questionIds = _.map(_.filter(assets, query), 'assetId');
            let payload = {
                "query": {
                    "metadata.questionId": {
                        "$in": questionIds
                    }
                }
            }
            return Promise.all([ARS.v3AssetsFetch(payload), Promise.resolve({ questionIds, cartridgeId: cartridge.cartridgeId, name: cartridge.name })])
                .spread((response, data) => {
                    let assetIds = _.uniq(_.map(response.assets, "id"));
                    if (assetIds.length < data.questionIds.length) {
                        alert(`Asset Ids: ${assetIds.join(",")} \n
                         Question Ids: ${data.questionIds.join(',')}
                           Asset records not found `)
                         return Promise.reject("Asset not found in ARS:");
                         


                    }
                    if (assetIds.length > data.questionIds.length) {
                        alert("Duplicate asset is create\n QuestionIds   :" +
                        data.questionIds.join(",") + "\nProblem in Data Please Correct It Before Upload!!");
                       return Promise.reject("data error");
                        
                    }
                    data.assetIds = assetIds;
                    _.forEach(response.assets, a => {

                        let index = _.findIndex(assets, {assetId:a.questionId});
                        
                        if(!assets[index] && -1 == _.findIndex(assets, {assetId:a.id})) {
                            console.log("some this went wrong!...");
                            Promise.reject("some this went wrong!...");
                            return false;
                            
                        } 
                        assets[index].arsAssetId = a.id;
                        assets[index].questionId = a.questionId;
                   })
                    // cartridgeId and cartridgeUID are the same in cartridge creation payload 
                    return Promise.all([
                        this.addCartridgeAssets(batchId, data.cartridgeId, assetIds, username),
                        Promise.resolve(data)])
                        .spread((response, data) => {
                            console.log("Cartridge-asset created successfully:", response);
                            return data;
                        })

                })


        },{concurrency:10});

    }

    addCartridgeAssets = (batchId, cartridgeUID, assetIds, createdBy) => {

        let links = [
            {
                "type": "cartridge-assets",
                "cartridgeUID": cartridgeUID,
                "assetIds": assetIds,
                createdBy: createdBy
            }
        ]
        return BatchesProvider.addLinkItem(batchId, links)

    }
    createCartridgesForQuestionGroups(username, batchId, topics, subtopics, assets, course) {

        let groups = {}
        groups.check = _.map(_.filter(_.uniqBy(assets, "CheckGroup"), a => { return a.CheckGroup != "" }), a => (
            {
                name: a.CheckGroup,
                cartridgeId: uuidv4(),
                field: "CheckGroup"
            }));
        groups.practice = _.map(_.filter(_.uniqBy(assets, "PracticeGroup"), a => { return a.PracticeGroup != "" }), a => (
            {
                name: a.PracticeGroup,
                cartridgeId: uuidv4(),
                field: "PracticeGroup"
            }));
        groups.learn = _.map(_.filter(_.uniqBy(assets, "LearnGroup"), a => { return a.LearnGroup != "" }), a => {
            let assetType = (_.find(assets,{LearnGroup: a.LearnGroup})||{}).assetType;
           let data= {
                name: a.LearnGroup,
                cartridgeId: uuidv4(),
                field: "LearnGroup"

            }
            if(assetType =="video") data.cartridgeType="video";
            return data;
        });


        return Promise.join(Promise.map(groups.check, group => {

            return this.CreateCartridge(batchId, group.cartridgeId, group.name, "subject", "grade","", username);

        },{concurrency:5}), Promise.map(groups.learn, group => {
            let cartridgeType ="";

            if(group.cartridgeType){
                cartridgeType ="video"

            }
              

            return this.CreateCartridge(batchId, group.cartridgeId, group.name, "subject", "grade",cartridgeType, username);

        },{concurrency:5}),
            Promise.map(groups.practice, group => {

                return this.CreateCartridge(batchId, group.cartridgeId, group.name, "subject", "grade", "", username);

            },{concurrency:5}), (check, learn, practice) => {
                console.log(check, practice, learn);
                return groups;
            })
    }
}



export default new CartridgeProvider();
