import React from 'react';
import {getThemes} from './utils/utils'
import QIKIK_CONST from './constants/constants';
import QikikAdminProvider from './providers/QikikAdminProvider'
import Select from "react-select";

export default class CreateCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grade: '',
      name: '',
      levelId: '',
      subjectId: '',
      description: '',
      colorCode: '',
      thumbnail: '',
      countries: [],
      subject: '',
      startDate: QIKIK_CONST.STARTDATE,
      endDate: QIKIK_CONST.ENDDATE,
      products: [{
        id: '',
        startDate: QIKIK_CONST.STARTDATE,
        endDate: QIKIK_CONST.ENDDATE
      }],
      createdBy: '',
      expires: false,
      countryList: [],
      selectedOption:''
    };

    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    QikikAdminProvider.getCountries(this.setCountries);
  }

  setCountries = (res) => {
    const countryList = res.map((data, index) => {
      return { key: index, label: data.name, value: data.code };
    });
    this.setState({ countryList: countryList });
  };

  onChange(event) {
    let {name}  = event.target;
    console.log(name, event.target.value, event.target );
    this.setState({[name]: event.target.value});
  }

  onCountryChange = (e) => {
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({countries: value});
  }

  onCreateCourse = (response) => {
    if(response && response.id){
      alert("Course has been Created!!");
      this.props.refreshCourseList();
    }
  }

  validateForm = () => {
    if (this.state.selectedOption.length == 0) {  
      alert("Please Select Country!");
      return false;
    } else if (this.state.subject == '') {
      alert("Please Provide Subject!");
      return false;
    } else if (this.state.grade == '') {
      alert("Please Provide Grade!");
      return false;
    }  else if (this.state.levelId == '' ||  !isUUID(this.state.levelId) ) {
      alert("Please Provide Level Id in uuid format");
      return false;
    } else if (this.state.subjectId == '' ||  !isUUID(this.state.subjectId) ) {
      alert("Please Provide Subject Id in uuid format");
      return false;
    } else if (this.state.name == '') {
      alert("Please Provide Name!");
      return false;
    } else if (this.state.description == '') {
      alert("Please Provide Description!");
      return false;
    } else if (this.state.themes == '') {
      alert("Please Provide themes!");
      return false;
    } else if (this.state.thumbnail == '') {
      alert("Please Provide thumbnail!");
      return false;
    }
    return true;
  }

  handleSubmit(event) {
    event.preventDefault();  
    if (!this.validateForm()) return;  
    let reqBody = {
                    "name": this.state.name,
                    "description":  this.state.description,
                    "subject":  this.state.subject,
                    "grade":  this.state.grade,
                    "levelId": this.state.levelId,
                    "subjectId": this.state.subjectId,
                      
                    "products": [
                      {
                        "startDate": this.state.startDate,
                        "endDate": this.state.endDate
                      }
                    ],
                    "countries": this.state.selectedOption.map((data) => {
                      return data.value;
                    }),
                    "colorCode":  this.state.themes,
                    "createdBy": JSON.parse(localStorage.getItem('kCurrentUser')).fName,
                    "thumbnail":  this.state.thumbnail,
                    "expires": false,
                    "startDate": this.state.startDate
                    
                  }
      QikikAdminProvider.createCourse(reqBody, this.onCreateCourse);

  }

  handleChangeDropDown = (selectedOption) => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  render() {
    return (
    <div className="p-20">
        <h3>Create Course</h3>

        <div className="w-50">
        <form  onSubmit={this.handleSubmit}>
            <label for="country">Country</label>
            <Select
                key={this.state.countryList.key}
                className="country"
                id="country"
                value={this.state.selectedOption}
                onChange={this.handleChangeDropDown}
                options={this.state.countryList}
                isMulti={true}
                closeMenuOnSelect={false}
              />

            <label for="subject">Subject</label>
            <input type="text" name="subject" value={this.state.subject} onChange={this.onChange} />

            <label for="fname">Grade</label>
            <input type="text" name="grade" value={this.state.grade} onChange={this.onChange} />
            <label htmlFor="levelId">Level Id</label>
          <input type="text" name="levelId" value={this.state.levelId} onChange={this.onChange} />

          <label htmlFor="subjectId">Subject Id</label>
          <input type="text" name="subjectId" value={this.state.subjectId} onChange={this.onChange} />

            <label for="fname">Name</label>
            <input type="text" name="name" value={this.state.name} onChange={this.onChange} />

            <label for="lname">Description</label>
            <input type="text" id="description" name="description" value={this.state.description} onChange={this.onChange} />

             <label for="country">Color Theme</label>
            <select id="themes" name="themes" value={this.state.themes} onChange={this.onChange}>
              {getThemes()}
            </select>

            <label for="fname">Thumbnail</label>
            <input type="text" name="thumbnail" value={this.state.thumbnail} onChange={this.onChange} />

            <label for="startDate">Start Date</label>
            <input type="text" name="startDate" value={this.state.startDate} onChange={this.onChange} />

            <label for="endDate">End Date</label>
            <input type="text" name="endDate" value={this.state.endDate} onChange={this.onChange} />
        
            <input type="submit" value="Submit" />
             <button type="button" onClick={this.props.onCancel} >Cancel</button>
        </form>
        </div>
   </div>

    );
  }
}
function isUUID ( uuid ) {
  let s = "" + uuid;

  s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
  if (s === null) {
    return false;
  }
  return true;
}
