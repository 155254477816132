import ParkingLot from '../thirdparty/ParkingLot'
import UserProvider from './UserProvider'
const uuidV4 = require('uuid/v4')
const Promise = require("bluebird");
class BatchesProvider {

  //permission functions
  canRemoveBatchCollaborators = (batch) => {
    let currentUser = UserProvider.getCurrentUser()
    return (batch.createdBy === currentUser.uid 
            || currentUser.roleValue === "pearson-admin")  
  }

  canEditBatchCollaborators = (batch) => {
    let currentUser = UserProvider.getCurrentUser()
    return (batch.createdBy === currentUser.uid 
            || (batch.collaborators && batch.collaborators.indexOf(currentUser.uid) >= 0)
            || currentUser.roleValue === "pearson-admin")  
  }

  canDeleteBatch = (batch) => {
    if (batch.status === "completed") {
      return false
    }
    let currentUser = UserProvider.getCurrentUser()
    return (batch.createdBy === currentUser.uid || currentUser.roleValue === "pearson-admin")  
  }
  canEditBatchName = (batch) => {
    let currentUser = UserProvider.getCurrentUser()
    return (batch.createdBy === currentUser.uid || currentUser.roleValue === "pearson-admin")  
  }
  canDeleteBatchItem = (item) => {
    if (item.status === "completed") {
      return false
    }
    let currentUser = UserProvider.getCurrentUser()
    return (item.createdBy === currentUser.uid || item.batchCreator === currentUser.uid || currentUser.roleValue === "pearson-admin")
  }

  //end - permission functions

  loadBatchCollaborators = (batchId, callback) => {
    //error, statusCode, batch, collaborators
    let THIS = this
    this.getBatchById(batchId, function(error, statusCode, batch) {
      let collaboratorIds = []
      if (batch && batch.id === batchId) {
        if (batch.collaborators)  {
          collaboratorIds = batch.collaborators
        }
      }

      UserProvider.fetchUsersByUIDs(collaboratorIds, function(error, statusCode, users) {
        callback(error, statusCode, batch, users)
      })

    })
  }

  getBatchById = (batchId, callback) => {
    ParkingLot.getBatchById(batchId, callback)
  }
  getBatchByUID = (batchId, callback) => {
    ParkingLot.getBatchByUID(batchId, callback)
    
  }

  submitBatch = (batchId, callback) => {
    ParkingLot.submitBatch(batchId, callback)
  }

  addV0CartridgesToBatch = (userId, items, batchId, callback) => {
    let cartridges = []
    items.forEach(function(anItem) {
      let aCartridge = {
        uid: anItem.uid || uuidV4(),
        createdBy: anItem.createdBy || userId,
        type: "cartridge",
        metadata: {
          name: anItem.name || uuidV4(),
          subject: anItem.subject || "n/a",
          grade: anItem.grade || "n/a"
        }
      }

      if (anItem.schoolIds && anItem.schoolIds.length > 0) {
        aCartridge.metadata.schoolIds = anItem.schoolIds.split(";")
      }

      if (anItem.externalIds && anItem.externalIds.length > 0) {
        aCartridge.metadata.externalIds = []
        let refs = anItem.externalIds.split(";")
        refs.forEach(function(aRef) {
            let refData = aRef.split("|")
            let exRef = {source: refData[0], originId: refData[1]}
            aCartridge.metadata.externalIds.push(exRef)
        })
      } 

      cartridges.push(aCartridge)
    })

    ParkingLot.addV1ItemsToBatch("cartridges", batchId, cartridges, callback)
  }
  addV0AssetsToBatch = (v0Assets, batchId, callback) => {
    ParkingLot.addV0ItemsToBatch("assets", batchId, v0Assets, callback)
  }
  addV0CartridgeAssetsToBatch = (v0Links, batchId, callback) => {
    ParkingLot.addV0ItemsToBatch("cartridge-assets", batchId, v0Links, callback)
  }
  addAssetToAssetLinkToBatch = (v0Links, batchId, callback) => {
    ParkingLot.addV0ItemsToBatch("asset-asset", batchId, v0Links, callback)
  }

  addAssetEntitlementLinksToBatch = (v1Links, batchId, callback) => {
    ParkingLot.addV1LinksToBatch("asset-entitlement", batchId, v1Links, callback)
  }

  createEmptyBatch = (userId, callback) => {
    ParkingLot.createEmptyBatch(userId, callback)
  }

  updateBatchCollaborators = (batchId, collaborators, callback) => {
    ParkingLot.updateBatch(batchId, {collaborators: collaborators}, callback)
  }

  updateBatchName = (batchId, name, callback) => {
    ParkingLot.updateBatch(batchId, {name: name}, function(error, statusCode, response) {
      callback(error, statusCode, response)
    })
  }

  submitTSVTextPayload = (payload, userId, payloadType, callback) => {
    ParkingLot.submitTSVTextPayload(payload, userId, payloadType, function(error, statusCode, response) {
      callback(error, statusCode, response)
    })
  }

  searchBatches = (userId, filters, pIndex, pCount, callback) => {
    //by userId AND filter by name
    let query = {}

    // if (userId) {
    //   query["$and"] = [{
    //     "$or": [{"createdBy": userId}, {"collaborators": {"$in": [userId]}}]
    //   }]
    // }
    // if (filters.name && filters.name.length > 0) {
    //   query["$or"] = [
    //     {"name": filters.name},
    //     {"status": filters.name}
    //   ]
    // }

    if (userId) {
      query["createdBy"] = userId;
    }
    if (filters.name && filters.name.length > 0) {
      query["id"] = filters.name
    }

    ParkingLot.fetchBatches(pIndex, pCount, query, function(error, statusCode, batchesResult) {
      let batches = batchesResult ? batchesResult.batches : null
      if (statusCode === 200 && batches && Array.isArray(batches) && batches.length) {
        let creatorIds = []
        batches.forEach(function(aBatch) {
          if (creatorIds.indexOf(aBatch.createdBy) < 0) {
            creatorIds.push(aBatch.createdBy)
          }
        })
        UserProvider.fetchUsersByUIDs(creatorIds, function(error, code, users) {
          let usersMap = {}
          if (users && Array.isArray(users)) {
            users.forEach(function(aUser) {
              usersMap[aUser.userId] = aUser
            })
          }

          batches.forEach(function(aBatch) {
            aBatch.creatorUser = usersMap[aBatch.createdBy]
          })

          callback(error, statusCode, batchesResult, filters)

        })
      }
      else {
        callback(error, statusCode, batchesResult, filters)
      }
      
    })
  }

  searchSaBatches = (userId, filters, pIndex, pCount, saId, fromDate, toDate, callback) => {
    //by userId AND filter by name
    let query = {}

    if (filters.name && filters.name.length > 0) {
      query["$or"] = [
        {"id": filters.name},
        {"name": filters.name},
        {"status": filters.name}
      ]
    }

    ParkingLot.fetchSaBatches(pIndex, pCount, saId, query, fromDate, toDate, function(error, statusCode, batchesResult) {
      let batches = batchesResult ? batchesResult.batches : null
      if (statusCode === 200 && batches && Array.isArray(batches) && batches.length) {
        let creatorIds = []
        batches.forEach(function(aBatch) {
          if (creatorIds.indexOf(aBatch.createdBy) < 0) {
            creatorIds.push(aBatch.createdBy)
          }
        })
        UserProvider.fetchUsersByUIDs(creatorIds, function(error, code, users) {
          let usersMap = {}
          if (users && Array.isArray(users)) {
            users.forEach(function(aUser) {
              usersMap[aUser.userId] = aUser
            })
          }

          batches.forEach(function(aBatch) {
            aBatch.creatorUser = usersMap[aBatch.createdBy]
          })

          callback(error, statusCode, batchesResult, filters)

        })
      }
      else {
        callback(error, statusCode, batchesResult, filters)
      }
      
    })
  }

  searchBatchItems = (batchId, filters, types, pIndex, pCount, callback) => {
    let query = {}
    if (filters && filters.name && filters.name.length > 0) {
      query["$or"] = [
        {"metadata.name": filters.name},
        {"metadata.contentTypeValue": filters.name},
        {"metadata.fileType": filters.name}
      ]
    }

    if (Array.isArray(types) && types.length > 0) {
      query.type = {"$in": types}
    }

    if (batchId) {
      query.batchId = batchId
    }


    ParkingLot.fetchBatchItems(pIndex, pCount, query, function(error, statusCode, response) {
      callback(error, statusCode, response, filters)
    })

  }

  getCurrentUserBatches = (callback) => {
      const user = UserProvider.getCurrentUser()
      let query = {
          createdBy: user.uid
      }
      ParkingLot.fetchBatches(0, 20, query, function(error, statusCode, response) {
        callback(error, statusCode, response)
      })
  }

  getBatchItems = (batchId, callback) => {
    let query = {
      batchId: batchId
    }

    ParkingLot.fetchBatchItems(0, 20, query, function(error, statusCode, response) {
      callback(error, statusCode, response)
    })
  }

  deleteBatch = (batchId, callback) => {
    ParkingLot.deleteBatch(batchId, function(error, statusCode, response) {
      callback(error, statusCode, response)
    })
  }

  deleteBatchItem = (itemid, callback) => {
    ParkingLot.deleteBatchItem(itemid, function(error, statusCode, response) {
      callback(error, statusCode, response)
    })
  }

  createBatchWithTag = (body, callback) => {
    ParkingLot.createBatchWithTag(body, callback)
  }

  batchItemAndLinks = (batchId, v1Links, callback) => {
    return ParkingLot.batchItemLinksToBatch(batchId, v1Links, callback)
  }
  addBatchItem  = ( batchId, item) => { 
     return new Promise( (resolve, reject) =>{
      ParkingLot.addV1ItemsToBatch(null,batchId, item, (error, statusCode, response) =>{
        if(error){
          return reject({error, statusCode});
        }
        return resolve(response);
      })
     });
  };
  addLinkItem = ( batchId, item) => { 
    return new Promise( (resolve, reject) =>{
     ParkingLot.addV1LinksToBatch(null,batchId, item, (error, statusCode, response) =>{
       if(error){
         return reject({error, statusCode});
       }
       return resolve(response);
     })
    });
 }


}
export default  new BatchesProvider();

