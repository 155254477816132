import React from 'react';

const uuidV4 = require('uuid/v4')
let swapData = [];
class ToCRenderer2 extends React.Component {
  constructor(props) {
    swapData = [];
    super(props)
    this.makeContainerNode = this.makeContainerNode.bind(this);
    this.makeAssetNode = this.makeAssetNode.bind(this);
    this.checkIt = this.checkIt.bind(this);
    this.state = {
      toc: {},
      showList: []
    }
  }

  componentDidMount() {
    this.hideLoader();
  }

  swapDown = (node) => {
    this.showLoader();
    let elmAID = node.uuid + '-root';
    let elmBID = document.getElementById(elmAID).nextSibling != null ? document.getElementById(elmAID).nextSibling.id : null; // #foo3

    if(elmBID == null) {
      alert('There is no below element to Swap!!');
    } else {
      let elmB_Attr = document.getElementById(elmAID).nextSibling;
      this.doSwap(elmAID, elmBID);
      let orderNumber = parseInt(node.orderNumber) + 1;
      
      let x = this.checkIt(node.tocId, node.nodeId, 'down');
      if(x == false) {
        swapData.push({tocId : node.tocId, nodeId: node.nodeId, orderNumber: orderNumber});
      }

      let y = this.checkIt(elmB_Attr.getAttribute('data-tocid'), elmB_Attr.getAttribute('data-nodeid'), 'up');
      if(y == false) {
        swapData.push({tocId : elmB_Attr.getAttribute('data-tocid'), nodeId: elmB_Attr.getAttribute('data-nodeid'), orderNumber: (parseInt(elmB_Attr.getAttribute('data-ordernumber')) -1)});
      }
    }
    this.props.swapInAPI(swapData);
    setTimeout(this.hideLoader, 2000);
  }

  swapUp = (node) => {
    this.showLoader();
    let elmAID = node.uuid + '-root';
    let elmBID = document.getElementById(elmAID).previousSibling != null ? document.getElementById(elmAID).previousSibling.id : null; // #foo3

    if(elmBID == null) {
      alert('There is no Above element to Swap!!');
    } else {
      let elmB_Attr = document.getElementById(elmAID).previousSibling;
      this.doSwap(elmAID, elmBID);
      let orderNumber = parseInt(node.orderNumber) - 1;
      let x = this.checkIt(node.tocId, node.nodeId, 'up');
      if(x == false) {
        swapData.push({tocId : node.tocId, nodeId: node.nodeId, orderNumber: orderNumber});
      }

      let y = this.checkIt(elmB_Attr.getAttribute('data-tocid'), elmB_Attr.getAttribute('data-nodeid'), 'down');
      if(y == false) {
        swapData.push({tocId : elmB_Attr.getAttribute('data-tocid'), nodeId: elmB_Attr.getAttribute('data-nodeid'), orderNumber: (parseInt(elmB_Attr.getAttribute('data-ordernumber')) +1)});
      }
    }
    this.props.swapInAPI(swapData);
    setTimeout(this.hideLoader, 2000);
  }

  checkIt = (tocId, nodeId, direction) => {
    let exists = false;
    if(swapData && swapData.length > 0) {
      swapData.forEach(function(data) {
        if (data.tocId === tocId && data.nodeId === nodeId) {
            if(direction == 'up') {
              data.orderNumber = parseInt(data.orderNumber) - 1;
            } else {
              data.orderNumber = parseInt(data.orderNumber) + 1;
            }
            exists = true;
        }
      })
    }
    return exists;
  }

  hideLoader() {
    document.getElementById("loader2").style.display = "none";
  }

  showLoader() {
    document.getElementById("loader2").style.display = "block";
  }

  onReorderAsset = (response) => {
    if(response) {
      alert(response);
    }
  }

  doSwap = (a, b) => {
    this.swapElements(document.getElementById(a), document.getElementById(b));
  }

  swapElements = (obj1, obj2) => {
    // save the location of obj2
    var parent2 = obj2.parentNode;
    var next2 = obj2.nextSibling;
    // special case for obj1 is the next sibling of obj2
    if (next2 === obj1) {
        // just put obj1 before obj2
        parent2.insertBefore(obj1, obj2);
    } else {
        // insert obj2 right before obj1
        obj1.parentNode.insertBefore(obj2, obj1);

        // now insert obj1 where obj2 was
        if (next2) {
            // if there was an element after obj2, then insert obj1 right before that
            parent2.insertBefore(obj1, next2);
        } else {
            // otherwise, just append as last child
            parent2.appendChild(obj1);
        }
    }
  }

  makeContainerNode = (node) => {
    node.uuid = node.uuid || uuidV4()
    let THIS = this
    let childrenNodes = function(start) {
        let list = []
        if (start.nodes) {
          start.nodes.forEach(function(aChild) {
            if (aChild.type === "container") {
              list.push(THIS.makeContainerNode(aChild))
            }
            else if (aChild.type === "asset") {
              list.push(THIS.makeAssetNode(aChild))
            }
          })
        }
        return list
    }

    let ret = 
    <div id={node.uuid + "-root"} data-orderNumber={node.orderNumber ? node.orderNumber : 0} data-tocId={node.tocId ? node.tocId : 0} data-nodeId={node.nodeId ? node.nodeId : 0} className="w3-container w3-show w3-left" style={{border:1, width:"100%"}}>
      <div onClick={function() {
          let showList = this.state.showList;
          var index = showList.indexOf(node.uuid);
          if (index > -1) {
            showList.splice(index, 1);
          }
          else {
            showList.push(node.uuid)
          }
          this.setState({
            showList: showList
          })
        }.bind(this)}   
        className="w3-button w3-tiny w3-grey w3-block w3-left-align" 
        key={node.nodeId}
        data-orderNumber={node.orderNumber ? node.orderNumber : 0}
        data-tocId={node.tocId ? node.tocId : 0} data-nodeId={node.nodeId ? node.nodeId : 0} >
          {"[" + node.type + " : " + node.label  + " : " + (node.nodes ? node.nodes.length : 0) + " children" + "] " + node.name.en} 
          <button type='button' onClick={function(e) {
            e.stopPropagation();
            this.props.getNodeType(node);
          }.bind(this)} className="w3-button w3-indigo w3-hover-blue assetEditButton" disabled={(this.props.disableEdit || this.props.batchStatus)?true:false}>Edit</button>

          <button type='button' onClick={function(e) {
            e.stopPropagation();
            this.swapDown(node);
          }.bind(this)} className="w3-button w3-indigo w3-hover-blue assetEditButton" disabled={(this.props.disableEdit || this.props.batchStatus)?true:false}><i className="down"></i></button>

          <button type='button' onClick={function(e) {
            e.stopPropagation();
            this.swapUp(node);
          }.bind(this)} className="w3-button w3-indigo w3-hover-blue assetEditButton" disabled={(this.props.disableEdit || this.props.batchStatus)?true:false}><i className="up"></i></button>

        </div>
        
        <div id={node.uuid} className={this.state.showList.indexOf(node.uuid) >= 0 ? "w3-container w3-left-align w3-show" : "w3-container w3-left-align w3-hide"}>
          {childrenNodes(node)}
        </div>
    </div>
    return ret
  }

  makeAssetNode = (node, i) => {
    node.uuid = node.uuid || uuidV4()
    let ret = 
    <div key={i} id={node.uuid + "-root"} data-orderNumber={node.orderNumber ? node.orderNumber : 0} data-tocId={node.tocId ? node.tocId : 0} data-nodeId={node.nodeId ? node.nodeId : 0} className="w3-container w3-show w3-left" style={{border:1, width:"100%"}}>  
      <div onClick={function() {
          let showList = this.state.showList
          var index = showList.indexOf(node.uuid);
          if (index > -1) {
            showList.splice(index, 1);
          }
          else {
            showList.push(node.uuid)
          }
          this.setState({
            showList: showList
          })
        }.bind(this)}   
        className="w3-btn w3-block w3-sand w3-left-align"
        data-orderNumber={node.orderNumber ? node.orderNumber : 0}
        data-tocId={node.tocId ? node.tocId : 0} data-nodeId={node.nodeId ? node.nodeId : 0} >
          {"[" + node.type + " : " + node.label + "] " + node.name.en}

          <button type='button' onClick={function(e) {
            e.stopPropagation();
            this.props.getNodeType(node);
          }.bind(this)} className="w3-button w3-indigo w3-hover-blue assetEditButton">Edit</button>

          <button type='button' onClick={function(e) {
            e.stopPropagation();
            this.swapDown(node);
          }.bind(this)} className="w3-button w3-indigo w3-hover-blue assetEditButton"><i className="down"></i></button>

          <button type='button' onClick={function(e) {
            e.stopPropagation();
            this.swapUp(node);
          }.bind(this)} className="w3-button w3-indigo w3-hover-blue assetEditButton"><i className="up"></i></button>

        </div>

        <div id={node.uuid} className={this.state.showList.indexOf(node.uuid) >= 0 ? "w3-container w3-left-align w3-show" : "w3-container w3-left-align w3-hide"}>
      </div>
    </div>
    return ret
  }

  generateFlatList = (toc, flatList, nodes, nesting) => {
    let THIS = this
    if (nodes) {
      nodes.forEach(function(aNode) {
        aNode.uuid = aNode.uuid || uuidV4()
        aNode.nesting = nesting
        flatList.push(aNode)
        if (aNode.type === "container") {
          let subNodes = aNode.nodes
          delete aNode.nodes
          THIS.generateFlatList(toc, flatList, subNodes, nesting+1)
        }
      })
    }
  }


  render() {
    let myToC = this.props.toc;
    let nodes = this.makeContainerNode(myToC);
    return (
      <div  className="w3-left" style={{width:"100%"}}>
        <div className="w3-left">
          {myToC.name.en}
        </div>
        <br/><br/>
        {nodes}
        <div id="loader2"></div>
      </div>
    )
  }
}

export default ToCRenderer2;