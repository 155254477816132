import React from 'react';
import UserProvider from '../../Providers/UserProvider'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import BatchItemsListItem from './BatchItemsListItem'
import IconButton from 'material-ui/IconButton';
import ActionPrev from 'material-ui/svg-icons/navigation/arrow-back';
import ActionNext from 'material-ui/svg-icons/navigation/arrow-forward';
import "./batchitemlist.css"
import ItemDisplay from "./ItemsDisplayConfig"


import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

const uuidV4 = require('uuid/v4')
//https://v0.material-ui.com/#/components/table
class BatchItemsList extends React.Component {
  state = {
    fixedHeader: false,
    fixedFooter: false,
    stripedRows: false,
    showRowHover: false,
    selectable: false,
    multiSelectable: false,
    enableSelectAll: false,
    deselectOnClickaway: true,
    showCheckboxes: false,    
  };

  onGoPrev = (e) => {
    this.props.cbPrev()
  }
  onGoNext = (e) => {
    this.props.cbNext()
  }



  render() {
    var columnTemplate = "default"
    if (this.props.itemTypesFilter && this.props.itemTypesFilter.length === 1) {
      columnTemplate = this.props.itemTypesFilter[0]
    }

    let pagingColumnSpan = ItemDisplay.dataPoints(columnTemplate).length - 2

    var itemsTable = this.props.batchitems
    var rows = []
    itemsTable.forEach((item)=> {
      rows.push(<BatchItemsListItem 
                  batchitem={item} 
                  template={columnTemplate}
                  refresh={this.props.refresh}
                />)
    })
    let minRowSpace = 8
    var emptyRows = []
    while (rows.length + emptyRows.length < minRowSpace) {
      emptyRows.push(<TableRow />)
    }


    return (
      <MuiThemeProvider>
        <div>
          <div>
              <IconButton style={{zIndex:"0"}} className="w3-left" disabled={this.props.page === 0} onClick={this.onGoPrev.bind(this)}>
                <ActionPrev />
              </IconButton>

              <IconButton style={{zIndex:"0"}} className="w3-right" disabled={this.props.batchitems.length < this.props.pageSize} onClick={this.onGoNext.bind(this)}>
                <ActionNext />
              </IconButton>

              <div className="w3-center" style={{color:"#aeaeae"}}>
                Page: {this.props.page + 1}, Count: {this.props.batchitems.length}
              </div>
          </div>
          <br/><br/>
          <Table
            className="itemstable"
            bodyStyle={{overflow:'visible'}}
            style={{tableLayout: 'auto'}}
            fixedHeader={this.state.fixedHeader}
            fixedFooter={this.state.fixedFooter}
            selectable={this.state.selectable}
            multiSelectable={this.state.multiSelectable}        
          >
            <TableHeader
              displaySelectAll={this.state.showCheckboxes}
              adjustForCheckbox={this.state.showCheckboxes}
              enableSelectAll={this.state.enableSelectAll}
              >
                <TableRow>
                  {ItemDisplay.headerRow(columnTemplate)}
                </TableRow>
              </TableHeader>
              <TableBody
                displayRowCheckbox={this.state.showCheckboxes}
                deselectOnClickaway={this.state.deselectOnClickaway}
                showRowHover={this.state.showRowHover}
                stripedRows={this.state.stripedRows}
              >
              {rows}
              {emptyRows}
              </TableBody>
            </Table>   
          </div>
        </MuiThemeProvider>   
    )
  }
}

export default BatchItemsList;
