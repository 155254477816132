import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import AppDispatcher from '../../../../utils/dispatcher/AppDispatcher'
import EditTOCTreeview from '../../EditTOCTreeview'
import csvToJson from 'csvtojson'
import XLSX from 'xlsx';
import { IconButton } from 'material-ui';
import { getDateTime } from '../../utils/utils';
import UserProvider from '../../../../Providers/UserProvider';
import QikikAdminProvider from '../../providers/QikikAdminProvider';
import ActionInfo from 'material-ui/svg-icons/action/info-outline';
import HawkHeaders from '../../../../utils/HawkHeaders';
import axios from 'axios';
import _ from 'lodash';
import BatchesProvider from '../../../../../src/Providers/BatchesProvider';
import AssignmentProvider from '../../../../../src/Providers/AssignmentProvider';
import CartridgeProvider from '../../../../../src/Providers/CartridgeProvider';
import LinksProvider from '../../../../../src/Providers/LinkProvider';
import { Promise } from 'bluebird';

import { v4 as uuidv4 } from 'uuid';
class QikToCDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFile: {},
      batch: true,
      tochandler: false,
      batchStatus: "",
      uuid:""
    };
    this.onFileAdded = this.onFileAdded.bind(this)
    this.onProcessFile = this.onProcessFile.bind(this)
    this.tocHandler = this.tocHandler.bind(this)
  };

  componentDidMount() {
    this.hideLoader();
    if (this.props.showTOC) {
      QikikAdminProvider.getFullTocTree(this.props.structureId, this.ongetFullTocTree);
      BatchesProvider.getBatchByUID(this.props.structureId, (error, statusCode, response) => {
        if(error) {
          alert("something went wrong!");
        } else {
          let { itemStat, status} = response;
          if(status == "completed") {
            this.setState({ batch: false, batchStatus: status })
          }
          else if(status =="submitted") {
            this.setState({ batch: false, batchStatus: status });
            return QikikAdminProvider.getFullTocTree(this.props.structureId, this.ongetFullTocTree);
          }
        }
      })
      
      // const authorization_header = HawkHeaders.generateMacHeader(process.env.REACT_APP_PARKINGLOT_PLA_URL, '/v1/batches/fetch?pIndex=0&count=1', process.env.REACT_APP_PARKINGLOT_PLA_HAWK, process.env.REACT_APP_PARKINGLOT_PLA_HAWK_SECRET, 'POST');
      // const configbatch = { headers: { 'Content-Type': 'application/json', 'Authorization': authorization_header } }
      // let data = {
      //   "query": {
      //     "tags": this.props.courseId,
      //     "status": "submitted"
      //   }
      // }
      // axios.post(process.env.REACT_APP_PARKINGLOT_PLA_URL + '/v1/batches/fetch?pIndex=0&count=1', data, configbatch).then((response_batch) => {
      //   //submitted length = 0 means 
      //   let search_by_tag = response_batch.data.batches.length == 0 ? false : true;
      //   this.setState({ batch: search_by_tag });
      //   return QikikAdminProvider.getFullTocTree(this.props.structureId, this.ongetFullTocTree);
      // })
    }
  }

  getStructureId = (response) => {
    if (response && response.id) {
      QikikAdminProvider.getFullTocTree(response.structureId, this.ongetFullTocTree);
      this.hideLoader();
    }
  }

  tocHandler(intervalId, cb) {
    
    BatchesProvider.getBatchByUID(this.state.uuid == "" ? this.props.structureId: this.state.uuid, (error, statusCode, response) =>{
      
      if(error) {
      alert("something went wrong!");
      clearTimeout(intervalId);
      
       return;

      } else {
        let { itemStat,status} = response;
        cb(status)
        
        }    
    
    })
    // const authorization_header = HawkHeaders.generateMacHeader(process.env.REACT_APP_PARKINGLOT_PLA_URL, '/v1/batches/fetch?pIndex=0&count=1', process.env.REACT_APP_PARKINGLOT_PLA_HAWK, process.env.REACT_APP_PARKINGLOT_PLA_HAWK_SECRET, 'POST');
    // const configbatch = { headers: { 'Content-Type': 'application/json', 'Authorization': authorization_header } }
    // let data = {
    //   "query": {
    //     "tags": this.props.courseId,
    //     "status": "submitted"
    //   }
    // }
    // axios.post(process.env.REACT_APP_PARKINGLOT_PLA_URL + '/v1/batches/fetch?pIndex=0&count=1', data, configbatch).then((response_batch) => {
    //   let search_by_tag = response_batch.data.batches.length == 0 ? false : true;
    //   this.setState({ batch: search_by_tag }, () => {
    //     if (!search_by_tag) {
    //       QikikAdminProvider.getCourseById(this.props.courseId, this.getStructureId);
    //     } else {
    //       this.setState({ batch: search_by_tag });
    //     }
    //   })
    // })
  }

  componentWillMount() {
    console.log("component unmounted")
  }

  onFileAdded = (e) => {
    const files = e.target.files;
    if (files && files[0]) this.setState({ selectedFile: files[0] });
  };

  hideLoader() {
    document.getElementById("loader").style.display = "none";
  }

  showLoader() {
    document.getElementById("loader").style.display = "block";
  }

  onProcessFile = (e) => {
    this.showLoader();
    // if (_.isEmpty(this.state.selectedFile)) {

    if (typeof this.state.selectedFile.name == 'string') {
      const reader = new FileReader()
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
        /* Get first worksheet */
        console.log(JSON.stringify(wb.SheetNames))
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const tocCSV = XLSX.utils.sheet_to_csv(wb.Sheets["toc"])
        const conceptsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["concepts"])
        const topicsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["topics"])
        const subtopicsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["subtopics"])
        const assetsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["assets"])
        const linksCSV = XLSX.utils.sheet_to_csv(wb.Sheets["links"])

        console.log("toc \n", tocCSV)

        this.setState({
          tocCSV: tocCSV,
          conceptsCSV: conceptsCSV,
          topicsCSV: topicsCSV,
          subtopicsCSV: subtopicsCSV,
          assetsCSV: assetsCSV,
          linksCSV: linksCSV
        }, () => {
          this.parseToC()
        })

      };
      if (rABS) {
        reader.readAsBinaryString(this.state.selectedFile);
      } else {
        reader.readAsArrayBuffer(this.state.selectedFile);
      };
    } else {
      alert("select a file for upload")
      this.hideLoader();
      return
    }
  };

  parseToC = () => {
    let tsvPayload = this.state.tocCSV
    csvToJson({
      noheader: false,
      output: "json",
      ignoreEmpty: false,
      delimiter: ","
    })
      .fromString(tsvPayload)
      .then((itemsFromCSV) => {
        this.setState({
          tocJSON: itemsFromCSV
        }, () => {
          this.parseConcepts()
        })

      })
  }

  parseConcepts = () => {
    let tsvPayload = this.state.conceptsCSV
    csvToJson({
      noheader: false,
      output: "json",
      ignoreEmpty: false,
      delimiter: ","
    })
      .fromString(tsvPayload)
      .then((itemsFromCSV) => {
        itemsFromCSV.map((data) => {
          data.tags = (data.hasOwnProperty("searchTag") || data.hasOwnProperty("searchtag")) ? data.searchTag == "" ? [] : _.uniq(_.split((data.searchTag || data.searchtag), '|')) : []
        });
        this.setState({
          conceptsJSON: itemsFromCSV
        }, () => {
          this.parseTopics()
        })

      })
  }

  parseTopics = () => {
    let tsvPayload = this.state.topicsCSV
    csvToJson({
      noheader: false,
      output: "json",
      ignoreEmpty: false,
      delimiter: ","
    })
      .fromString(tsvPayload)
      .then((itemsFromCSV) => {
        itemsFromCSV.map((data) => {
          data.tags = (data.hasOwnProperty("searchTag") || data.hasOwnProperty("searchtag")) ? data.searchTag == "" ? [] : _.uniq(_.split((data.searchTag || data.searchtag), '|')) : []
        });
        this.setState({
          topicsJSON: itemsFromCSV
        }, () => {
          this.parseSubtopics()
        })

      })
  }

  parseSubtopics = () => {
    let tsvPayload = this.state.subtopicsCSV
    csvToJson({
      noheader: false,
      output: "json",
      ignoreEmpty: false,
      delimiter: ","
    })
      .fromString(tsvPayload)
      .then((itemsFromCSV) => {
        itemsFromCSV.map((data) => {
          data.tags = (data.hasOwnProperty("searchTag") || data.hasOwnProperty("searchtag")) ? data.searchTag == "" ? [] : _.uniq(_.split((data.searchTag || data.searchtag), '|')) : []
        });
        this.setState({
          subtopicsJSON: itemsFromCSV
        }, () => {
          this.parseAssets()
        })

      })
  }

  parseAssets = () => {
    let tsvPayload = this.state.assetsCSV
    csvToJson({
      noheader: false,
      output: "json",
      ignoreEmpty: false,
      delimiter: ","
    })
      .fromString(tsvPayload)
      .then((itemsFromCSV) => {
        itemsFromCSV.map((data) => {
          data.tags = (data.hasOwnProperty("searchTag") || data.hasOwnProperty("searchtag")) ? data.searchTag == "" ? [] : _.uniq(_.split((data.searchTag || data.searchtag), '|')) : []
        });
        this.setState({
          assetsJSON: itemsFromCSV,
        }, () => {
          this.parseLinks()
        })
      })
  }
  parseLinks = () => {
    let tsvPayload = this.state.linksCSV
    csvToJson({
      noheader: false,
      output: "json",
      ignoreEmpty: false,
      delimiter: ","
    })
      .fromString(tsvPayload)
      .then((itemsFromCSV) => {

        this.setState({
          linkJSON: itemsFromCSV,
        }, () => {
          this.putItTogether()
        })
      })
  }

  putItTogether = () => {
    let toc = this.state.tocJSON[0];
    let concepts = this.state.conceptsJSON;
    let topics = this.state.topicsJSON;
    let subtopics = this.state.subtopicsJSON;
    let assets = this.state.assetsJSON;
    let links = this.state.linkJSON;

    concepts = _.map(concepts, concept => {
      concept.nodeId = uuidv4();
      return concept;
    });

    topics = _.filter(topics, topic => {
      topic.nodeId = uuidv4();
      return topic.label != "";
    });

    subtopics = _.filter(subtopics, st => {
      st.nodeId = uuidv4();
      return st.label != "";
    });

    assets = _.filter(assets, a => {
      a.nodeId = uuidv4();
      
      return a.label != "";
    });

    if (!toc || !concepts || !topics || !subtopics || !assets  ) {
      alert("missing data")
      this.hideLoader();
      return
    }

    let conceptsDic = {}
    concepts.forEach(function (con) {
     let aConcept = _.clone(con);
      if (aConcept.tag) {
        conceptsDic[aConcept.tag] = aConcept
        aConcept.metadata  = {tag: aConcept.tag};
        if (!toc.nodes) {
          toc.nodes = []
        }
        aConcept.tags = _.filter(aConcept.tags, tag => (tag!= ""));
        toc.nodes.push(aConcept)
        delete aConcept.Duplicate
        delete aConcept.tag
        delete aConcept.searchTag
        delete aConcept.searchtag
      }
    })

    let topicsDic = {}
    let problem = false;
    for (var i = 0; i < topics.length; i++) {
      let aTopic = _.clone(topics[i]);
      if (typeof (aTopic) != "undefined" && aTopic.topicTag != "#NAME?") {
        if (aTopic.tag && aTopic.conceptTag && aTopic.topicTag) {
          topicsDic[aTopic.topicTag] = aTopic;
          let thisConcept = conceptsDic[aTopic.conceptTag];
          if (!thisConcept.nodes) {
            thisConcept.nodes = [];
          }
          aTopic.tags = _.filter(aTopic.tags, tag => (tag!= ""));
          aTopic.metadata ={ tag: aTopic.tag};
          thisConcept.nodes.push(aTopic);

          delete aTopic.Duplicate
          delete aTopic.tag
          delete aTopic.conceptTag
          delete aTopic.topicTag
          delete aTopic.searchTag
          delete aTopic.searchtag
        }
      } else {
        problem = true;
      }
    }

    if (problem) {
      alert("Problem in Excel Please Correct It Before Upload!!");
      this.hideLoader();
      return;
    }

    let subtopicsDic = {}
    for (var i = 0; i < subtopics.length; i++) {
      let aSubtopic = _.clone(subtopics[i]);
      if (aSubtopic.tag && aSubtopic.topicTag && aSubtopic.subtopicTag) {
        subtopicsDic[aSubtopic.subtopicTag] = aSubtopic
        let thisTopic = topicsDic[aSubtopic.topicTag]
        if (typeof (thisTopic) == "undefined") {
          problem = true;
          this.hideLoader();
          alert("Problem in Excel Please Correct It Before Upload!!");
          return;
        }
        if (!thisTopic.nodes) {
          thisTopic.nodes = []
        }
        aSubtopic.tags = _.filter(aSubtopic.tags, tag => (tag!= ""));
        aSubtopic.metadata ={ tag: aSubtopic.tag};
        thisTopic.nodes.push(aSubtopic);
        delete aSubtopic.Duplicate
        delete aSubtopic.tag
        delete aSubtopic.subtopicTag
        delete aSubtopic.topicTag
        delete aSubtopic.searchTag
        delete aSubtopic.searchtag
      }
    }

    if (problem) {
      this.hideLoader();
      alert("Problem in Excel Please Correct It Before Upload!!");
    }

    // assets.forEach((anAsset) => {
    _.forEach(assets, (a) => {
      let anAsset = _.clone(a);
      if (anAsset.tag && anAsset.subtopicTag) {
        let thisSubtopic = subtopicsDic[anAsset.subtopicTag];
        if (typeof (thisSubtopic) == "undefined") {
          problem = true;
          return false;
        } else {
          if (!thisSubtopic.nodes || !thisSubtopic.hasOwnProperty("nodes")) {
            thisSubtopic.nodes = []
          }
          anAsset.metadata ={ tag: anAsset.tag};
          anAsset = _.omit(anAsset, ["tag",
            "subtopicTag",
            "searchTag",
            "searchtag",
            "Purpose",
            "CheckAssignment",
            "CheckGroup",
            "PracticeAssignment",
            "PracticeGroup",
            "LearnAssignment",
            "LearnGroup",
            "field18",
            "field19",
            "field20",
            "Bloom"
          ]);
          thisSubtopic.nodes.push(anAsset);
        }
      }
    })


    let tocId = uuidv4(); // for batch uuid and toc uuid, we are using same uuid.
    let batchId = null;
    let username = "ingestion";

    // creating batch
    let payload = {
      batch: {
        "uid": tocId,
        "name": "ingestion-toc",
        "createdBy": username,
        "description": "parking lot ingestion",
        "type": "generic",
        "tags":[ tocId]

      }
    }
    // batch creation 

    return new Promise((resolve, reject) => {
      BatchesProvider.createBatchWithTag(payload, (response, statusCode, error) => {
        if (error) {
          console.log("While creating batch error");
          return reject(error);
        }
        batchId = response.id;
        return resolve(response);
      })
    })

    .then(() => {
      return CartridgeProvider.createCartridgesForQuestionGroups(username, batchId, [], [], assets, "english")
    }).then(cartridges => {
        
      return  CartridgeProvider.AddAssetsToCartridge(username, batchId, topics, subtopics, assets, cartridges);
         
      })
      .then( cartridgesAssets => {
        return Promise.all([
          AssignmentProvider.CreateAssignments(username, batchId, topics, subtopics, assets),
          Promise.resolve(cartridgesAssets)]);
      })
      .spread( ( assignments,cartridgesAssets) =>{
        return Promise.all(
          [
            Promise.resolve(assignments),
            AssignmentProvider.CreateAssignmentAssets(username, batchId, topics, subtopics,assets,assignments,cartridgesAssets )
         
          ]);
      })
      .spread(( assignments, assignmentAssets) =>{
         return LinksProvider.createNodeLinks("pulse", batchId, toc,assignments, links, username );
      })
      .then( () =>{
        
          _.forEach(toc.nodes|| [], concept =>{
           return  _.forEach(concept.nodes||[], (topic) =>{
              return _.forEach(topic.nodes||[], (subtopic) =>{
                   _.forEach(subtopic.nodes|| [],(asset) =>{
                     let tocSheetAssetObject = _.find(assets,{assetId: asset.assetId});
                    asset.assetId = tocSheetAssetObject.arsAssetId;
                   })
              })
            })
        });
        
      })
      .then( () =>{
        return new Promise((resolve, reject) => {

          BatchesProvider.submitBatch(batchId, (error, statusCode,response ) => {
            if (error) {
              console.log("While creating batch error");
              return reject(error);
            }
            batchId = response.id;
            return resolve(response);
          })
        })
      })
      .then(() => {
        if (!problem) {
          this.setState({
            toc: toc
          }, () => {
            this.state.toc.uuid = tocId;
            console.log("toc  ID  and Batch id ", tocId);
            QikikAdminProvider.submitToTOC(this.state.toc, this.onSubmitToTOC);
          })
        } else {
          this.hideLoader();
          alert("Problem in Excel Please Correct It Before Upload!!");
          return false;
        }
      })
      .catch(err =>{
        this.hideLoader();
          alert("Problem in Excel Please Correct It Before Upload!!");
          
          BatchesProvider.deleteBatch(batchId, (error, statusCode, response ) => {
            if (error) {
              console.log("While creating batch error");
             
            }

            return false;
          })
        
      })
  }

  showToCDetails = (toc) => {
    let strJson = JSON.stringify(toc, null, 4)
    let info = {
      sender: this,
      title: "ToC",
      content: strJson
    }
    AppDispatcher.postNotification("pop-it-up", info)
  }

  onCreateCartridge = (response) => {
    if (response && response.id) {
      let body = {
        "cartridge": {
          "id": response.id,
          "assetIds": this.getAssetIds()
        }
      }
      QikikAdminProvider.linkCartridge(body, this.onLinkCartridge);
    }
  }

  getAssetIds = () => {
    let assetIDs = [];
    let assets = this.state.assetsJSON;
    assets.forEach(function (asset) {
      if (asset.assetId) {
        assetIDs.push(asset.assetId);
      }
    })
    return assetIDs;
  }

  onLinkCartridge = (response) => {
    if (response.statusCode != 400) {
      // QikikAdminProvider.submitToTOC(this.state.toc, this.onSubmitToTOC);
    } else {
      alert(response.message);
    }
  }

  onSubmitToTOC = (response) => {
    if (response && response.id) {
      setTimeout(() => QikikAdminProvider.tocTaskStatus(response.id, this.ontocTaskStatus), 3000);
    }
  }

  ontocTaskStatus = (response) => {
    if (response && response.id) {
      if (!response.result.hasOwnProperty("uuid")) {
        alert(`Problem in Excel Please Correct It Before Upload!! \n ${response.result.error[0].message}`);
        this.hideLoader();
        return;
      }
      this.setState({ uuid: response.result.uuid });
      let body = {
        "structureId": response.result.uuid
      }
      QikikAdminProvider.editCourse(body, this.props.courseId, this.updateCourse)
      QikikAdminProvider.linkToCourse(response.result.uuid, this.props.courseId, this.onlinkToCourse);
      QikikAdminProvider.getFullTocTree(response.result.uuid, this.ongetFullTocTree);
    }
  }

  updateCourse = (response) => {
    console.log(response);
  }

  updateToc = (uuid) => {
    QikikAdminProvider.getFullTocTree(uuid, this.ongetFullTocTree);
  }

  updateTopicToc = (response) => {
    if (response) {
      setTimeout(() => QikikAdminProvider.tocTaskStatus(response, this.treePopulate), 3000);
    }
  }

  treePopulate = (response) => {
    if (response && response.id) {
      QikikAdminProvider.getFullTocTree(response.result.uuid, this.ongetFullTocTree);
    }
  }

  ongetFullTocTree = (response) => {
    if (response && response.id) {
      this.setState({
        tocTree: response
      });
      this.hideLoader();
    }
  }

  onlinkToCourse = (response) => {
    if (response && response.id) {
      console.log(this.props.courseId);
    }
  }

  render() {
    return (
      <MuiThemeProvider>
        <div>
          <div className="w3-left" style={{ width: "100%" }}>
            {this.state.tocTree ?
              <EditTOCTreeview courseId={this.props.courseId} course={ this.props.course}  fileName={this.state.selectedFile} assetsJSON={this.state.assetsJSON} toc={this.state.tocTree} uuid={this.state.uuid} structureId={this.props.structureId} updateToc={this.updateToc} batch={this.state.batch} tocHandler={this.tocHandler} updateTopicToc={this.updateTopicToc} batchStatus={this.state.batchStatus} /> :
              !this.props.showTOC ?
                <div className="w3-left">
                  <br /><br />
            Upload Qikik ToC XLSX (<a href="https://docs.google.com/spreadsheets/d/1gaj8p2YdrIfYRQLZvtm7qYlSmKYdEZiZ5u_BluN71RY/edit?usp=sharing">master template</a>):

            <input type="file" className="form-control" id="file" onChange={this.onFileAdded} />
                  <input type='submit' value="Process ToC" onClick={this.onProcessFile} />
                  <IconButton style={{ zIndex: "0" }} tooltip="show info" key="show-info" onClick={function () {
                    this.showToCDetails(this.state.toc)
                  }.bind(this)}>
                    <ActionInfo />
                  </IconButton>
                </div> : ''}
          </div>
          <div id="loader"></div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default QikToCDashboard;