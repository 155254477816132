import WebUtil from '../../../utils/WebUtil'
import HawkHeaders from '../../../utils/HawkHeaders'
import axios from 'axios';

var Promise = require('bluebird')
let apipaths = {
    serviceDirectory: "/v1/product/{productId}/service_directory/entity/{entityId}",
    courses: "/v1/course/find?index={startIndex}&size=20",
    createCourse: "/v1/course",
    deleteCourse: "/v1/course/{courseId}",
    editCourse: "/v1/course/{courseId}",
    createCartridge: "/v3/cartridge",
    submitToTOC: "/tocapi/v1/toc",
    tocTaskStatus: "/tocapi/v1/async-tasks/{taskId}/status",
    getFullTocTree: "/tocapi/v1/toc/{tocId}/tree",
    linkToCourse: "/v1/course/{courseId}/set/structureId",
    updatePanel: "/tocapi/v1/toc/{tocId}/node/{nodeId}",
    publishADAP: "/v1/adap/publish/toc/{tocId}",
    fetchAsset: "/v3/pulse/asset/fetch",
    insertNode: "/tocapi/v1/toc/{id}/node/{parentNodeId}",
    getCountries: "/v0/getresource?folder=countries&filename=list.json",
    editAssetDetails: "/v1/pulse/assetcontexts/assetid/{assetId}",
    reorderAssetDetails: "/tocapi/v1/toc/{tocId}/node/{nodeId}/order/{orderNumber}",
    getCourseById: "/v1/course/{courseId}",
    updateTOC: "/tocapi/v1/toc/{id}"
}

class QikikAPI {

    getServiceDirectory = () => {
        let deferred = Promise.pending();
        var path = apipaths.serviceDirectory
        path = path.replace("{productId}", qikik_ProdModelId())
        path = path.replace("{entityId}", qikik_EntityId());

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(pref_Url(), path, pref_hawkId(), pref_hawkSecret(), 'GET') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "GET",
            url: pref_Url() + path,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            deferred.resolve(response)
        })

        return deferred.promise     
    }

    getCourses = (startIndex=0) => {
        let deferred = Promise.pending();
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.CourseAPI.url : null;
        var path = apipaths.courses
        path = path.replace("{startIndex}", startIndex * 20);

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "POST",
            url: url + path,
            headers: headers,
            body: {  "productIds": [qikik_ProdModelId()]}
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
           deferred.resolve(response)
        })  
        return deferred.promise     
    }

    createCourse = (body, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.CourseAPI.url : null;
        var path = apipaths.createCourse

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };
            body.products[0].id = qikik_ProdModelId();
        let request = {
            method: "POST",
            url: url + path,
            headers: headers,
            body
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })          
    }

    deleteCourse = (courseId, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.CourseAPI.url : null;
        var path = apipaths.deleteCourse;
        path = path.replace("{courseId}", courseId);

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'DELETE') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };
        
        let request = {
            method: "DELETE",
            url: url + path,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })          
    }

    editCourse = (body, courseId, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.CourseAPI.url : null;
        var path = apipaths.editCourse;
        path = path.replace("{courseId}", courseId);

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'PUT') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };
        //body.products[0].id = qikik_ProdModelId();
        let request = {
            method: "PUT",
            url: url + path,
            headers: headers,
            body
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })          
    }

    getCourseById = (courseId, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.CourseAPI.url : null;
        var path = apipaths.getCourseById;
        path = path.replace("{courseId}", courseId);

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'GET') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };
        //body.products[0].id = qikik_ProdModelId();
        let request = {
            method: "GET",
            url: url + path,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })          
    }
    
    createCartridge = (body, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ARSAPI.url : null;
        var path = apipaths.createCartridge

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "POST",
            url: url + path,
            headers: headers,
            body
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })          
    }

    linkCartridge = (body, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ARSAPI.url : null;
        var path = apipaths.createCartridge

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'PUT') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "PUT",
            url: url + path,
            headers: headers,
            body
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })          
    }

    submitToTOC = (body, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ToCAPI.url : null;
        var path = apipaths.submitToTOC;

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            , "productModelId": qikik_ProdModelId()
        };

        let request = {
            method: "POST",
            url: url + path,
            headers: headers,
            body
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })          
    }

    tocTaskStatus = (taskId, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ToCAPI.url : null;
        var path = apipaths.tocTaskStatus;
        path = path.replace("{taskId}", taskId);

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'GET') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            , "productModelId": qikik_ProdModelId()
        };

        let request = {
            method: "GET",
            url: url + path,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error)
        })     
    }

    getFullTocTree = (tocId, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ToCAPI.url : null;
        var path = apipaths.getFullTocTree;
        path = path.replace("{tocId}", tocId)

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'GET') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            , "productModelId": qikik_ProdModelId()
        };

        let request = {
            method: "GET",
            url: url + path,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error)
        }) 
    }

    linkToCourse = (uuid, courseId, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.CourseAPI.url : null;
        var path = apipaths.linkToCourse
        path = path.replace("{courseId}", courseId)
        
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'PUT') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let payload = {
            "structureId": uuid
        }

        let request = {
            method: "PUT",
            url: url + path,
            headers: headers,
            body: payload
        }


        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error)
        })        

    }

    updatePanel = (tocId, nodeId, body, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ToCAPI.url : null;
        var path = apipaths.updatePanel;
        path = path.replace("{tocId}", tocId)
        path = path.replace("{nodeId}", nodeId)
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'PATCH') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            , "productModelId": qikik_ProdModelId()
        };

        let request = {
            method: "PATCH",
            url: url + path,
            headers: headers,
            body
        }


        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error)
        }) 
    }

    publishADAP = (tocId, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.AdapAPI.url : null;
        var path = apipaths.publishADAP;
        path = path.replace("{tocId}", tocId);

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            , "deviceid": getDeviceID()
            , "productModelId": qikik_ProdModelId()
        };

        let request = {
            method: "POST",
            url: url + path,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })          
    }

    getCountries = (callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.CaaS.url : null;
        var path = apipaths.getCountries;

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'GET') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            , "productModelId": qikik_ProdModelId()
        };

        let request = {
            method: "GET",
            url: url + path,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error)
        })     
    } 

    fetchAssetDetails = (body, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ARSAPI.url : null;
        var path = apipaths.fetchAsset;
        
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            // , "deviceid": getDeviceID()
            // , "productModelId": qikik_ProdModelId()
        };

        let request = {
            method: "POST",
            url: url + path,
            headers: headers,
            body
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })
    }

    editAssetDetails = (assetId, body, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ARSAPI.url : null;
        var path = apipaths.editAssetDetails;
        path = path.replace("{assetId}", assetId)

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'PUT') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "PUT",
            url: url + path,
            headers: headers,
            body
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })          
    }

    removeAssetDetails = (tocId, nodeId, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ToCAPI.url : null;
        var path = apipaths.updatePanel;
        path = path.replace("{tocId}", tocId);
        path = path.replace("{nodeId}", nodeId);

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'DELETE') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            , "productModelId": qikik_ProdModelId()
        };
        
        let request = {
            method: "DELETE",
            url: url + path,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })
    }

    reorderAssetDetails = (tocId, nodeId, orderNumber, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ToCAPI.url : null;
        var path = apipaths.reorderAssetDetails;
        path = path.replace("{tocId}", tocId)
        path = path.replace("{nodeId}", nodeId)
        path = path.replace("{orderNumber}", orderNumber)
        
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'PATCH') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            , "productModelId": qikik_ProdModelId()
        };

        let request = {
            method: "PATCH",
            url: url + path,
            headers: headers
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = responseString;
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error)
        })
    }

    insertNode = (id, parentNodeId, body, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ToCAPI.url : null;
        var path = apipaths.insertNode;
        path = path.replace("{id}", id)
        path = path.replace("{parentNodeId}", parentNodeId)

        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'POST') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            , "productModelId": qikik_ProdModelId()
        };

        let request = {
            method: "POST",
            url: url + path,
            headers: headers,
            body
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error);
        })
    }

    updateTOC = (tocId, body, callback) => {
        let serviceDirectory = localStorage.getItem('serviceDirectory'); 
        let svcDir = JSON.parse(serviceDirectory)
        let url = svcDir ? svcDir.services.ToCAPI.url : null;
        var path = apipaths.updateTOC;
        path = path.replace("{id}", tocId)
        const headers = { 
            authorization: HawkHeaders.generateMacHeader(url, path, course_hawkId(), course_hawkSecret(), 'PATCH') 
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
            , "productModelId": qikik_ProdModelId()
        };

        let request = {
            method: "PATCH",
            url: url + path,
            headers: headers,
            body
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(response, statusCode, error)
        }) 
    }
}

export default new QikikAPI();

function pref_Url() {return process.env.REACT_APP_PREFERENCE_API_URL}
function pref_hawkId() {return process.env.REACT_APP_PREFERENCE_HAWK_ID}
function pref_hawkSecret() {return process.env.REACT_APP_PREFERENCE_HAWK_SECRET}
function qikik_ProdModelId() {return process.env.REACT_APP_QIKIK_PRODUCTMODEL_ID}
function qikik_EntityId() {return process.env.REACT_APP_QIKIK_ENTITYID}
function course_hawkId() {return process.env.REACT_APP_COURSEAPI_HAWK_ID}
function course_hawkSecret() {return process.env.REACT_APP_COURSEAPI_HAWK_SECRET}
function getDeviceID() {return Math.floor(Math.random() * 100000000) + 1;}