import React from 'react';
import {getThemes} from '../../utils/utils'
import QikikAdminProvider from '../../providers/QikikAdminProvider';
import SubtopicPanel from './SubtopicPanel';
export default class TopicPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tocId: this.props.node.tocId, 
      nodeId: this.props.node.nodeId,
      parentNodeId: this.props.node.parentNodeId,
      name: this.props.node.name.en, 
      description: this.props.node.description.en,
      colorTheme: this.props.node.metadata.themes,
      thumbnail: this.props.node.metadata.thumbnail,
      tags: this.props.node.hasOwnProperty("tags") ? this.props.node.tags.join("|") : [],
      topic: "topic",
      subtopicname: '',
      subtopicdescription: '',
      subtopiccolorTheme: '',
      subtopicthumbnail: '',
      subtopictags:'',
      uuid:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      name: nextProps.node.name.en,
      description: nextProps.node.description.en,
      parentNodeId: nextProps.node.parentNodeId,
      thumbnail: nextProps.node.metadata.thumbnail ? nextProps.node.metadata.thumbnail : '',
      colorTheme: nextProps.node.metadata.themes ? nextProps.node.metadata.themes : '',
      tags: nextProps.node.tags ? nextProps.node.tags.join("|") : ''
     });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.showLoader();
    if(this.state.topic == "subtopic"){
      if(!this.state.subtopicname){
        alert("Sub Topic Name is required");
        return false
      }

      if(!this.state.subtopicdescription){
        alert("Sub Topic Description is required");
        return false;
      }

      this.state.subtopictags = this.state.subtopictags == "" ? [] : this.state.subtopictags.split("|")

      let reqBody = {
        "name": {
            "en": this.state.subtopicname
        },
        "description": {
          "en": this.state.subtopicdescription
        },
        "type": "container",
        "label": "subtopic",
        "metadata": {
          "themes": this.state.subtopiccolorTheme,
          "thumbnail": this.state.subtopicthumbnail
        },
        "tags": this.state.subtopictags
      }
      QikikAdminProvider.insertNode(this.state.tocId, this.state.nodeId, reqBody, this.onSubmitToTOC);
    } else {
    let reqBody = {
                    "name": {
                        "en": this.state.name
                    },
                    "description": {
                      "en": this.state.description
                    },
                    "metadata": {
                      "themes": this.state.colorTheme,
                      "thumbnail": this.state.thumbnail
                    },
                    "tags": this.state.tags.split("|")
                  }
      QikikAdminProvider.updatePanel(this.state.tocId, this.state.nodeId, reqBody, this.onUpdateTopicPanel);
    }
  }

  onSubmitToTOC = (response) => {
    if(response && response.id){
      setTimeout(() => QikikAdminProvider.tocTaskStatus(response.id, this.ontocTaskStatus), 3000);
    }
  }

  ontocTaskStatus = (response) => {
    if(response && response.id){
      this.setState({uuid: response.result.uuid, topic: "topic"}, () => {
        QikikAdminProvider.getFullTocTree(response.result.uuid, this.onUpdateTopicPanel);
      });
    }
  }

  onUpdateTopicPanel = (response) => {
    if(response && response.id){
        this.props.updateToc(this.state.tocId);
        this.props.hideLoader();
    }
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value}, () => console.log(this.state.name));
  }

  addAsset = (event) => {
    console.log(event.target.id);
    this.props.addAsset(event.target.id);
  }
  
  addTopic = (event) => {
    event.preventDefault();
    alert("sub-topic creation..")
    // let reqBody = {
    //                 "name": {
    //                     "en": this.state.name
    //                 },
    //                 "description": {
    //                   "en": this.state.description
    //                 },
    //                 "type": "container",
    //                 "label": "topic",
    //                 "metadata": {
    //                   "themes": this.state.colorTheme,
    //                   "thumbnail": this.state.thumbnail
    //                 },
    //               }
    // QikikAdminProvider.insertNode(this.state.tocId, this.state.parentNodeId, reqBody, this.onAddTopicPanel);
  }

  onAddTopicPanel = (response) => {
    if(response && response.id){
      console.log(this.props.uuid);
        this.props.updateToc(this.props.uuid);
    }
  }

  addSubTopic = () => {
    this.setState({topic:'subtopic'}, () => {
      console.log("Moving to add Sub Topic");
    })
  }

  onCancel = () => {
    this.setState({topic:'topic'}, () => {
      console.log("Moving to edit Topic");
    })
  }

  render() {
    return (
      <div className="p-20">
        <h3>{ this.state.topic == 'topic' ? 'Topic Panel' : 'Sub Topic Panel' }</h3>
        <div className="w-50">

        <br/>
{ this.state.topic == 'topic' ? 

          <form  onSubmit={this.handleSubmit}>          
            <label for="fname">Name</label>
            <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />

            <label for="lname">Description</label>
            <input type="text" name="description" value={this.state.description} onChange={this.handleChange} />

            <label for="colorTheme">Color Theme</label>
            <select id="colorTheme" name="colorTheme" value={this.state.colorTheme} onChange={this.handleChange}>
              {getThemes()}
            </select>

            <label for="fname">Thumbnail</label>
            <input type="text" name="thumbnail" value={this.state.thumbnail} onChange={this.handleChange} />
            
            <label for="fname">Tags</label>
            <input type="text" name="tags" value={this.state.tags} onChange={this.handleChange} />
            <br />
        
            <input type="submit" value="Submit" />
          </form>
        : <form  onSubmit={this.handleSubmit}>          
        <label for="fname">Sub Topic Name</label>
        <input type="text" name="subtopicname" value={this.state.subtopicname} onChange={this.handleChange} />

        <label for="lname">Sub Topic Description</label>
        <input type="text" name="subtopicdescription" value={this.state.subtopicdescription} onChange={this.handleChange} />

        <label for="colorTheme">Sub Topic Color Theme</label>
        <select id="colorTheme" name="subtopiccolorTheme" value={this.state.subtopiccolorTheme} onChange={this.handleChange}>
          {getThemes()}
        </select>

        <label for="fname">Sub Topic Thumbnail</label>
        <input type="text" name="subtopicthumbnail" value={this.state.subtopicthumbnail} onChange={this.handleChange} />
        
        
        <label for="fname">Tags</label>
        <input type="text" name="subtopictags" value={this.state.subtopictags} onChange={this.handleChange} />



        <br />
    
        <input type="submit" value="Submit" />
      </form>}   
        </div>
        <div className="button-container">
          { this.state.topic == "topic" ?
          <button id="addTopic" onClick={this.addSubTopic} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Add Sub Topic</button>
          : <button id="addSubTopic" onClick={this.onCancel} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Cancel</button>}
        </div>
      </div>
    );
  }
}
