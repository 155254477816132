import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import './styles/style.css'
import Courses from './Courses'
import CreateCourse from './CreateCourse'
import EditCourse from './EditCourse'
import EditTOC from './EditTOC'
import QikikAdminProvider from './providers/QikikAdminProvider'

import UserProvider from '../../Providers/UserProvider';
import { getDateTime } from './utils/utils';
import { v4 as uuidv4 } from 'uuid';


class QikToCDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      courseList: true,
      selectedFile: {},
      pIndex: 0,
      pSize: 20,
      courses: [],
      courseListData:[],
      selectedCourseId:"",
      courseData:{}      
    };
    QikikAdminProvider.getServiceDirectory();
  };


  componentDidMount() {
    console.log("selectedCourseId::::::::::::::::", this.state.selectedCourseId)
    this.getCourses();
  }

  getCourses = (idx = 0) => {
    let self = this;
    //Fetches the serviceDirectory and then calls the courseList API using the URL from SD
    QikikAdminProvider.getCourseList(idx).then(function (response) {
      if (response) {
        self.setState({ courses: response.data, courseListData:  response})
      } else {
        self.setState({ error: 'Unable to fetch the servicedirectory' });
      }
    })
  }

  // getCourseDetail = () =>{
  //   let course =  this.state.courses.filter(data => {if(data.id == this.state.selectedCourseId) return data});
  //   console.log("course:::::::::", course);
  //   return course[0];
  // }

  getCourseDetail = () => {
    alert(this.state.selectedCourseId);
    let course =  this.state.courses.filter(data => {if(data.id == this.state.selectedCourseId) return data});
    this.setState({courseData:course[0]}, () => {
      return QikikAdminProvider.getCourseById(this.state.selectedCourseId, this.getCourseDetailResponse);
    });
  }

  getCourseDetailResponse = (response) => {
    this.setState({courseData:response});
    return response;
  }

  createCourse = () => {
    this.props.setCourseItems({ createCourse: true, courseList: false, editTOC: false, showTOC: false });
  }

  editCourse = (id) => {
    let course =  this.state.courses.filter(data => {if(data.id == id) return data});
    course = course[0];
    this.setState({course: course, courseData:course});
    if(!course.cartridgeIds.length){
      let reqBody = {
        "cartridge": {
          "name": "qikik-" + course.id + "-"+ getDateTime(),
          "createdBy": UserProvider.getCurrentUser().name
        }
      }
      QikikAdminProvider.createCartridge(reqBody, this.updateCartridge);
    } else {
      QikikAdminProvider.getCourseById(id, this.getCourseDetailResponse);
    }
    if(!course.courseId){
      course.courseId = uuidv4();
    }
    this.setState({ selectedCourseId: id }, () => this.props.setCourseItems({ courseList: false, editTOC: false, createCourse: false, editCourse: true, showTOC: false }));
  }
  
  updateCartridge = (response) => {
    console.log("updateCartridge::", response)
    if(response && response.id) {
      let course_data = {
        "cartridgeIds": [response.id]
      }
      QikikAdminProvider.editCourse(course_data, this.state.selectedCourseId, this.updateCourse);
      console.log("Course Cartridge updated in Course");
    }
  }

  updateCourse = (response) => {
    if(response) {
      this.setState({courseData:response.update});
      console.log("Course updated with Cartridge", response);
    }
  }

  onCancel = () => {
    this.props.setCourseItems({ courseList: true, createCourse: false, editCourse: false, showTOC: false });
    this.getCourses();
  }

  refreshCourseList = () => {
    this.onCancel();
    this.getCourses();
  }

  editTOC = () => {
    this.props.setCourseItems({ editTOC: true, courseList: false, createCourse: false, editCourse: false, showTOC: false })
  }

  showTOC = (structureId) => {
    console.log(structureId)

    this.setState({
      structureId: structureId,
      batch:true,
      uuid: structureId
      
    })
    this.props.setCourseItems({ editTOC: false, courseList: false, createCourse: false, editCourse: false, showTOC: true })
  }

  onStepBack = () => {
    if (this.state.pIndex > 0) {
      this.setState({ pIndex: this.state.pIndex - 1 }, () => this.getCourses(this.state.pIndex));
    }
  }

  onStepForward = () => {
    if (this.state.pSize == this.state.courses.length) {
      this.setState({ pIndex: this.state.pIndex + 1 }, () => this.getCourses(this.state.pIndex));
    }
  }

  render() {

    return (
    
      <MuiThemeProvider>
        <div className="w3-left" style={{ width: "100%" }}>
          <br /><br />
          <div className="qikik">
            {this.props.createCourse ? <CreateCourse refreshCourseList={this.refreshCourseList} onCancel={this.onCancel} /> : ''}
            {this.props.courseList ? <Courses refreshCourseList={this.refreshCourseList} pageSize={this.state.pSize} page={this.state.pIndex} cbPrev={this.onStepBack.bind(this)} cbNext={this.onStepForward.bind(this)} courses={this.state.courses} courseData={this.state.courseListData} onCreateCourse={this.createCourse} onEditCourse={this.editCourse} /> : ''}
            {this.props.editCourse ? <EditCourse onCancel={this.onCancel} courseData={this.state.courseData} editTOC={this.editTOC} showTOC={this.showTOC} refreshCourseList={this.refreshCourseList} /> : ''}
            {(this.props.editTOC || this.props.showTOC) ? <EditTOC structureId={this.state.structureId} showTOC={this.props.showTOC} courseId={this.state.selectedCourseId} course= {this.state.course}/> : ''}
          </div>
        </div>

      </MuiThemeProvider>
    );
  }

}

export default QikToCDashboard;