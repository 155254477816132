const asset = {
      header: [ 'uid', 'createdBy', "name", 'description', 'sourceUrl', 'packageRoot', 
            'thumbnailUrl', 'contentTypeValue', 'fileType', 'limitByRoles', 'purposes', 'externalRef', 'section', 
            'sectionOrder', 'order', 'topic', 'subtopic1', 'subtopic2', 'hidetoc', 'maxattempt', 'maxgrade', 
            'isViewable', 'isDownloadable', 'countryCode', 'bookId', 'author', 'pageLayout', 'pageNavigation', 
            'createZip', 'resourcePanelUrl', 'metadataUrl', 'AD.participantCount', 'AD.timeLimit', 'AD.preSteps', 
            'AD.steps', 'AD.scoring', 'Status' ],
      dataInJson: [ 'uid', 'createdBy', 'metadata.name', 'metadata.description', 
            'progression.source.url', 'progression.package.root', 'metadata.thumbnailUrl', 'metadata.contentTypeValue', 
            'metadata.fileType', 'metadata.limitByRoles', 'metadata.purposes','metadata.externalRef', 'metadata.section',
            'metadata.sectionOrder','metadata.order', 'metadata.topic.name', 'metadata.topic.subtopic.name',
            'metadata.topic.subtopic.subtopic.name', 'metadata.hidetoc', 'metadata.maxattempt', 'metadata.maxgrade', 
            'metadata.isViewable','metadata.isDownloadable', 'metadata.countryCode', 'progression.book.id', 
            'metadata.author','metadata.pageLayout', 'metadata.pageNavigation', 'metadata.createZip', 
            'metadata.resourcePanelUrl', 'metadata.metadataUrl','metadata.activityData.participantCount', 
            'metadata.activityData.timeLimit', 'metadata.activityData.preSteps','metadata.activityData.steps', 
            'metadata.activityData.scoring', 'status']
}
const cartridge = {
      header: [ 'uid', 'createdBy', "name", 'subject', 'grade', 'schoolIds', 'externalIds', 'status'], 
      dataInJson: [ 'uid', 'createdBy', 'metadata.name', 'metadata.subject', 'metadata.grade', 
            'metadata.schoolIds', 'metadata.externalIds', 'status'] 
}
const assetAsset = {
      header: [ 'uid', 'createdBy', "assetId", 'assetUID', 'relatedAssetId', 'relatedAssetUID', 'type', 'pageId', 'status' ], 
      dataInJson: [ 'uid', 'createdBy', 'progression.assetId', 'metadata.assetUID', 'progression.relatedAssetId', 
            'metadata.relatedAssetUID', 'metadata.type', 'metadata.pageId', 'status'] 
}
const cartridgeAsset = {
      header: [ 'uid', 'createdBy', "assetId", "assetUID", "cartridgeId", "cartridgeUID", "name", 'description', 
            'limitByRoles', 'purposes', 'externalRef', 'section', 'sectionOrder', 'order', 'topic', 'subtopic1', 
            'subtopic2', 'hidetoc', 'maxattempt', 'maxgrade', 'isViewable', 'isDownloadable', 'status' ],
      dataInJson: [ 'uid', 'createdBy', 'progression.assetId', 'metadata.assetUID', 'progression.cartridgeId', 'metadata.cartridgeUID', 'metadata.name', 'metadata.description', 
            'metadata.limitByRoles', 'metadata.purposes','metadata.externalRef', 'metadata.section',
            'metadata.sectionOrder','metadata.order', 'metadata.topic.name', 'metadata.topic.subtopic.name',
            'metadata.topic.subtopic.subtopic.name', 'metadata.hidetoc', 'metadata.maxattempt', 'metadata.maxgrade', 
            'metadata.isViewable','metadata.isDownloadable', 'status'

      ]      
} 
const hashForData = {
      asset, cartridge, "asset-asset": assetAsset, "cartridge-asset": cartridgeAsset 
}
export default hashForData;