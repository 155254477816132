import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import CronListItem from './CronListItem'
import IconButton from 'material-ui/IconButton';
import "./cronslist.css"

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

const uuidV4 = require('uuid/v4')
//https://v0.material-ui.com/#/components/table
class CronsList extends React.Component {
  state = {
    fixedHeader: false,
    fixedFooter: false,
    stripedRows: false,
    showRowHover: false,
    selectable: false,
    multiSelectable: false,
    enableSelectAll: false,
    deselectOnClickaway: true,
    showCheckboxes: false,    
  };

  render() {
    var cronsTable = this.props.crons || []
    var token = this.props.token
    var rows = []
    cronsTable.forEach(function(cron) {
      rows.push(<CronListItem key={cron.id} cron={cron} token={token} />)
    })

    let minRowSpace = 8
    var emptyRows = []
    while (rows.length + emptyRows.length < minRowSpace) {
      emptyRows.push(<TableRow key={"empty"+emptyRows.length} />)
    }
    
    return (
      <MuiThemeProvider>
        <Table
          className="cronstable"
          bodyStyle={{overflow:'visible'}}
          style={{tableLayout: 'auto'}}
          fixedHeader={this.state.fixedHeader}
          fixedFooter={this.state.fixedFooter}
          selectable={this.state.selectable}
          multiSelectable={this.state.multiSelectable}        
        >
          <TableHeader
            displaySelectAll={this.state.showCheckboxes}
            adjustForCheckbox={this.state.showCheckboxes}
            enableSelectAll={this.state.enableSelectAll}
            >
              <TableRow>
                <TableHeaderColumn className="cronid">Group</TableHeaderColumn>
                <TableHeaderColumn className="cronname">Name</TableHeaderColumn>
                <TableHeaderColumn className="crondescription">Description</TableHeaderColumn>
                <TableHeaderColumn className="cronstatus">Status</TableHeaderColumn>
                <TableHeaderColumn className="cronactions">Action(s)</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody
              displayRowCheckbox={this.state.showCheckboxes}
              deselectOnClickaway={this.state.deselectOnClickaway}
              showRowHover={this.state.showRowHover}
              stripedRows={this.state.stripedRows}
            >
            {rows}
            {emptyRows}
            </TableBody>
          </Table>   
        </MuiThemeProvider>   
    )
  }
}

export default CronsList;
