import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import TextField from 'material-ui/TextField';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Popover from 'material-ui/Popover';
import FlatButton from 'material-ui/FlatButton';
import DatePicker from 'material-ui/DatePicker';
import RaisedButton from 'material-ui/RaisedButton';

import UserProvider from '../../Providers/UserProvider'
import BatchesList from '../BatchesList/BatchesList'
import BatchesProvider from '../../Providers/BatchesProvider'
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import SettingsProvider from '../../Providers/SettingsProvider'

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: "100%",
    margin: "10px",
    padding: "10px"
  },
  gridList: {
    width: 500,
    height: 450,
    overflowY: 'auto',
  },
};

class SaBatchesDashboard extends React.Component {
  state = {
    batches: [],
    searchText: localStorage.getItem("kBatchesSearchQuery"),
    pIndex: 0,
    pSize: 20,
    addBatchOptionsOpen: false,
    autoOk: true,
    fromDate: new Date(),
    toDate: new Date()
  };

  componentWillMount = () => {
    this.loadBatches(this.state.pIndex)
    AppDispatcher.addObserver("kReloadBatches", this, this.onReloadBatchesNotification)
  };

  componentDidMount = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    
    const d = new Date();
    d.setMonth(d.getMonth() - 2);

    this.setState({
      fromDate: currentDate,
      toDate: currentDate,
      minDate: d
    })
  }

  handleChangeFromDate = (event, date) => {
    this.setState({
      fromDate: date,
    });
  }

  handleChangeToDate = (event, date) => {
    this.setState({
      toDate: date,
    });
  }

  componentWillUnmount = () => {
    AppDispatcher.removeObserver("kReloadBatches", this, this.onReloadBatchesNotification)
  };

  onReloadBatchesNotification = (notification) => {
    this.setState({
      batches: []
    })
    this.loadBatches(this.state.pIndex)
  };

  onStepBack = () => {
    if (this.state.pIndex > 0) {
      this.loadBatches(this.state.pIndex - 1)
    }
  }

  onStepForward = () => {
    if (this.state.pSize === this.state.batches.length) {
      this.loadBatches(this.state.pIndex + 1)
    }
  }

  toYYYYMMDD = (v) => {
    const d = v.getDate();
    const m = v.getMonth() + 1;
    return `${v.getFullYear()}-${m<=9 ? ('0'+m) : m}-${d<=9 ? ('0'+d) : d}`
  }

  loadBatches = (page) => {
    const THIS = this
    const user = UserProvider.getCurrentUser()
    const userId = SettingsProvider.getBool("limitToCurrentUser") ? user.uid : null

    let reqFilters = {}
    reqFilters.name = this.state.searchText

    this.setState({
      batches: []
    })

    const { scope } = UserProvider.userHasPermission("monitor_batch_status") || {};
    const { saIds } = scope || {};
    const [saId] = saIds || [];

    BatchesProvider.searchSaBatches(userId, reqFilters, page, this.state.pSize, saId, this.toYYYYMMDD(this.state.fromDate), this.toYYYYMMDD(this.state.toDate), function (error, statusCode, batchData, filters) {
      if (statusCode === 200 && batchData) {
        if (batchData.page.pIndex === page
          // && name === query.name) 
          && (
            ((filters.name && filters.name === reqFilters.name) || !filters.name)
          )
        ) {
          THIS.setState({
            batches: batchData && batchData.batches ? batchData.batches : [],
            pIndex: page,
            total: batchData.page.total || 0
          })
        }
      }
    })
  }

  onSearchNow = () => {
    this.loadBatches(0)
  };

  handleSearchTextChange = (e) => {
    const value = e.target.value;
    localStorage.setItem("kBatchesSearchQuery", value)
    this.setState({
      searchText: value,
    });
  };

  render() {
    
    return (
      <MuiThemeProvider>
        <div className=''>
          <div className="w12-padding" style={styles.root}>
            <TextField className="w3-left" style={{ height: "72px", marginRight: 10 }}
              hintText="Search By Batch ID/Status"
              value={this.state.searchText}
              onChange={this.handleSearchTextChange}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                  this.onSearchNow(ev);
                }
              }}
            />
            <div className="w3-left">
              <DatePicker
                onChange={this.handleChangeFromDate}
                floatingLabelText="From Date"
                autoOk={this.state.autoOk}
                minDate={this.state.minDate}
                maxDate={this.state.toDate}
                defaultDate={this.state.fromDate}
              />
            </div>
            <div className="w3-left">
              <DatePicker
                onChange={this.handleChangeToDate}
                floatingLabelText="To Date"
                autoOk={this.state.autoOk}
                minDate={this.state.fromDate}
                defaultDate={this.state.toDate}
              />
            </div>
            <div className="w3-left">
              <RaisedButton label="Search" onClick={() => {
                this.onSearchNow();
              }} style={{
                margin: 12,
                marginTop: 18
              }} />
            </div>
            {/* <FlatButton
              className="w3-right w3-white w3-wide w3-bar-item w3-button"
              style={{ zIndex: "0" }}
              label="+ ADD"
              primary={true}
              onClick={this.onAddBatchClicked}
            /> */}

          </div>
          <div id="batches-list">
            {<BatchesList batches={this.state.batches} total={this.state.total} page={this.state.pIndex} pageSize={this.state.pSize} cbPrev={this.onStepBack.bind(this)} cbNext={this.onStepForward.bind(this)} />}
          </div>
          {this.renderBatchCreateOptions()}
        </div>
      </MuiThemeProvider>
    );
  }

  //Batch Create Options PopOver
  onAddBatchClicked = (e) => {
    this.setState({
      addBatchOptionsOpen: true,
      addBatchOptionsAnchor: e.currentTarget
    })
  }

  closeAddBatchPopup = () => {
    this.setState({
      addBatchOptionsOpen: false
    })
  };

  renderBatchCreateOptions = () => {
    var items = []

    if (UserProvider.userHasPermission("create_asset") || UserProvider.userHasPermission("create_cartridge")) {
      items.push(<MenuItem
        primaryText="Create Empty Batch"
        key="tsv-add-empty-batch"
        id="tsv-add-empty-batch"
        onClick={this.onCreateEmptyBatch} />)
    }

    let ret = <Popover
      open={this.state.addBatchOptionsOpen}
      anchorEl={this.state.addBatchOptionsAnchor}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      targetOrigin={{ horizontal: 'left', vertical: 'top' }}
      onRequestClose={this.closeAddBatchPopup}
    >
      <div>
        <p className="w3-padding w3-white">Allowed Actions: </p>
        <Menu>
          {items}
        </Menu>
      </div>
    </Popover>

    return ret
  };

  //END - Batch Create Options PopOver

  onCreateEmptyBatch = (e) => {

    this.setState({
      addBatchOptionsOpen: false
    })

    let user = UserProvider.getCurrentUser()
    BatchesProvider.createEmptyBatch(user.uid, function (error, statusCode, response) {
      if (statusCode == 200 && response && response.id) {
        AppDispatcher.postNotification("kOpenBatch", response)
        return
      }
      alert(JSON.stringify(error))
    })
  }
}

export default SaBatchesDashboard;
