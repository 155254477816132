import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import FlatButton from 'material-ui/FlatButton';
import csvToJson from 'csvtojson'
import XLSX from 'xlsx';
import ToCRenderer from './ToCRenderer/ToCRenderer2'
import { IconButton } from 'material-ui';
import ActionInfo from 'material-ui/svg-icons/action/info-outline';


class QikToCDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        selectedFile: {}
    };
    this.onFileAdded = this.onFileAdded.bind(this)
    this.onProcessFile = this.onProcessFile.bind(this)

  };

  onFileAdded = (e) => {
    const files = e.target.files;
    if (files && files[0]) this.setState({ selectedFile: files[0] });
  };

  onProcessFile = (e) => {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
      /* Get first worksheet */
      console.log(JSON.stringify(wb.SheetNames))
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const tocCSV =  XLSX.utils.sheet_to_csv(wb.Sheets["toc"])
      const conceptsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["concepts"])
      const topicsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["topics"])
      const subtopicsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["subtopics"])
      const assetsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["assets"])

      console.log("toc \n", tocCSV)

      this.setState({
          tocCSV: tocCSV,
          conceptsCSV: conceptsCSV,
          topicsCSV: topicsCSV,
          subtopicsCSV: subtopicsCSV,
          assetsCSV: assetsCSV
      }, () => {
          this.parseToC()
      })

    };
    if (rABS) {
        reader.readAsBinaryString(this.state.selectedFile);
      } else {
        reader.readAsArrayBuffer(this.state.selectedFile);
      };

    };

  parseToC = () => {
    let tsvPayload = this.state.tocCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            tocJSON: itemsFromCSV
        }, () => {
            this.parseConcepts()
        })
        
    })             
  }

  parseConcepts = () => {
    let tsvPayload = this.state.conceptsCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            conceptsJSON: itemsFromCSV
        }, ()=> {
            this.parseTopics()
        })
        
    })             
  }

  parseTopics = () => {
    let tsvPayload = this.state.topicsCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            topicsJSON: itemsFromCSV
        }, ()=> {
            this.parseSubtopics()
        })

    })             
  }

  parseSubtopics = () => {
    let tsvPayload = this.state.subtopicsCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
          subtopicsJSON: itemsFromCSV
        }, ()=> {
            this.parseAssets()
        })

    })             
  }

  parseAssets = () => {
    let tsvPayload = this.state.assetsCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            assetsJSON: itemsFromCSV,
        }, () => {
            this.putItTogether()
        })
    })             
  }

  putItTogether = () => {
      let toc = this.state.tocJSON[0]
      let concepts = this.state.conceptsJSON
      let topics = this.state.topicsJSON
      let subtopics = this.state.subtopicsJSON
      let assets = this.state.assetsJSON
      if (!toc || !concepts || !topics || !subtopics || !assets) {
          alert("missing data")
          return
      }

      let conceptsDic = {}
      concepts.forEach(function(aConcept) {
        if (aConcept.tag) {
          conceptsDic[aConcept.tag] = aConcept
          if (!toc.nodes) {
              toc.nodes = []
          }
          toc.nodes.push(aConcept)
          delete aConcept.Duplicate
          delete aConcept.tag
        }
      })

      let topicsDic = {}
      topics.forEach(function(aTopic) {
        if (aTopic.tag && aTopic.conceptTag && aTopic.topicTag) {
          topicsDic[aTopic.topicTag] = aTopic
          let thisConcept = conceptsDic[aTopic.conceptTag]
          if (!thisConcept.nodes) {
            thisConcept.nodes = []
          }
          thisConcept.nodes.push(aTopic)

          delete aTopic.Duplicate
          delete aTopic.tag
          delete aTopic.conceptTag
          delete aTopic.topicTag
        }
      })

      let subtopicsDic = {}
      subtopics.forEach(function(aSubtopic) {
        if (aSubtopic.tag && aSubtopic.topicTag && aSubtopic.subtopicTag) {
          subtopicsDic[aSubtopic.subtopicTag] = aSubtopic
          let thisTopic = topicsDic[aSubtopic.topicTag]
          if (!thisTopic.nodes) {
            thisTopic.nodes = []
          }
          thisTopic.nodes.push(aSubtopic)

          delete aSubtopic.Duplicate
          delete aSubtopic.tag
          delete aSubtopic.subtopicTag
          delete aSubtopic.topicTag
        }
      })


      assets.forEach(function(anAsset) {
        if (anAsset.tag && anAsset.subtopicTag) {
          let thisSubtopic = subtopicsDic[anAsset.subtopicTag]
          if (!thisSubtopic.nodes) {
            thisSubtopic.nodes = []
          }
          thisSubtopic.nodes.push(anAsset)
          delete anAsset.tag
          delete anAsset.subtopicTag
        }
      })


      this.setState({
        toc: toc
      }, () => {
        // this.showToCDetails(toc)
      })
  }

  showToCDetails = (toc) => {
    let strJson = JSON.stringify(toc, null, 4)
    let info = {
      sender: this, 
      title: "ToC",
      content: strJson
    }

    AppDispatcher.postNotification("pop-it-up", info)

  }



  render() {

    let toc = this.state.toc ? <ToCRenderer toc={this.state.toc} /> : ""

    return (
      <MuiThemeProvider>
        <div className="w3-left" style={{width:"100%"}}>
        <br/><br/>
        <div className="w3-left" >
        Upload Qikik ToC XLSX (<a href="https://docs.google.com/spreadsheets/d/1gaj8p2YdrIfYRQLZvtm7qYlSmKYdEZiZ5u_BluN71RY/edit?usp=sharing">master template</a>):

            <input type="file" className="form-control" id="file" onChange={this.onFileAdded} />
            <input type='submit'  value="Process ToC" onClick={this.onProcessFile} />
            <IconButton style={{zIndex:"0"}} tooltip="show info" key="show-info" onClick={function() {
                this.showToCDetails(this.state.toc)
            }.bind(this)}>
              <ActionInfo />
            </IconButton>


        </div>
        <br/><br/>
        {toc}
        </div>

        </MuiThemeProvider>
    );
  }

}

export default QikToCDashboard;