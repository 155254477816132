import WebUtil from '../utils/WebUtil'
let apiVersion = "1.0"

let apipaths = {
    login: "/user/authenticate",
    user: "/user",
    logout: "/user/logout",
    permissions: "/admin/permissions",
    usersFetch: "/user/fetch"
}

class GAB {   
    searchUser = (query, token, deviceId, callback) => {
        let payload = query
        const headers = tokenAuthHeaders(deviceId, token)
        let request = {
            method: "POST",
            url: gaburl() + apipaths.usersFetch,
            headers: headers,
            body: payload
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })
    }

    findUser = (data, token, deviceId, callback) => {
        if (!data) {
            callback(200, null, [])
        }

        var payload = {
            username: [data],
            startIndex: 0,
            endIndex: 0
        }

        let THIS = this
        this.searchUser(payload, token, deviceId, function(error, statusCode, list1) {
            payload.email = [data]
            delete payload.username
            THIS.searchUser(payload, token, deviceId, function(error, statusCode, list2) {

                let ret = []
                let collected = []
                if (Array.isArray(list1)) {
                    list1.forEach(function(aUser) {
                        if (collected.indexOf(aUser.id) < 0) {
                            ret.push(aUser)
                            collected.push(aUser.id)
                        }
                    })                    
                }
                if (Array.isArray(list2)) {
                    list2.forEach(function(aUser) {
                        if (collected.indexOf(aUser.id) < 0) {
                            ret.push(aUser)
                            collected.push(aUser.id)
                        }
                    })                    
                }

                callback(error, statusCode, ret)

            })
        })
    }

    fetchUsersByUIDs = (uids, token, deviceId, callback) => {
        if (!uids || !uids.length) {
            callback(200, null, [])
            return
        }

        var payload = {
            userId: uids,
            startIndex: 0,
            endIndex: 0
        }
        this.searchUser(payload, token, deviceId, callback)
    } 

    login = (username, password, deviceId, callback) => {
        const headers = standardHeaders(deviceId)

        let payload = {
            username: username,
            password: password
        }

        let request = {
            method: "POST",
            url: gaburl() + apipaths.login,
            headers: headers, 
            body: payload
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var loginResponse = null
            var error = null
            try {
                loginResponse = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, loginResponse)
        })
    }

    getUser = (token, deviceId, callback) => {
        const headers = tokenAuthHeaders(deviceId, token)
        let request = {
            method: "GET",
            url: gaburl() + apipaths.user,
            headers: headers
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })
        
    }

    getPermissions = (token, deviceId, callback) => {
        const headers = tokenAuthHeaders(deviceId, token)
        let request = {
            method: "GET",
            url: gaburl() + apipaths.permissions,
            headers: headers
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })
    }

    logout = (token, deviceId, callback) => {
        const headers = tokenAuthHeaders(deviceId, token)
        let request = {
            method: "GET",
            url: gaburl() + apipaths.logout,
            headers: headers
        }
        
        WebUtil.execute(request.method, request.url, request.headers, request.body, function(statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            callback(error, statusCode, response)
        })

    }
}

export default new GAB();

function standardHeaders(deviceId) {
    var headers = { 
        deviceid: deviceId
        , appversion: apiVersion
        , Accept: "application/json"
        // , "user-agent": "ingestion-parkinglot-ui-worker"
        , "accept-language": "en"
    };   
    
    return headers
}

function tokenAuthHeaders(deviceId, token) {
    var headers = standardHeaders(deviceId, apiVersion)
    headers.authorization = "Bearer " + token
    return headers
}

function gaburl() {
    return process.env.REACT_APP_GAB_URL
}