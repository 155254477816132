import React from 'react';
import UserProvider from '../../Providers/UserProvider'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import BatchListItem from './BatchListItem'
import IconButton from 'material-ui/IconButton';
import ActionPrev from 'material-ui/svg-icons/navigation/arrow-back';
import ActionNext from 'material-ui/svg-icons/navigation/arrow-forward';
import "./batcheslist.css"

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

const uuidV4 = require('uuid/v4')
//https://v0.material-ui.com/#/components/table
class BatchesList extends React.Component {
  state = {
    fixedHeader: false,
    fixedFooter: false,
    stripedRows: false,
    showRowHover: false,
    selectable: false,
    multiSelectable: false,
    enableSelectAll: false,
    deselectOnClickaway: true,
    showCheckboxes: false,    
  };

  onGoPrev = (e) => {
    this.props.cbPrev()
  }
  onGoNext = (e) => {
    this.props.cbNext()
  }


  render() {
    var batchesTable = this.props.batches
    var rows = []
    batchesTable.forEach(function(batch) {
      rows.push(<BatchListItem key={batch.id} batch={batch} />)
    })

    let minRowSpace = 8
    var emptyRows = []
    while (rows.length + emptyRows.length < minRowSpace) {
      emptyRows.push(<TableRow key={"empty"+emptyRows.length} />)
    }

    var pageInfo = ""
    if (Array.isArray(this.props.batches) && this.props.batches.length) {
      let currentIndex = this.props.page * this.props.pageSize + 1
      let countOnPage = this.props.batches.length
      let countTotal = this.props.total
  
      pageInfo = "Showing " + currentIndex + " - " + (currentIndex + countOnPage - 1) + " of " + countTotal
    }
    
    return (
      <MuiThemeProvider>
        <Table
          className="batchestable"
          bodyStyle={{overflow:'visible'}}
          style={{tableLayout: 'auto'}}
          fixedHeader={this.state.fixedHeader}
          fixedFooter={this.state.fixedFooter}
          selectable={this.state.selectable}
          multiSelectable={this.state.multiSelectable}        
        >
          <TableHeader
            displaySelectAll={this.state.showCheckboxes}
            adjustForCheckbox={this.state.showCheckboxes}
            enableSelectAll={this.state.enableSelectAll}
            >
              <TableRow>
                <TableHeaderColumn style={{textAlign: 'left'}}>
                  <IconButton style={{zIndex:"0"}} disabled={this.props.page === 0} onClick={this.onGoPrev.bind(this)}>
                    <ActionPrev />
                  </IconButton>
                </TableHeaderColumn>                
                <TableHeaderColumn colSpan="4" style={{textAlign: 'center'}}>
                  {pageInfo}
                </TableHeaderColumn>                
                <TableHeaderColumn style={{textAlign: 'right'}}>
                  <IconButton style={{zIndex:"0"}} disabled={this.props.batches.length < this.props.pageSize} onClick={this.onGoNext.bind(this)}>
                    <ActionNext />
                  </IconButton>

                </TableHeaderColumn>                
              </TableRow>
              <TableRow>
                <TableHeaderColumn className="batchid">Batch ID</TableHeaderColumn>
                <TableHeaderColumn className="batchdate">Date</TableHeaderColumn>
                <TableHeaderColumn className="batchname">Batch Name</TableHeaderColumn>
                <TableHeaderColumn className="batchdescription">Description</TableHeaderColumn>
                <TableHeaderColumn className="batchcreator">Creator</TableHeaderColumn>
                <TableHeaderColumn className="batchcreator">Collaborators</TableHeaderColumn>
                <TableHeaderColumn className="batchstatus">Status</TableHeaderColumn>
                <TableHeaderColumn className="batchactions">Action(s)</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody
              displayRowCheckbox={this.state.showCheckboxes}
              deselectOnClickaway={this.state.deselectOnClickaway}
              showRowHover={this.state.showRowHover}
              stripedRows={this.state.stripedRows}
            >
            {rows}
            {emptyRows}
            </TableBody>
          </Table>   
        </MuiThemeProvider>   
    )
  }
}

export default BatchesList;
