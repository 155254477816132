import React from 'react';

const  ErrorBlock = (props) => {
    return (
        <div className="error-block">
            <span>{props.text}</span>
            {
                props.children
            }
        </div> 
    )
}
export default ErrorBlock;