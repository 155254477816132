import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import IconButton from 'material-ui/IconButton';
import ActionPrev from 'material-ui/svg-icons/navigation/arrow-back';
import ActionNext from 'material-ui/svg-icons/navigation/arrow-forward';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import QikikAdminProvider from './providers/QikikAdminProvider'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

class Courses extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    fixedHeader: false,
    fixedFooter: false,
    stripedRows: false,
    showRowHover: false,
    selectable: false,
    multiSelectable: false,
    enableSelectAll: false,
    deselectOnClickaway: true,
    showCheckboxes: false,    
  };

  deleteBatch = (e) => {
    console.log(e);
    QikikAdminProvider.deleteCourse(e, this.onDeleteCourse);
  }

  onDeleteCourse = (response) => {
    if(response && response.course) {
      alert("Course Deleted Successfuly!!");
      this.props.refreshCourseList();
    }
  }

  onGoPrev = (e) => {
    this.props.cbPrev()
  }
  onGoNext = (e) => {
    this.props.cbNext()
  }

  render() {
    var courseData = this.props.courses
    var rows = []
    let editBtnHndlr = this.props.onEditCourse;
    courseData.forEach((course) => {
      rows.push( 
      <TableRow key={course.id}>  
        <TableRowColumn >{ (course.name.length > 20) ? course.name.substring(0,20)+ "..." : course.name }</TableRowColumn>
        <TableRowColumn >{ (course.description.length > 25) ? course.description.substring(0,25)+ "..." : course.description }</TableRowColumn>
        <TableRowColumn >{ (course.grade.length > 10) ? course.grade.substring(0,10)+ "..." : course.grade }</TableRowColumn>
        <TableRowColumn >{course.colorCode}</TableRowColumn>
        <TableRowColumn>{ (course.subject.length > 20) ? course.subject.substring(0,20)+ "..." : course.subject }</TableRowColumn>
        <TableRowColumn ><img className="thumbnail-fix" src={course.thumbnail} /></TableRowColumn>
        <TableRowColumn >
          <div>
            <span style={{lineHeight : '50px'}} onClick={() => editBtnHndlr(course.id)}><a href="#">Edit</a></span>
            {/* <IconButton style={{zIndex:"0"}} tooltip="" key="delete" onClick={(e) => this.deleteBatch(course.id)}>
              <ActionDelete />
            </IconButton> */}
          </div>
        </TableRowColumn>
      </TableRow>)
    })

    let minRowSpace = 8
    var emptyRows = []
    while (rows.length + emptyRows.length < minRowSpace) {
      emptyRows.push(<TableRow key={"empty"+emptyRows.length} />)
    }

    var pageInfo = ""
    if (Array.isArray(this.props.courses) && this.props.courses.length) {  
      let currentIndex = (this.props.page * 20) + 1
      let countOnPage = currentIndex + this.props.courses.length - 1;
      let countTotal = this.props.courseData.page.count;
      pageInfo = "Showing " + currentIndex + " - " + countOnPage + " of " + countTotal
    }

    return (
      <MuiThemeProvider>
        <div>
          <div className="w-100">
            <div className="left-block"><h3>Courses</h3></div>
            <div className="right-block"> <button type="button" onClick={this.props.onCreateCourse}>+ Create course</button></div>
          </div>
          <div className="table-div">
            <Table
              bodyStyle={{ overflow: 'visible' }}
              style={{ tableLayout: 'auto' }}
              fixedHeader={this.state.fixedHeader}
              fixedFooter={this.state.fixedFooter}
              selectable={this.state.selectable}
              multiSelectable={this.state.multiSelectable}
            >
              <TableHeader
                displaySelectAll={this.state.showCheckboxes}
                adjustForCheckbox={this.state.showCheckboxes}
                enableSelectAll={this.state.enableSelectAll}
              >
                <TableRow>
                  <TableHeaderColumn style={{ textAlign: 'left' }}>
                    <IconButton style={{ zIndex: "0" }} disabled={this.props.page === 0} onClick={this.onGoPrev.bind(this)}>
                      <ActionPrev />
                    </IconButton>
                  </TableHeaderColumn>
                  <TableHeaderColumn colSpan="4" style={{ textAlign: 'center' }}>
                    {pageInfo}
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{ textAlign: 'right' }}>
                    <IconButton style={{ zIndex: "0" }} disabled={this.props.courseData.data && this.props.courseData.data.length < this.props.pageSize} onClick={this.onGoNext.bind(this)}>
                      <ActionNext />
                    </IconButton>

                  </TableHeaderColumn>
                </TableRow>
                <TableRow>

                  <TableHeaderColumn >Name</TableHeaderColumn>
                  <TableHeaderColumn >Description</TableHeaderColumn>
                  <TableHeaderColumn >Grade</TableHeaderColumn>
                  <TableHeaderColumn >Color Theme</TableHeaderColumn>
                  <TableHeaderColumn >Subject</TableHeaderColumn>Thumbnail
                <TableHeaderColumn >Thumbnail</TableHeaderColumn>

                  <TableHeaderColumn >Action(s)</TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody
                displayRowCheckbox={this.state.showCheckboxes}
                deselectOnClickaway={this.state.deselectOnClickaway}
                showRowHover={this.state.showRowHover}
                stripedRows={this.state.stripedRows}
              >
                {rows}
                {emptyRows}
              </TableBody>
            </Table>
          </div>
        </div>
      </MuiThemeProvider>

    )
  }
}

export default Courses;
