import React from 'react';
import QikikAdminProvider from '../../providers/QikikAdminProvider';
export default class ParentPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          tocId: this.props.structureId, 
          nodeId: this.props.node.nodeId,
          parentNodeId: this.props.node.parentNodeId,
          name: this.props.node.name.en, 
          description: this.props.node.description.en,
          concept: 'parent',
          conceptname: '',
          conceptdescription: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
      }
    
      handleSubmit(event) {
        event.preventDefault();
        this.props.showLoader();
        if(this.state.concept != "concept"){
        let reqBody = {
            "name": {
                  "en": this.state.name
              },
              "description": {
                "en": this.state.description
              }
            }
            QikikAdminProvider.updateTOC(this.state.tocId, reqBody, this.onUpdateConceptPanel);
        } else {
          let reqBody = {
            "name": {
                "en": this.state.conceptname
            },
            "description": {
              "en": this.state.conceptdescription
            },
            "type": "container",
            "label": "concept"
          }
          QikikAdminProvider.insertNode(this.state.tocId, this.state.tocId, reqBody, this.onAddTopicPanel);
        } 
      }
    
      onUpdateConceptPanel = (response) => {
        if(response && response.id){
            this.props.updateToc(response.uuid);
        }
      }

      onAddTopicPanel = (response) => {
        if(response && response.id){
            this.props.updateTopicToc(response.id);
        }
      }
    
      addConcept = (event) => {
        event.preventDefault();
        this.setState({
          'concept':'concept'
        })
      }
    
      onAddConceptPanel = (response) => {
        if(response && response.id){
          console.log(this.props.uuid);
            this.props.updateToc(this.props.structureId);
        }
      }

  hideLoader() {
    document.getElementById("loader2").style.display = "none";
  }

  showLoader() {
    document.getElementById("loader2").style.display = "block";
  }

  onCancel = () => {
    this.setState({
      'concept':'parent'
    })
  }

  render() {
    return (
        <div className="p-20">
          <div className="w-50">
          { this.state.concept == 'concept' ?   
            <form  onSubmit={this.handleSubmit}>
            <h3>Concept Panel</h3>
              <label for="fname">Name</label>
              <input type="text" name="conceptname" value={this.state.conceptname} onChange={this.handleChange} />
              
              <label for="fname">Description</label>
              <input type="text" name="conceptdescription" value={this.state.conceptdescription} onChange={this.handleChange} />
              <br />
              <input type="submit" value="Submit" />
            </form> : <form  onSubmit={this.handleSubmit}> 
              <h3>Parent Panel</h3>
              <label for="fname">Name</label>
              <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
              
              <label for="fname">Description</label>
              <input type="text" name="description" value={this.state.description} onChange={this.handleChange} />
              <br />
          
              <input type="submit" value="Submit" />
            </form>}
          </div>

          {/* <button id="addTopic" onClick={this.addConcept} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton"> Add Concept </button> */}
          <div className="button-container">
          { this.state.concept == 'concept' ?   
            <button id="addTopic" onClick={this.onCancel} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton"> Cancel </button>
            : ''
          }
          </div>
        </div>
      );
  }
}