
import ADAP from "./../thirdparty/ADAP";
class AdapProvider {

  publish(courseId, payload){
         return ADAP.publish(courseId, payload)
  }

}

export default new AdapProvider();
