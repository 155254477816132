import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { FlatButton } from 'material-ui';
import IconContainer from 'material-ui/svg-icons/navigation/unfold-more';
import IconAsset from 'material-ui/svg-icons/navigation/last-page';
const uuidV4 = require('uuid/v4')

class ToCRenderer2 extends React.Component {
  constructor(props) {
    super(props)
    let myToC = JSON.parse(JSON.stringify(props.toc))
    myToC.uuid = myToC.uuid || uuidV4()
    myToC.type = "container"
    myToC.label = "ToC"

    this.makeContainerNode = this.makeContainerNode.bind(this)
    this.makeAssetNode = this.makeAssetNode.bind(this)

    this.state = {
      toc: myToC,
      showList: []
    }
  }


  makeContainerNode = (node) => {
    node.uuid = node.uuid || uuidV4()
    let THIS = this
    let childrenNodes = function(start) {
        let list = []
        if (start.nodes) {
          start.nodes.forEach(function(aChild) {
            if (aChild.type === "container") {
              list.push(THIS.makeContainerNode(aChild))
            }
            else if (aChild.type === "asset") {
              list.push(THIS.makeAssetNode(aChild))
            }
          })
        }
        return list
    }

    let ret = 
    <div id={node.uuid + "-root"} className="w3-container w3-show w3-left" style={{border:1, width:"100%"}}>  
      <button onClick={function() {
          let showList = this.state.showList
          var index = showList.indexOf(node.uuid);
          if (index > -1) {
            showList.splice(index, 1);
          }
          else {
            showList.push(node.uuid)
          }
          this.setState({
            showList: showList
          })
        }.bind(this)}   
        className="w3-btn w3-grey w3-block w3-left-align">
          {"[" + node.type + " : " + node.label  + " : " + (node.nodes ? node.nodes.length : 0) + " children" + "] " + node.name.en}
        </button>

        <div id={node.uuid} className={this.state.showList.indexOf(node.uuid) >= 0 ? "w3-container w3-left-align w3-show" : "w3-container w3-left-align w3-hide"}>
          {/* <h4>root info</h4>
          <p>node: {node.uuid}</p> */}

          {childrenNodes(node)}

        </div>

    </div>

    return ret
  }

  makeAssetNode = (node) => {
    node.uuid = node.uuid || uuidV4()
    let ret = 
    <div id={node.uuid + "-root"} className="w3-container w3-show w3-left" style={{border:1, width:"100%"}}>  
      <button onClick={function() {
          let showList = this.state.showList
          var index = showList.indexOf(node.uuid);
          if (index > -1) {
            showList.splice(index, 1);
          }
          else {
            showList.push(node.uuid)
          }
          this.setState({
            showList: showList
          })
        }.bind(this)}   
        className="w3-btn w3-block w3-sand w3-left-align">
          {"[" + node.type + " : " + node.label + "] " + node.name.en}
        </button>

        <div id={node.uuid} className={this.state.showList.indexOf(node.uuid) >= 0 ? "w3-container w3-left-align w3-show" : "w3-container w3-left-align w3-hide"}>
          {/* <h4>root info</h4>
          <p>root info</p> */}

        </div>

    </div>


    return ret
  }



  generateFlatList = (toc, flatList, nodes, nesting) => {
    let THIS = this
    if (nodes) {
      nodes.forEach(function(aNode) {
        aNode.uuid = aNode.uuid || uuidV4()
        aNode.nesting = nesting
        flatList.push(aNode)
        if (aNode.type === "container") {
          let subNodes = aNode.nodes
          delete aNode.nodes
          THIS.generateFlatList(toc, flatList, subNodes, nesting+1)
        }
      })
    }
  }


  render() {
    let myToC = this.state.toc

    let nodes = this.makeContainerNode(myToC)

    return (
        <div  className="w3-left" style={{width:"110%"}}>
            <div className="w3-left">
              {myToC.name.en}
            </div>
            <br/><br/>
            {nodes}
        </div>
    )
  }
}

export default ToCRenderer2;