import WebUtil from '../utils/WebUtil'
import HawkHeaders from '../utils/HawkHeaders'
const uuidV4 = require('uuid/v4')

let apipaths = {

    v3AssetsFetch: "/v3/pulse/asset/fetch",
    createAsset: "/v1/{tenantTag}/assetcontexts/create",
    unSetMetadata: "/v1/{tenantTag}/assetcontexts/assetid/{assetId}"
}


class ARS {
    v3AssetsFetch = (payload, limit = 50, returnTotalCount = false) => {
        var path = apipaths.v3AssetsFetch + `?limit=${limit}&returnTotalCount=${returnTotalCount}`;


        const headers = {
            authorization: HawkHeaders.generateMacHeader(ars_rul(), path, hawkId(), hawkSecret(), 'POST')
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "POST",
            url: ars_rul() + path,
            headers: headers,
            body: payload
        }
        return new Promise((resolve, reject) => {
            WebUtil.execute(request.method, request.url, request.headers, request.body, function (statusCode, responseString) {
                var response = null
                var error = null
                if (statusCode == 200) {
                    try {
                        response = JSON.parse(responseString);
                        return resolve(response)
                    }
                    catch (err) {
                        error = err
                        return reject(error);
                    }
                } else {
                    return reject(responseString)
                }
            })
        })
    }
    createAsset = (tenantTag, payload) => {
        var path = apipaths.createAsset.replace("{tenantTag}", tenantTag);

        const headers = {
            authorization: HawkHeaders.generateMacHeader(ars_rul(), path, hawkId(), hawkSecret(), 'POST')
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "POST",
            url: ars_rul() + path,
            headers: headers,
            body: payload
        }
        return new Promise((resolve, reject) => {

            WebUtil.execute(request.method, request.url, request.headers, request.body, function (statusCode, responseString) {

                var response = null
                var error = null
                if (statusCode == 200) {
                    try {
                        response = JSON.parse(responseString);
                        return resolve(response)
                    }
                    catch (err) {
                        error = err
                        return reject(error);
                    }
                } else {
                    return reject(responseString)
                }
            })
        })

    }
    unSetMetadata = (tenantTag, assetId, payload) => {
        let path = apipaths.unSetMetadata.replace("{tenantTag}", tenantTag);
        path.replace("{assetId}", assetId);

        const headers = {
            authorization: HawkHeaders.generateMacHeader(ars_rul(), path, hawkId(), hawkSecret(), 'PUT')
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "PUT",
            url: ars_rul() + path,
            headers: headers,
            body: payload
        }
        return new Promise((resolve, reject) => {

            WebUtil.execute(request.method, request.url, request.headers, request.body, function (statusCode, responseString) {

                var response = null
                var error = null
                if (statusCode == 200) {
                    try {
                        response = JSON.parse(responseString);
                        return resolve(response)
                    }
                    catch (err) {
                        error = err
                        return reject(error);
                    }
                } else {
                    return reject(responseString)
                }
            })
        })
    }
}
export default new ARS();
function ars_rul() { return process.env.REACT_APP_ARS_URL }
function hawkId() { return process.env.REACT_APP_ARS_HAWK_ID }
function hawkSecret() { return process.env.REACT_APP_ARS_HAWK_SECRET }