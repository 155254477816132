import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import TextField from 'material-ui/TextField';
import BatchItemsList from '../BatchItemsList/BatchItemsList'
import BatchesProvider from '../../Providers/BatchesProvider'
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import ActionInfo from 'material-ui/svg-icons/action/info-outline';
import ActionSubmit from 'material-ui/svg-icons/action/launch';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import ActionRefresh from 'material-ui/svg-icons/action/autorenew';
import ActionAdd from 'material-ui/svg-icons/content/add';
import IconButton from 'material-ui/IconButton';
import BatchCollaborators from '../BatchCollaborators/BatchCollaborators'
import hashForData from './csvStrings'
import { CSVLink } from "react-csv";
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import AddItemsToBatchDialog from "../AddItemsToBatchDialog/AddItemsToBatchDialog"

class BatchDetail extends React.Component {
  state = {
    searchText: "",
    batchitems: [],
    batchDetailOpen: false,
    pIndex: 0,
    pSize: 0,
    hld: {all:{total:0,completed:0},asset:{total:0,completed:0},cartidge:{total:0,completed:0},cartidgeAsset:{total:0,completed:0},assetToAsset:{total:0,completed:0}},
    editingBatchName: false,
    itemTypesFilter:[],
    txtBatchName: "",
    batchStatus: "",
    openAddTSVItemsDialog: false
  };
  totalItems = []
  componentWillMount = () => {
    this.getTotalItemsToshowHighLevelData();
    this.reloadBatchItems(this.state.pIndex)
    this.getBatchById();
    AppDispatcher.addObserver("kReloadBatchItems", this, this.reloadItemsNotification)
  }

  componentWillUnmount =() => {
    AppDispatcher.removeObserver("kReloadBatchItems", this, this.reloadItemsNotification)
  };
  
  

  reloadItemsNotification = (notification) => {
    let batch = notification.info
    if (batch.id === this.props.batch.id) {
      this.reloadBatchItems(this.state.pIndex)
    }
  }

  onStepBack = () => {
    if (this.state.pIndex > 0) {
      this.reloadBatchItems(this.state.pIndex - 1)
    }
  }

  onStepForward = () => {
    if (this.state.pSize === this.state.batchitems.length) {
      this.reloadBatchItems(this.state.pIndex + 1)
    }
  }
  parseResponseDataToRequestData = ()=>{
     return this.totalItems.filter(item => (item.type === this.state.itemTypesFilter[0]))
      .map((item)=>this.getValueFromResponseJson(item))
  }
  getValueFromResponseJson = (item)=>{
    return hashForData[this.state.itemTypesFilter[0]].dataInJson.map((key)=>key.split('.').reduce((curObj,index)=>{
      if(!curObj){
        return ""
      }else{
        if(index === "limitByRoles"){
          return Array.isArray(curObj[index]) ? curObj[index].join("|") : ''
        }
        if(index === "purposes" || index === 'preSteps' || index === 'steps' || 
          index === 'scoring' || index === 'schoolIds'){
          return Array.isArray(curObj[index]) ? curObj[index].join(";") : ''
        }
        if(index === "externalRef" || index === 'externalIds'){
          return Array.isArray(curObj[index]) ? 
            curObj[index].map((extRef)=>{
              return extRef.source+'|'+extRef.originId;
            }).join(';') : ''
        }
        return curObj[index]; 
      }
    },item))
  }
  exportData = ()=>{
    let parsedData = this.parseResponseDataToRequestData();
    parsedData.unshift(hashForData[this.state.itemTypesFilter[0]].header);
    return parsedData;
  }
  getBatchById = ()=>{
    BatchesProvider.getBatchById(this.props.batch.id, this.updateBatchStatus)
  }
  updateBatchStatus = (error, statusCode, response)=>{
    if (statusCode === 200 && (response && response.constructor === Object)) {
      this.setState({batchStatus: response.status})  
    }
  }
  onSubmitBatch = () => {
    let THIS = this
    BatchesProvider.submitBatch(this.props.batch.id, function(error, statusCode, response) {
      if (!error && statusCode === 200 && response && response.id === THIS.props.batch.id) {
        AppDispatcher.postNotification("kOpenBatch", response)
        THIS.refresh()
        return
      }
      alert("error: ", JSON.stringify(error))
    })
  }
  callbackTotalItems = (error, statusCode, itemsData)=> {
    if (statusCode === 200 && itemsData && itemsData.items) {
        let totalItems = itemsData.items;
        let completedTotal = totalItems.filter((item)=>(item.status === 'completed'));
        let totalAssets = totalItems.filter((item)=>(item.type === 'asset'));
        let totalCartridges = totalItems.filter((item)=>(item.type === 'cartridge'));
        let totalCartidgeAssets = totalItems.filter((item)=>(item.type === 'cartridge-asset'));
        let totalAssetToAssets = totalItems.filter((item)=>(item.type === 'asset-asset'));
        let completedAssets = totalItems.filter((item)=>(item.type === 'asset' && item.status === 'completed'));
        let completedCartridges = totalItems.filter((item)=>(item.type === 'cartridge' && item.status === 'completed'));
        let completedCartidgeAssets = totalItems.filter((item)=>(item.type === 'cartridge-asset' && item.status === 'completed'));
        let completedAssetToAssets = totalItems.filter((item)=>(item.type === 'asset-asset' && item.status === 'completed'));
        let hld = {
          all: {
            total: totalItems.length,
            completed: completedTotal.length
          },
          asset: {
            total: totalAssets.length,
            completed: completedAssets.length
          },
          cartidge: {
            total: totalCartridges.length,
            completed: completedCartridges.length
          },
          cartidgeAsset: {
            total: totalCartidgeAssets.length,
            completed: completedCartidgeAssets.length
          },
          assetToAsset: {
            total: totalAssetToAssets.length,
            completed: completedAssetToAssets.length
          }
        }
        this.totalItems = totalItems;
        this.setState({hld})
    }
  }
  getTotalItemsToshowHighLevelData = ()=>{
    BatchesProvider.searchBatchItems(this.props.batch.id, null, null, null, null, this.callbackTotalItems)
  }
  reloadBatchItems = (page) => {
    const THIS = this
    let reqFilters = {}
    this.closePopups()

    reqFilters.name = this.state.searchText

    this.setState({
      batchitems: []
    })


    BatchesProvider.searchBatchItems(this.props.batch.id, reqFilters, this.state.itemTypesFilter, page, this.state.pSize, function(error, statusCode, itemsData, filters) {
      if (statusCode === 200 && itemsData) {
        if (
          itemsData.page.pIndex === page
          &&
          ((filters.name && filters.name === reqFilters.name) || !filters.name)
        ) 
        {
          THIS.setState({
            batchitems: itemsData && itemsData.items ? itemsData.items : [],
            pIndex: page
          })    
        }
      }
    })
  };
  
  onSearchNow = () => {
    this.reloadBatchItems(0)
  };

  refresh = ()  => {
    this.reloadBatchItems(this.state.pIndex)
    this.getBatchById();
    this.getTotalItemsToshowHighLevelData();
  }

  onDeleteBatch = (e) => {
    let batch = this.props.batch
    BatchesProvider.deleteBatch(batch.id, function(error, statusCode, response) {
      AppDispatcher.postNotification("kOpenDashboard", {})
    })
  }

  handleSearchTextChange = (e) => {
    const value = e.target.value;

    this.setState({
      searchText: value,
    });
  };
  onEditBatchName = (e) => {
    this.setState({
      editingBatchName: true,
      txtBatchName: this.props.batch.name
    })
  }

  updateBatchName = (e) => {
    let THIS = this
    BatchesProvider.updateBatchName(this.props.batch.id, this.state.txtBatchName, function(error, statusCode, response) {
      if (statusCode === 200 && response.name) {
        THIS.props.batch.name = response.name
      }
      else {
        alert("Unable to rename batch.")
      }
      THIS.setState({
        editingBatchName: false,
        txtBatchName: ""
      })
    })
  }

  handleBatchNameChange = (e) => {
    const value = e.target.value;
    this.setState({
      txtBatchName: value,
    });
  }


  render() {
    let actions = []

    if (this.state.editingBatchName) {
          actions.push(
            <TextField id={this.props.batch.id}
              value={this.state.txtBatchName}
              onChange={this.handleBatchNameChange}
              autoFocus={true}
              onKeyDown={(ev) => {
                if (ev.key === "Escape") {
                  this.setState({
                    editingBatchName: false,
                    txtBatchName: ""
                  })
                }
              }}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                  this.updateBatchName(ev);
                }
              }}            
            />
          )
    }
    else {
      actions.push(
        <FlatButton
            style={{zIndex:"0"}}
            label={this.props.batch.name}
            primary={true}
            onClick={this.onEditBatchName}
            disabled={!BatchesProvider.canDeleteBatch(this.props.batch)}
          />
      )
    }

    if (this.props.batch.status === "draft") {
      actions.push(
        <IconButton style={{zIndex:"0"}} tooltip="Add Items" onClick={function() {
            this.setState({
              openAddTSVItemsDialog: true
            })
          }.bind(this) }
        ><ActionAdd /></IconButton>        
      )

      actions.push(
        <IconButton style={{zIndex:"0"}} tooltip="Submit Batch" onClick={this.onSubmitBatch}><ActionSubmit /></IconButton>        
      )

    }

    actions.push(
        <IconButton style={{zIndex:"0"}} tooltip="Info" onClick={this.showBatchDetail}><ActionInfo /></IconButton>        
    )

    actions.push(
      <IconButton style={{zIndex:"0"}} tooltip="Refresh" onClick={this.refresh.bind(this)}><ActionRefresh /></IconButton>        
  )


    if (BatchesProvider.canDeleteBatch(this.props.batch)) {
      actions.push(<IconButton style={{zIndex:"0"}} tooltip="Delete Batch" onClick={this.onDeleteBatch}><ActionDelete /></IconButton>)
    }

    return (
        <MuiThemeProvider>
        <div>
          <br/>
          <div className="w3-padding">
          
          <TextField className="w3-left w3-white w3-wide w3-bar-item w3-button" 
            hintText="search"
            value={this.state.searchText}
            onChange={this.handleSearchTextChange}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                // Do code here
                ev.preventDefault();
                this.onSearchNow(ev);
              }
            }}            
          />
          <p className="w3-right w3-white">
            {actions}
          </p>
          </div>

          <br/><br/>
          <div className="w3-padding w3-left w3-white">
          <div className="align-left">
            <div className="padb">Batch status: <span className={this.state.batchStatus}>{this.state.batchStatus}</span></div>
            
          </div>
          <div className="row-button">
            <div className="padb">Show:</div>
            
          </div>
          <div className="row-button">
            <FlatButton style={{zIndex:"0"}} label={`all`} primary={this.state.itemTypesFilter.length === 0} 
                onClick={function() {
                  this.state.itemTypesFilter.splice(0, this.state.itemTypesFilter.length)
                  this.reloadBatchItems(0)
                }.bind(this)}
            />
            <strong className="draft">{this.state.hld.all.completed+"/"+this.state.hld.all.total}</strong>
          </div>
          <div className="row-button">
            <FlatButton style={{zIndex:"0"}} label="assets" primary={this.state.itemTypesFilter.indexOf("asset") >= 0} 
              onClick={function() {
                this.state.itemTypesFilter.splice(0, this.state.itemTypesFilter.length)
                this.state.itemTypesFilter.push("asset")
                this.reloadBatchItems(0)
              }.bind(this)}

            />
            <strong className="draft">{this.state.hld.asset.completed+"/"+this.state.hld.asset.total}</strong>
          </div>
          <div className="row-button">
            <FlatButton style={{zIndex:"0"}} label="cartridges" primary={this.state.itemTypesFilter.indexOf("cartridge") >= 0} 
              onClick={function() {
                this.state.itemTypesFilter.splice(0, this.state.itemTypesFilter.length)
                this.state.itemTypesFilter.push("cartridge")
                this.reloadBatchItems(0)
              }.bind(this)}

            />
            <strong className="draft">{this.state.hld.cartidge.completed+"/"+this.state.hld.cartidge.total}</strong>
          </div>
          <div className="row-button">  
            <FlatButton style={{zIndex:"0"}} label="cartridge assets" primary={this.state.itemTypesFilter.indexOf("cartridge-asset") >= 0} 
              onClick={function() {
                this.state.itemTypesFilter.splice(0, this.state.itemTypesFilter.length)
                this.state.itemTypesFilter.push("cartridge-asset")
                this.reloadBatchItems(0)
              }.bind(this)}

            />
            <strong className="draft">{this.state.hld.cartidgeAsset.completed+"/"+this.state.hld.cartidgeAsset.total}</strong>
          </div>
          <div className="row-button">
            <FlatButton style={{zIndex:"0"}} label="asset-to-asset" primary={this.state.itemTypesFilter.indexOf("asset-asset") >= 0} 
              onClick={function() {
                this.state.itemTypesFilter.splice(0, this.state.itemTypesFilter.length)
                this.state.itemTypesFilter.push("asset-asset")
                this.reloadBatchItems(0)
              }.bind(this)}

            />
            <strong className="draft">{this.state.hld.assetToAsset.completed+"/"+this.state.hld.assetToAsset.total}</strong>
          </div> 
          

          </div>
         {Array.isArray(this.state.itemTypesFilter) && (this.state.itemTypesFilter.length > 0) &&       
            <div className="row-button download-btn">
              <CSVLink filename={this.state.itemTypesFilter[0]+"-csv-file.csv"} data={this.exportData()} className>Download CSV</CSVLink>
            </div>    
         }  
            <br/><br/><br/><br/><br/>
           
          <div className="w3-padding" id="batches-list">
          
          
          {<BatchItemsList 
              batchitems={this.state.batchitems} 
              page={this.state.pIndex} 
              pageSize={this.state.pSize} 
              cbPrev={this.onStepBack.bind(this)} 
              cbNext={this.onStepForward.bind(this)}
              itemTypesFilter={this.state.itemTypesFilter}
              refresh={this.refresh}
            />}
          </div>
          {this.renderBatchDetailPopup()}
          {<AddItemsToBatchDialog 
                batchName={this.props.batch.name}
                batchId={this.props.batch.id}
                show={this.state.openAddTSVItemsDialog} 
                callback={function(refresh) {
                  this.setState({
                    openAddTSVItemsDialog: false
                  })
                  if (refresh) {
                    this.refresh()
                    
                  }
                }.bind(this) }/>}
                 

        </div>
        
        </MuiThemeProvider>        
    );
  }

  editCollaborators = (e) => {
    let batch = this.props.batch
    let info = {
      sender: this, 
      title: "Collaborators for Batch: " + batch.id,
      content: <BatchCollaborators batch={batch}/>
    }

    AppDispatcher.postNotification("pop-it-up", info)
  }


  //Batch Detail PopOver


  showBatchDetail = (e) => {
    this.setState({
      batchDetailOpen: true,
      batchDetailAnchor: e.currentTarget
    })
  }


  renderBatchDetailPopup = () => {
    var ret = ""
    let batch = this.props.batch
  
    var items = []
    if (BatchesProvider.canEditBatchCollaborators(batch)) {
      items.push(<MenuItem 
        primaryText="Edit Collaborators" 
        key="tsv-edit-collaborators"
        id="tsv-edit-collaborators" 
        onClick={function(){
          this.setState({
            batchDetailOpen: false
          })
          this.editCollaborators()
        }.bind(this)} />)
    }

    //collaborators
    var collabValue = (batch.collaborators ? batch.collaborators.length : 0) + " collaborators"
    //end - collaborators

  
    ret = <Popover
              open={this.state.batchDetailOpen}
              anchorEl={this.state.batchDetailAnchor}
              anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
              targetOrigin={{horizontal: 'left', vertical: 'top'}}
              onRequestClose={this.closePopups}
            >
              <div>
                <p className="w3-padding w3-white">
                  <b>Name</b>: {batch.name}<br/>
                  <b>Owner</b>: {batch.creatorUser ? batch.creatorUser.name : batch.createdBy}<br/> 
                  <b>Collaborators</b>: {collabValue}
                </p>
                <Menu>
                  {items}
                </Menu>
              </div>
            </Popover>
  
    return ret
  };  

  closePopups = () => {
    this.setState({
      batchDetailOpen: false
    })
  };

  //END - Batch Detail PopOver

}



export default BatchDetail;