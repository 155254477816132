import WebUtil from '../utils/WebUtil'
import HawkHeaders from '../utils/HawkHeaders'
import { Promise } from 'bluebird';

let apipaths = {
    publish: "/api/courses/{courseId}/courseplans/create"
}

class ADAP {
    publish = (courseId, payload) => {
        var path = apipaths.publish.replace("{courseId}", courseId);

        const headers = {
            
             Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "POST",
            url: path,
            headers: headers,
            body: payload
        }
        return new Promise((resolve, reject) => {

            WebUtil.execute(request.method, request.url, request.headers, request.body, function (statusCode, responseString) {

                var response = null
                var error = null
                if (statusCode == 200) {
                    try {
                        response = JSON.parse(responseString);
                        return resolve(response)
                    }
                    catch (err) {
                        error = err
                        return reject(error);
                    }
                } else {
                    return reject(responseString)
                }
            })
        })
    } 

    }

export default new ADAP();

function ADP_url() { return process.env.REACT_APP_ADAP2_URL }
function hawkId() { return process.env.REACT_APP_ADAP2_HAWK_ID }
function hawkSecret() { return process.env.REACT_APP_ADAP2_HAWK_SECRET }