const Hawk = require('hawk')

class HawkHeaders {
    generateMacHeader = (uri, path, hawkId, hawkKey, method) => {
        const macUrl = uri + path;
        const timestamp = (Math.trunc(Date.now() / 1000));
        const credentials = {
          algorithm: "sha256",
          id: hawkId,
          key: hawkKey
        }
        const options = { credentials, timestamp };
        const header = Hawk.client.header(macUrl, method, options);
        return header.field;        
    }

    keySecretAuthorization = (uri, path, hawkId, hawkKey, method) => {
        let obj = {};
        obj.url = uri+'/'+path;
        obj.method = method;
        obj.headers = {};
        var diffTimestamp = 0;
        var date = new Date();
        var timestamp = date.getTime();
        var serverTimestamp = parseInt(timestamp / 1000);
        diffTimestamp = serverTimestamp - (Math.trunc(Date.now() / 1000) + 1000);
        var credentials = {};

        credentials.algorithm = "sha256";
        credentials.id = hawkId;
        credentials.key = hawkKey;

        var timestamp = (Math.trunc(Date.now() / 1000) + 1000) + diffTimestamp;
        var options = { credentials: credentials, timestamp: timestamp };
        var header = Hawk.client.header(obj.url, obj.method, options);
        obj.headers["Authorization"] = header.field;

        console.log(obj.headers["Authorization"]);

        return obj.headers["Authorization"];
    }
}
export default new HawkHeaders();