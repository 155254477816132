 const qikikContants = {
                        "ASSETTYPE" : [
                                'html',
                                'video'
                                ],
                        "THEMES" : [
                                'allports_001',
                                'valencia_001',
                                'bouquet_001',
                                'royalblue_001',
                                'easternblue_001',
                                'junglegreen_001',
                                'royalblue_002'
                                ],
                        "COUNTRY": [{
                                code: "us",
                                name: "USA"
                                },
                                {
                                    code: "in",
                                    name: "India"
                                }
                                ],
                        "STARTDATE": "2019-10-01",
                        "ENDDATE": "2030-12-30",
                        "MODELID": "{{productModelId}}"
}
export default qikikContants;
        