import React from 'react';
import QikToCDashboard from './components/QikToCDashboard/QikToCDashboard'
export default class EditTOC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <QikToCDashboard showTOC={this.props.showTOC} courseId={this.props.courseId} course = {this.props.course} structureId={this.props.structureId} />  
      </div>
    );
  }
}
