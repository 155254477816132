import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import FlatButton from 'material-ui/FlatButton';
import csvToJson from 'csvtojson'
import XLSX from 'xlsx';
import ToCRenderer from './ToCRenderer/ToCRenderer2'
import { IconButton } from 'material-ui';
import ActionInfo from 'material-ui/svg-icons/action/info-outline';


class OAEPToCDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        selectedFile: {}
    };
    this.onFileAdded = this.onFileAdded.bind(this)
    this.onProcessFile = this.onProcessFile.bind(this)

  };

  onFileAdded = (e) => {
    const files = e.target.files;
    if (files && files[0]) this.setState({ selectedFile: files[0] });
  };

  onProcessFile = (e) => {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
      /* Get first worksheet */
      console.log(JSON.stringify(wb.SheetNames))
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const tocCSV =  XLSX.utils.sheet_to_csv(wb.Sheets["toc"])
      const levelsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["levels"])
      const modulesCSV = XLSX.utils.sheet_to_csv(wb.Sheets["modules"])
      const tasksCSV = XLSX.utils.sheet_to_csv(wb.Sheets["tasks"])

      console.log("toc \n", tocCSV)

      this.setState({
          tocCSV: tocCSV,
          levelsCSV: levelsCSV,
          modulesCSV: modulesCSV,
          tasksCSV: tasksCSV
      }, () => {
          this.parseToC()
      })

    };
    if (rABS) {
        reader.readAsBinaryString(this.state.selectedFile);
      } else {
        reader.readAsArrayBuffer(this.state.selectedFile);
      };

    };

  parseToC = () => {
    let tsvPayload = this.state.tocCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            tocJSON: itemsFromCSV
        }, () => {
            this.parseLevels()
        })
        
    })             
  }

  parseLevels = () => {
    let tsvPayload = this.state.levelsCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            levelsJSON: itemsFromCSV
        }, ()=> {
            this.parseModules()
        })
        
    })             
  }

  parseModules = () => {
    let tsvPayload = this.state.modulesCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            modulesJSON: itemsFromCSV
        }, ()=> {
            this.parseTasks()
        })

    })             
  }

  parseTasks = () => {
    let tsvPayload = this.state.tasksCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            tasksJSON: itemsFromCSV,
        }, () => {
            this.putItTogether()
        })
    })             
  }
  putItTogether = () => {
      let toc = this.state.tocJSON[0]
      let levels = this.state.levelsJSON
      let modules = this.state.modulesJSON
      let tasks = this.state.tasksJSON
      if (!toc || !levels || !modules || !tasks) {
          alert("missing data")
          return
      }

      let levelsDic = {}
      levels.forEach(function(aLevel) {
          levelsDic[aLevel.tag] = aLevel
          if (!toc.nodes) {
              toc.nodes = []
          }
          toc.nodes.push(aLevel)
          delete aLevel.Duplicate
          delete aLevel.tag
      })

      let modulesDic = {}
      modules.forEach(function(aModule) {
          modulesDic[aModule.moduleTag] = aModule
          let thisLevel = levelsDic[aModule.levelTag]
          if (!thisLevel.nodes) {
              thisLevel.nodes = []
          }
          thisLevel.nodes.push(aModule)

          delete aModule.Duplicate
          delete aModule.tag
          delete aModule.levelTag
          delete aModule.moduleTag
      })

      tasks.forEach(function(aTask) {
          let thisModule = modulesDic[aTask.moduleTag]
          if (!thisModule.nodes) {
              thisModule.nodes = []
          }
          thisModule.nodes.push(aTask)
          delete aTask.tag
          delete aTask.moduleTag
      })


      this.setState({
        toc: toc
      }, () => {
        // this.showToCDetails(toc)
      })
  }

  showToCDetails = (toc) => {
    let strJson = JSON.stringify(toc, null, 4)
    let info = {
      sender: this, 
      title: "ToC",
      content: strJson
    }

    AppDispatcher.postNotification("pop-it-up", info)

  }



  render() {

    let toc = this.state.toc ? <ToCRenderer toc={this.state.toc} /> : ""

    return (
      <MuiThemeProvider>
        <div className="w3-left" style={{width:"100%"}}>
        <br/><br/>
        <div className="w3-left" >
            Upload OAE ToC XLSX (<a href="https://docs.google.com/spreadsheets/d/1CIN3w1LOzAmVkjJmoD2Cc6Z5ptxLoAIPV5-rH5mlgcQ/edit?usp=sharing">master template</a>):

            <input type="file" className="form-control" id="file" onChange={this.onFileAdded} />
            <input type='submit'  value="Process ToC" onClick={this.onProcessFile} />
            <IconButton style={{zIndex:"0"}} tooltip="show info" key="show-info" onClick={function() {
                this.showToCDetails(this.state.toc)
            }.bind(this)}>
              <ActionInfo />
            </IconButton>


        </div>
        <br/><br/>
        {toc}
        </div>

        </MuiThemeProvider>
    );
  }

}

export default OAEPToCDashboard;