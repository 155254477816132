import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import BatchesProvider from "./BatchesProvider.js";
import { Promise } from "bluebird";
import AAPI from "./../thirdparty/AAPI";
class AssignmentProvider {

  CreateAssignment = (tenantTag, batchId, assignments) => {

    // parking bath item payload for assignment
    let payload = _.map(assignments, assignment => {

      let item = {
        "type": "assignment",
        "createdBy": assignment.createdBy,
        "uid": assignment.assignmentId,
        "metadata": {
          "tenantTag": tenantTag,
          "assignmentId": assignment.assignmentId,
          "name": assignment.name,
          "assignmentUrn": `${tenantTag}:AAPI:assignment:${assignment.assignmentId}`,
          "type": assignment.type,
          "isTemplate": false,
          "description": assignment.name,
          "status": "assigned",
          "nodeId": assignment.nodeId,
          "createdBy": assignment.createdBy

        }
      }
      if (assignment.diagnosticNodeId) {
        item.metadata.diagnosticNodeId = assignment.diagnosticNodeId;
      }
      return item;
    });


    return BatchesProvider.addBatchItem(batchId, payload);

  }
  CreateAssignments = (createdBy, batchId, topics, subTopics, assets) => {

    let assignments = {};

    assignments.check = _.filter(_.uniqBy(assets, 'CheckAssignment'), asset => {
      return asset.CheckAssignment && asset.CheckAssignment != "";
    });

    assignments.practice = _.filter(_.uniqBy(assets, 'PracticeAssignment'), asset => {
      return asset.PracticeAssignment && asset.PracticeAssignment != "";
    });

    assignments.learn = _.filter(_.uniqBy(assets, 'LearnAssignment'), asset => {
      return asset.LearnAssignment && asset.LearnAssignment != "";
    });

    assignments.learn = this.prepareLearnNuggetAssignment(subTopics, assignments.learn, createdBy);
    // check nugget assignments 
    assignments.check = this.prepareDiagnosticNuggetAssignment("check", topics, assignments.check, createdBy);
    // practice nugget assignments 
    assignments.practice = this.prepareDiagnosticNuggetAssignment("practice", topics, assignments.practice, createdBy);
    // return Promise.map([...assignments.learn, ...assignments.check, ...assignments.practice],
    //   assignment => {
    //     return Promise.resolve(assignment);//this.CreateAssignment("pulse", batchId, assignment);
    //   }, { concurrency: 5 })
    //   .then(assignments => {
    return this.CreateAssignment("pulse", batchId, [...assignments.learn, ...assignments.check, ...assignments.practice])
      .then((assRes) => {
        console.log(assRes);
        return [...assignments.learn, ...assignments.check, ...assignments.practice];
      })

  }

  prepareLearnNuggetAssignment = (subTopics, assets, createdBy) => {

    return _.map(assets, (asset) => {
      let subTopicTag = asset.subtopicTag;
      let subTopic = _.find(subTopics, { subtopicTag: subTopicTag });
      if (!subTopic) {
        alert(`Problem in Excel Please Correct It Before Upload!!\n
         Asset subtopicTag value not able to find the topic details\n
         AssetId : ${asset.assetId} \n
         subtopicTag : ${asset.subtopicTag} \n
         ${JSON.stringify(asset)}`);
         console.log(`Problem in Excel Please Correct It Before Upload!!\n
         Asset subtopicTag value not able to find the topic details\n
         AssetId : ${asset.assetId} \n
         subtopicTag : ${asset.subtopicTag} \n
         ${JSON.stringify(asset)}`);
         throw "subtopic not found";
        return false;
      }
      return {
        assignmentId: uuidv4(),
        type: "learn",
        nodeId: subTopic.nodeId,
        name: asset.LearnAssignment,
        createdBy: createdBy
      };
    })

  }
  prepareDiagnosticNuggetAssignment = (assignmentType, topics, assets, createdBy) => {
    let assignments = [];
    return _.map(assets, asset => {
      let conceptTag = asset.subtopicTag.split('.')[0];
      let topicTag = conceptTag + "." + asset.subtopicTag.split('.')[1];
      let topic = _.find(topics, { topicTag: topicTag });
      if(!topic){
        console.log("topic tag",topicTag,"asset details", JSON.stringify(asset))
        throw "topic not found";

      }
      let diagnosticNugget = _.find(assignments, { nodeId: topic.nodeId });
      let diagnosticNodeId = null;
      if (diagnosticNugget) {
        diagnosticNodeId = diagnosticNugget.diagnosticNodeId;
      } else {
        diagnosticNodeId = uuidv4();
      }
      if (!topic) {
        alert(`Problem in Excel Please Correct It Before Upload!!\n
         Asset subtopicTag value not able to find the topic details\n
         ${JSON.stringify(asset)}`);

      }
      let types = {
        check: "CheckAssignment",
        practice: "PracticeAssignment"
      }
      let assign = {
        assignmentId: uuidv4(),
        type: assignmentType,
        nodeId: topic.nodeId,
        name: asset[types[assignmentType]],
        diagnosticNodeId: diagnosticNodeId,
        createdBy: createdBy
      }
      assignments.push(assign);

      return assign;
    })

  }

  AddAssetToAssignment = (tenantTag, batchId, assignmentsAssets, createdBy) => {

    let payload = _.map(assignmentsAssets, assignment => {
      return _.map(assignment.assets, asset => {
        return {
          "type": "assignment-asset",
          "assignmentUID": assignment.assignmentId,
          "metadata": {
            "tenantTag": tenantTag,
            "assignmentId": assignment.assignmentId,
            "cartridgeId": asset.cartridgeId,
            "assetId": asset.arsAssetId,
            "order": asset.order,
            "metadata": asset.metadata,
            "createdBy": createdBy
          },
          "createdBy": createdBy
        }
      })
    })

    let items = this.getItemsByLimit(_.flattenDeep(payload), 200);

    return Promise.map(items, item => {
      return BatchesProvider.addLinkItem(batchId, item);
    }, { concurrency: 1 })
      .then(res => {
        return _.flattenDeep(res);
      });
  }
  getItemsByLimit = (items, limit) => {
    let nodes = [], skipCount = 0;
    do {
      nodes.push(_.take(_.drop(items, skipCount), limit));
      skipCount = skipCount + limit;
    } while (skipCount < items.length);
    return nodes;
  }
  CreateAssignmentAssets = (username, batchId, topics, subtopics, assets, assignments, cartridgesAssets) => {

    return Promise.all(_.map(assignments, assignment => {
      let types = {
        check: "CheckAssignment",
        practice: "PracticeAssignment",
        learn: "LearnAssignment"
      }
      let questionGroupsFieldName = {
        check: "CheckGroup",
        practice: "PracticeGroup",
        learn: "LearnGroup"
      }
      let query = {};
      query[types[assignment.type]] = assignment.name;
      let assignmentAssets = _.filter(assets, query);
      let order =0;
      let assignmentAssetsOrder = _.map(assignmentAssets, asset => {
        order= order+1;
        return {
          order: order,
          assetId: asset.assetId
        }
      })
      let questionGroups = _.filter(_.uniq(_.map(assignmentAssets, (questionGroupsFieldName[assignment.type]))), (e) => {
        return e != "";
      });

      let questionGroupAssets = _.map(questionGroups, group => {

        query = {};
        query[questionGroupsFieldName[assignment.type]] = group;
        let groupAssets = _.clone(_.filter(assignmentAssets, query), true);
        return _.map(groupAssets, (asset) => {
          let ass = {};
          let assetOrder = _.find(assignmentAssetsOrder,{assetId:asset.assetId});
          ass.order = assetOrder.order;
          let cartridge = _.find(cartridgesAssets, { name: group });
          ass.cartridgeId = cartridge.cartridgeId;
          ass.arsAssetId = asset.arsAssetId;
          ass.metadata = {
            answerType: "multi-one-correct",
            points: 1,
            assessmentObjectives: [asset.Bloom || "dev"
            ]
          }
          if (asset.assetType === "video") {
            ass.metadata.cartridgeType = "video";
          }
          return ass;
        })
      })
      console.log("question group assets", questionGroupAssets);

      return {
        assignmentId: assignment.assignmentId,
        assets: _.flattenDeep(questionGroupAssets)
      }

    }))
      .then(assignmentAssets => {
        return this.AddAssetToAssignment('pulse', batchId, assignmentAssets, username);
      })
      .then(result => {
        //return this.AddAssetToAssignment('pulse', batchId, assignment.assignmentId, assignment.assignmentId, _.flattenDeep(questionGroupAssets), username);
        console.log("Assignment assets are created.", result);
      })
  }

  getAssignmentsByNodeId = (tenantTag, nodeId) => {

    return AAPI.fetchAssignments(tenantTag, nodeId);

  }
  getAssignmentsByToc = (tenantTag, toc, courseId, subjectId, levelId) => {
    let topicIds = _.flattenDeep(_.map(toc.nodes, node => (_.map(node.nodes, 'nodeId'))));

    let subTopicIds = _.map(_.flattenDeep(_.map(toc.nodes, conc => { return _.map(conc.nodes, "nodes") })), "nodeId")

    return Promise.all([
      Promise.map(topicIds, topicId => {
        return this.getAssignmentsByNodeId(tenantTag, topicId);
      }, { concurrency: 5 }),
      Promise.map(subTopicIds, subtopicId => {
        return this.getAssignmentsByNodeId(tenantTag, subtopicId);
      }, { concurrency: 5 })])
      .spread(async (topicAssignments, subTopicAssignments) => {
        subTopicAssignments = _.flattenDeep(subTopicAssignments);
        topicAssignments = _.flattenDeep(topicAssignments);

        let checkAssignments = _.filter(topicAssignments, { type: "check" });
        let practiceAssignments = _.filter(topicAssignments, { type: "practice" });
        let topicsOrders = _.flattenDeep(this.generateTopics(toc));
        return Promise.map([subTopicAssignments, practiceAssignments, checkAssignments], (assignments) => {

          return this.fetchAssignmentsAssets(tenantTag, assignments);

        }, { concurrency: 5 })
          .then((assignmentAssets) => {
            return Promise.props({
              check: this.mapToDiagnosticCatalogs(tenantTag, toc, checkAssignments, topicsOrders, assignmentAssets[2]),
              practice: this.mapToDiagnosticCatalogs(tenantTag, toc, practiceAssignments, topicsOrders, assignmentAssets[1]),
              topics: topicsOrders,
              learn: this.generateCatalogs(tenantTag, toc, topicsOrders, subTopicAssignments, assignmentAssets[0])

            });
          })

      })
      .then(catalogs => {
        let topics = _.flattenDeep(catalogs.topics)
        catalogs.learn = _.flattenDeep(catalogs.learn);
        let catalogsInOrder = [];

        _.each(topics, (topic) => {
          let index = 0;
          let checkCatalog = _.filter(catalogs.check, { topicId: topic.topicId });
          _.each(checkCatalog, catalog => {
            catalog.orderInTopic = index++;
          })
          let learnCatalogs = _.filter(catalogs.learn, { topicId: topic.topicId });
          learnCatalogs = _.sortBy(learnCatalogs, ['orderNumber']);
          _.each(learnCatalogs, catalog => {
            delete catalog.orderNumber;
            catalog.orderInTopic = index++;
          })

          let practiceCatalogs = _.filter(catalogs.practice, { topicId: topic.topicId });

          _.each(practiceCatalogs, catalog => {
            catalog.orderInTopic = index++;
          })
          catalogsInOrder.push(...checkCatalog); // check nugget is first element.
          catalogsInOrder.push(...learnCatalogs); // learn nugget is second element. 
          catalogsInOrder.push(...practiceCatalogs); // practice nugget is third element.

        })
        let coursePlan = {
          "courseType": "standard",
          "courseId": courseId,
          "gradingStructureId": null,
          "catalogs": catalogsInOrder,
          "levelId": levelId,
          "coursePlanId": uuidv4(),
          "subjectId": subjectId,
          "topics": topics
        }
        console.log("Course plan payload ", coursePlan);
        return coursePlan;

      })
  }

  mapToDiagnosticCatalogs = (tenantTag, toc, assignments, topicsOrders, diagnosticCatalogsAssets) => {
    let catalogIds = _.uniq(_.map(assignments, "diagnosticNodeId"));

    return Promise.map(catalogIds, catalogId => {

      let catalogAssignments = _.filter(assignments, { "diagnosticNodeId": catalogId });
      return Promise.map(catalogAssignments, async (ass) => {

        let nodeLinks = await AAPI.fetchLink(tenantTag, ass.assignmentId);

        let prerequisiteCatalogs = [];

        prerequisiteCatalogs = _.map(nodeLinks, link => {
          return _.map(link.linkedNodes, "nodeId")
        });

        prerequisiteCatalogs = _.uniq(_.flattenDeep(prerequisiteCatalogs));

        return Promise.resolve({
          "assessmentId": ass.assignmentId,
          "questionGroups": [],
          "prerequisiteNuggets": prerequisiteCatalogs
        });
      }, { concurrency: 1 })
        .then(async (catalogAssignments) => {

          let diagnosticNodeAssignments = _.filter(assignments, { "diagnosticNodeId": catalogId });

          let assets = _.flattenDeep(_.map(diagnosticNodeAssignments, assignment => {
            return _.filter(diagnosticCatalogsAssets, { assignmentId: assignment.assignmentId });
          }))
          let points = _.uniq(_.map(assets, "cartridgeId")).length; // for every question group, one pint

          let topics = _.flattenDeep(_.map(toc.nodes, concept => {
            return concept.nodes;
          }));
          let topic = _.find(topics, { nodeId: diagnosticNodeAssignments[0].nodeId });
          let topicOrder = _.find(topicsOrders, { topicId: diagnosticNodeAssignments[0].nodeId });
          return Promise.resolve({
            "associations": [],
            "orderInTopic": topicOrder.topicPriority,
            "optional": false,
            "topicId": topic.nodeId,
            "assessments": catalogAssignments,
            "timesVisible": [],
            "assessmentObjectives": [],
            "points": points,
            "prerequisites": [],
            "catalogId": catalogId

          });
        })
    })

  }
  fetchAssignmentsAssets = (tenantTag, assignments) => {

    return Promise.map(assignments, assignment => {

      return AAPI.fetchAssignmentAssets(tenantTag, assignment.assignmentId);
    }, { concurrency: 10 })
      .then(assignmentAssets => {

        return _.flattenDeep(assignmentAssets);
      })

  }

  generateTopics = (toc) => {
    let order = 0;
    return _.map(toc.nodes, concept => {

      return _.map(concept.nodes, topic => {
        return {
          "topicId": topic.nodeId,
          "topicWeight": 1,
          "topicPriority": order++
        }
      })
    })

  }
  generateCatalogs = (tenantTag, toc, topicsOrders, subTopicsAssignments, subTopicsAssignmentsAssets) => {

    return Promise.map(toc.nodes, concepts => {

      return Promise.map(concepts.nodes, topic => {

        let topicsOrder = _.find(topicsOrders, { topicId: topic.nodeId })

        return Promise.map(topic.nodes || [], async (subtopic) => {

          let assignments = _.filter(subTopicsAssignments, { nodeId: subtopic.nodeId });
          let assignmentAssets = _.flatMapDeep(_.map(assignments, assignment => {
            return _.filter(subTopicsAssignmentsAssets, { assignmentId: assignment.assignmentId });
          }));
          let points = _.uniq(_.map(assignmentAssets, "cartridgeId")).length; // for every question group, one pint

          let linkNodes = await AAPI.fetchLink(tenantTag, subtopic.nodeId);
          let prerequisites = _.map(linkNodes, link => {
            return _.map(link.linkedNodes, "nodeId");
          });
          prerequisites = _.flattenDeep(prerequisites);
          return Promise.resolve({
            "associations": [],
            "orderInTopic": topicsOrder.topicPriority,
            "optional": false,
            "topicId": subtopic.parentNodeId,
            "assessments": [],
            "orderNumber": subtopic.orderNumber,
            "timesVisible": [],
            "assessmentObjectives": [],
            "points": points,
            "prerequisites": prerequisites,
            "catalogId": subtopic.nodeId
          });
        }, { concurrency: 5 })
      }, { concurrency: 5 })
    }, { concurrency: 10 })
  }
}

export default new AssignmentProvider();
