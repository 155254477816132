import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import UserProvider from '../../Providers/UserProvider'
import IconButton from 'material-ui/IconButton';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import ActionInfo from 'material-ui/svg-icons/action/info-outline';
//https://material.io/tools/icons/?icon=edit&style=baseline
import ActionEdit from 'material-ui/svg-icons/editor/mode-edit';
import BatchCollaborators from '../BatchCollaborators/BatchCollaborators'
import BatchesProvider from '../../Providers/BatchesProvider'
import TextField from 'material-ui/TextField';
import "./batcheslist.css"

import {
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
const kStartedEditingBatchName = "kStartedEditingBatchName"
const uuidV4 = require('uuid/v4')
//https://v0.material-ui.com/#/components/table
class BatchListItem extends React.Component {

  state = {
    editingName: false,
    txtBatchName: ""
  }

  componentWillMount = () => {
    AppDispatcher.addObserver(kStartedEditingBatchName, this, this.onStartedEditingBatchName)
  };

  componentWillUnmount =() => {
    AppDispatcher.removeObserver(kStartedEditingBatchName, this, this.onStartedEditingBatchName)
  };

  onStartedEditingBatchName = (notification) => {
    let sender = notification.info.sender
    if (sender !== this) {
      this.setState({
        editingName: false,
        txtBatchName: ""
      })
    }
  }

  openBatch = (e) => {
    AppDispatcher.postNotification("kOpenBatch", this.props.batch)
  }

  deleteBatch = (e) => {
    let batch = this.props.batch
    let currentUser = UserProvider.getCurrentUser()
    BatchesProvider.deleteBatch(batch.id, function(error, statusCode, response) {
      AppDispatcher.postNotification("kReloadBatches", {})
    })
  }

  editCollaborators = (e) => {
    let batch = this.props.batch
    let info = {
      sender: this, 
      title: "Collaborators for Batch: " + batch.id,
      content: <BatchCollaborators batch={batch}/>
    }

    AppDispatcher.postNotification("pop-it-up", info)
  }

  editBatchName = (e) => {
    AppDispatcher.postNotification(kStartedEditingBatchName, {batch: this.props.batch, sender: this})
    this.setState({
      editingName: true,
      txtBatchName: this.props.batch.name
    })
  }
  handleBatchNameChange = (e) => {
    const value = e.target.value;
    this.setState({
      txtBatchName: value,
    });
  };

  updateBatchName = (e) => {
    let THIS = this
    BatchesProvider.updateBatchName(this.props.batch.id, this.state.txtBatchName, function(error, statusCode, response) {
      if (statusCode === 200 && response.name) {
        THIS.props.batch.name = response.name
      }
      else {
        alert("Unable to rename batch.")
      }
      THIS.setState({
        editingName: false,
        txtBatchName: ""
      })

    })
  }


  render() {
    let batch = this.props.batch

    let actions = []
    actions.push(
      <IconButton style={{zIndex:"0"}} tooltip="show info" key="show-info" onClick={this.openBatch}>
        <ActionInfo />
      </IconButton>
    )

    if (BatchesProvider.canDeleteBatch(batch)) {
      actions.push(
        <IconButton style={{zIndex:"0"}} tooltip="" key="delete" onClick={this.deleteBatch}>
          <ActionDelete />
        </IconButton>
      )
    }

    let editName = <IconButton style={{zIndex:"0", width:"12px", height:"12px"}} iconStyle={{width:"12px", height:"12px"}} tooltip="" onClick={this.editBatchName}><ActionEdit /></IconButton>

    //collaborators
    var collabValue = (batch.collaborators ? batch.collaborators.length : 0) + " collaborators"
    //END - collaborators
    let createdDateFromUTC = new Date(batch.createdAt)
    let date = createdDateFromUTC.getDate();
    let year = createdDateFromUTC.getFullYear();
    let month = createdDateFromUTC.getMonth();
    let createdDate = date+'-'+(month+1)+'-'+year;
    return (
      
      <TableRow key={batch.id} className={batch.status}>
        <TableRowColumn className="batchid">{batch.id}</TableRowColumn>
        <TableRowColumn className="batchdate">{createdDate}</TableRowColumn>
        <TableRowColumn className="batchname">
          {
            (this.state.editingName) ? 
              <TextField id={batch.id}
              value={this.state.txtBatchName}
              onChange={this.handleBatchNameChange}
              autoFocus={true}
              onKeyDown={(ev)=>{
                if (ev.key === "Escape") {
                  this.setState({
                    editingName: false,
                    txtBatchName: ""
                  })
                }
              }}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                  this.updateBatchName(ev);
                }
              }}            
              />
              : 
              (BatchesProvider.canEditBatchName(batch) ? editName : "")
          }
          {(this.state.editingName) ? "" : batch.name}
        </TableRowColumn>
        <TableRowColumn className="batchdescription">{batch.description ? batch.description : "n/a"}</TableRowColumn>
        <TableRowColumn className="batchcreator">{batch.creatorUser ? batch.creatorUser.name : batch.createdBy}</TableRowColumn>
        <TableRowColumn className="batchcreator">
          {
            BatchesProvider.canEditBatchCollaborators(batch) ? 
              <IconButton style={{zIndex:"0", width:"12px", height:"12px"}} iconStyle={{width:"12px", height:"12px"}} onClick={this.editCollaborators} >
                  <ActionEdit />
              </IconButton> 
              : 
              ""
          }
          {collabValue}
        </TableRowColumn>
        <TableRowColumn className="batchcstatus">{batch.status}</TableRowColumn>
        <TableRowColumn className="batchcactions">{actions}</TableRowColumn>
      </TableRow>
    )
  }
}

export default BatchListItem;