import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import IconButton from 'material-ui/IconButton';
import ActionInfo from 'material-ui/svg-icons/action/info-outline';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import UserProvider from '../../Providers/UserProvider'
import BatchesProvider from '../../Providers/BatchesProvider';
import "./batchitemlist.css"
import ItemDisplay from "./ItemsDisplayConfig"


import {
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';


const uuidV4 = require('uuid/v4')
//https://v0.material-ui.com/#/components/table
class BatchItemsListItem extends React.Component {

  deleteItem = (e) => {
    BatchesProvider.deleteBatchItem(this.props.batchitem.id, (error, statuCode, response) => {
      AppDispatcher.postNotification("kReloadBatchItems", {id: this.props.batchitem.batchId})
      this.props.refresh();
    })
  }
  errorTooltip = (batchitem) => {
    if(Array.isArray(batchitem.attempts) && (batchitem.attempts.length > 0)){
      let len = batchitem.attempts.length;
      let error = batchitem.attempts[batchitem.attempts.length -1 ].error;
      let message = "Something went wrong."
      if(error && (typeof error === "object") && (!Array.isArray(error))){
        message = batchitem.attempts[batchitem.attempts.length -1 ].error.message || batchitem.attempts[batchitem.attempts.length -1 ].error.reason;
      }else if(error && (typeof error === "string")){
        message = error;
      }
      try {
        message = (Object.prototype.toString.call(message) == "[object Object]") ? Object.values(message)[0] : message;
      } catch(e) {
        console.error(e);
      }      
      return message;
    }
    return "";  
  }
  render() {
    let batchitem = this.props.batchitem
    let contentType = (batchitem.metadata.contentTypeValue || "-") + " | " + (batchitem.metadata.fileType || "-")

    let actions = []
    actions.push(
      <IconButton style={{zIndex:"0"}} tooltip="show info" key="show-info" onClick={this.showItemDetails}>
        <ActionInfo />
      </IconButton>
    )

    if (BatchesProvider.canDeleteBatchItem(batchitem)) {
      actions.push(
        <IconButton style={{zIndex:"0"}} tooltip="delete" key="delete" onClick={this.deleteItem}>
          <ActionDelete />
        </IconButton>
      )
    }
    
    let columns = []
    let THIS = this
    ItemDisplay.dataPoints(this.props.template).forEach(function(point) {
      var value = ""
      var valueAction = ""
      var errorInfo = ""
      switch(point.tag) {
        case "itemUID":     {value = batchitem.uid; break;}
        case "itemId":      { value = batchitem.id; break;}
        case "itemType":    { value = batchitem.type; break;}
        case "itemName":    { value = (batchitem.metadata ? batchitem.metadata.name : "-"); break;}
        case "itemSubject": { value = (batchitem.metadata ? batchitem.metadata.subject : "-"); break;}
        case "itemGrade":   { value = (batchitem.metadata ? batchitem.metadata.grade : "-"); break;}
        case "itemSchools": { 
          let schoolCount = (batchitem.metadata && batchitem.metadata.schoolIds) ? batchitem.metadata.schoolIds.length : 0

          value = schoolCount + " school(s)" 
          break;
        }
        case "contentType": { value = contentType; break;}
        case "itemStatus":  {
          value = batchitem.status;
          if(batchitem.status === 'failed'){
            errorInfo = <IconButton 
                            iconStyle={{width:"12px", height:"12px"}}
                            tooltip={THIS.errorTooltip(batchitem)} key="show" 
                            tooltipPosition="right">
                            <ActionInfo />
                          </IconButton>
          } 
          break;
        }
        case "itemSection": { 
          value = (batchitem.metadata && batchitem.metadata.section) ? batchitem.metadata.section : "-"
          break;
        }
        case "itemPurpose": { 
          value = (batchitem.metadata && batchitem.metadata.purposes) ? JSON.stringify(batchitem.metadata.purposes) : "-"
          break;
        }
        case "itemTopic": { 
          value = (batchitem.metadata && batchitem.metadata.topic) ? batchitem.metadata.topic.name : "-"
          break;
        }
        case "itemSubtopic1": { 
          value = (batchitem.metadata && batchitem.metadata.topic && batchitem.metadata.topic.subtopic) ? batchitem.metadata.topic.subtopic.name : "-"
          break;
        }

        case "itemState":   { 
          var item_state = "-"
          if (["completed", "failed"].indexOf(batchitem.status) >= 0) {
            item_state = batchitem.status + " @" + new Date(batchitem.completedAt) 
          }
          else if (batchitem.currentWorker) {
            item_state = batchitem.currentWorker.worker + " @ " + new Date(batchitem.currentWorker.at)
          }
      
          value = item_state; 
          break;
        }
        case "itemassetID":     { value = (batchitem.progression && (batchitem.progression.asset || batchitem.progression.assetId)) ? 
          (batchitem.progression.asset ? batchitem.progression.asset.id : batchitem.progression.assetId) : "-"; break;}
        case "itemassetUID":     { value = (batchitem.metadata && batchitem.metadata.assetUID) ? batchitem.metadata.assetUID : "-"; break;}
        case "itemcartridgeUID":  { value = (batchitem.metadata && batchitem.metadata.cartridgeUID) ? batchitem.metadata.cartridgeUID : "-"; break;}           
        case "rplus":       { value = (batchitem.progression && batchitem.progression.book) ? batchitem.progression.book.id : "-"; break;}
        case "sourceUrl":       { value = (batchitem.progression && batchitem.progression.source) ? batchitem.progression.source.url : "-"; break;}
        case "itemcartridgeID":       { value = (batchitem.progression && (batchitem.progression.cartridge || batchitem.progression.cartridgeId)) ? 
          (batchitem.progression.cartridge ? batchitem.progression.cartridge.id : batchitem.progression.cartridgeId) : "-"; break;}
        case "rplusprd":    { value = (batchitem.progression && batchitem.progression.book) ? batchitem.progression.book.productId : "-"; break;}
        case "rplusprdlink":    { value = (batchitem.progression && batchitem.progression.book) ? batchitem.progression.book.rumbaUrl : "-"; break;}
        case "rplusaol":    { 
          try {
            value = batchitem.progression.book.metadata.desktopUrl
          }
          catch (error) {
            value = "-"
          }
          
          break;
        }
        case "itemAttempts":{
          value = " " + (batchitem.attempts ? batchitem.attempts.length : 0) + " attempts"
          valueAction = <IconButton 
                          iconStyle={{width:"12px", height:"12px"}} 
                          style={{width:"12px", height:"12px", zIndex:"0"}} 
                          tooltip="show" key="show" 
                          onClick={THIS.showItemAttempts}>
                          <ActionInfo />
                        </IconButton>

          break
        }
        case "itemResult":  {
          value = " "
          var comma = ""
          if (batchitem.progression) {
            for (var progressionKey in batchitem.progression) {
              value = value + comma + progressionKey
              comma = ", "
            }
          }

          valueAction = <IconButton 
                          iconStyle={{width:"12px", height:"12px"}} 
                          style={{width:"12px", height:"12px", zIndex:"0"}} 
                          tooltip="show" key="show" 
                          onClick={THIS.showItemResults}>
                          <ActionInfo />
                        </IconButton>

          break
        }
        case "actions":     { value = actions; break;}
        
      }
      columns.push(<TableRowColumn className={point.cssClass}>{valueAction}{value}{errorInfo}</TableRowColumn>)
    })



    return (
      <TableRow key={batchitem.id} className={batchitem.status}>
        {columns}
      </TableRow>
    )
  }

  showItemDetails = (e) => {
    let batchitem = this.props.batchitem
    let strJson = batchitem ? JSON.stringify(batchitem, null, 4) : ""
    let info = {
      sender: this, 
      title: "Batch Item: " + batchitem.id,
      content: strJson
    }

    AppDispatcher.postNotification("pop-it-up", info)

  }

  showItemResults = (e) => {
    let batchitem = this.props.batchitem
    let strJson = batchitem.progression ? JSON.stringify(batchitem.progression, null, 4) : "no progress available yet"
    let info = {
      sender: this, 
      title: "Progress for batch item: " + batchitem.id,
      content: strJson
    }

    AppDispatcher.postNotification("pop-it-up", info)

  }

  showItemAttempts = (e) => {
    let batchitem = this.props.batchitem
    let strJson = batchitem.attempts ? JSON.stringify(batchitem.attempts, null, 4) : "no attempts available yet"
    let info = {
      sender: this, 
      title: "Attempts for batch item: " + batchitem.id,
      content: strJson
    }

    AppDispatcher.postNotification("pop-it-up", info)

  }
  
}

export default BatchItemsListItem;