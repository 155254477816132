import React from 'react';
import { getAssetTypeList } from '../../utils/utils';
import QikikAdminProvider from '../../providers/QikikAdminProvider';
export default class AssetsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purpose: this.props.purpose ? this.props.purpose : '',
      difficulty: this.props.difficulty ? this.props.difficulty : '',
      bloomsTaxonomy: this.props.bloomsTaxonomy ? this.props.bloomsTaxonomy : '',
      parentNodeId: this.props.node.parentNodeId,
      tocId: this.props.node.tocId, 
      nodeId: this.props.node.nodeId, 
      uuid: this.props.uuid ? this.props.uuid : this.props.structureId,
      structureId: this.props.structureId,
      assetId: this.props.node.assetId,
      name: this.props.node.name.en, 
      assetType: this.props.node.assetType,
      description: this.props.node.description.en,
      tags: this.props.node.hasOwnProperty("tags") ? this.props.node.tags.join("|") : []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRemoveAsset = this.onRemoveAsset.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ids: nextProps.node.ids ? nextProps.node.ids : '',
      assetId: nextProps.node.assetId,
      name: nextProps.node.name.en,
      tocId: nextProps.node.tocId, 
      nodeId: nextProps.node.nodeId,
      parentNodeId: nextProps.node.parentNodeId,
      assetType: nextProps.node.assetType,
      description: nextProps.node.description.en,
      purpose: nextProps.purpose == "{quick-test}" ? this.state.purpose : nextProps.purpose,
      difficulty: nextProps.difficulty == "" ? this.state.difficulty : nextProps.difficulty,
      bloomsTaxonomy: nextProps.bloomsTaxonomy == "" ? this.state.bloomsTaxonomy : nextProps.bloomsTaxonomy
     });
  }
  
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    this.showLoader();
    event.preventDefault();
    let reqBody = {
      "name": {
          "en": this.state.name
      },
      "description": {
        "en": this.state.description
      }
    }
    QikikAdminProvider.updatePanel(this.state.tocId, this.state.nodeId, reqBody, this.onUpdatePanel);
  }

  onUpdatePanel = (response) => {
    if(response && response.id) {
      let reqBody = {
        "metadata": {
            "purposes": this.state.purpose.split(","),
            "difficulty": this.state.difficulty,
            "bloomsTaxonomy": this.state.bloomsTaxonomy
        }
      }
      QikikAdminProvider.editAssetDetails(this.state.assetId, reqBody, this.onUpdatePanelARS);
    }
  }

  onUpdatePanelARS = (response) => {
    if(response && response.assetId) {
      this.props.updateToc(this.state.uuid);
      alert("Asset has been Updated Successfuly!!");  
      this.props.stateupdate();
      this.hideLoader();
    }
  }

  removeAsset = (event) => {
    this.showLoader();
    event.preventDefault();
    QikikAdminProvider.removeAssetDetails(this.state.tocId, this.state.nodeId, this.onRemoveAsset);
  }

  onRemoveAsset = (response) => {
    if(response && response.ToC) {
      alert(response.ToC);
      this.setState({
        name: '',
        description: '',
        purpose: '',
        difficulty: '',
        bloomsTaxonomy: '',
        assetId: ''
      });
      this.props.updateToc(this.state.uuid);
      this.props.stateupdate();
      this.hideLoader();
    }
  }

  hideLoader() {
    document.getElementById("loader2").style.display = "none";
  }

  showLoader() {
    document.getElementById("loader2").style.display = "block";
  }

  render() {
    return (
      <div className="p-20">
        <h3>Assets Panel</h3>
        <div className="w-50">
        <form  onSubmit={this.handleSubmit}>
          
          <label for="assetType">Asset Type</label>
          <select id="assetType" name="assetType" onChange={this.handleChange} value={this.state.assetType}>
            {getAssetTypeList()}
          </select>

          <label for="name">Name</label>
          <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
          
          <label for="description">Description</label>
          <input type="text" name="description" value={this.state.description} onChange={this.handleChange} />

          <label for="purpose">Purpose</label>
          <input type="text" name="purpose" value={this.state.purpose} onChange={this.handleChange} />

          <label for="difficulty">Difficulty</label>
          <input type="text" name="difficulty" value={this.state.difficulty} onChange={this.handleChange} />

          <label for="bloomsTaxonomy">Bloom's Taxonomy</label>
          <input type="text" name="bloomsTaxonomy" value={this.state.bloomsTaxonomy} onChange={this.handleChange} />

          <label for="assetId">ID</label>
          <input type="text" name="assetId" value={this.state.assetId} onChange={this.handleChange} />
          
          <label for="tags">Tags</label>
          <input type="text" name="tags" value={this.state.tags} onChange={this.handleChange} />

          <input type="submit" value="Submit" />
        </form>
        </div>
        <div className="button-container">
          <button  onClick={this.removeAsset} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Remove asset</button>
        </div>
   </div>

    );
  }
}