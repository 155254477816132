const settings = {
  limitToCurrentUser: true,
  enablAllBatchItemsView: false
}
class SettingsProvider {
    setBool = (key, value) => {
        settings[key] = value
    }
    getBool = (key) => {
        return settings[key]
    }
    
}
export default new SettingsProvider();

