
let receiversMap = {}

class AppDispatcher {
  addObserver = (name, receiver, callback) => {

    var listeners = receiversMap[name];
    var anObserver = {receiver: receiver, callback: callback}

    if (!receiversMap[name]) {
      listeners = []
      receiversMap[name] = listeners
    }

    if (!findObserver(name, anObserver)) {
      listeners[listeners.length] = anObserver
    }
  }

  removeObserver = (name, receiver, callback) => {
    //TODO: remove everything for this receiver.

    var observer = findObserver(name, {receiver: receiver, callback: callback})
    var index = observer ? receiversMap[name].indexOf(observer) : -1
    if (index >= 0) {
      receiversMap[name].splice(index, 1)
    }
  }

  postNotification = (name, info) => {
    var listeners = receiversMap[name]
    if (listeners) {
      listeners.forEach(function(anObserver) {
        var cb = anObserver.callback
        cb({name: name, info: info})
      })
    }
  }

}
export default new AppDispatcher();


function findObserver(name, observer) {
  var listeners = receiversMap[name]
  var ret = null
  if (listeners) {
    for (var i = 0; i < listeners.length; i++) {
      var anObserver = listeners[i]
      if (anObserver.receiver === observer.receiver && anObserver.callback === observer.callback) {
        ret = anObserver
        break
      }
    }
  }

  return ret
}
