import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import ActionSetting from 'material-ui/svg-icons/action/settings';

import UserProvider from '../../Providers/UserProvider';
import AppDispatcher from '../../utils/dispatcher/AppDispatcher';
import AdminSettings from '../AdminSettings/AdminSettings';

class UserHeader extends React.Component {
  state = {
    // schoolSwitcherOpen: false
  };
  onOpenDashboard = (e) => {
    AppDispatcher.postNotification("kOpenDashboard", {})
  };
  onOpenSaDashboard = () => {
    AppDispatcher.postNotification("kOpenSaDashboard", {})
  }
  onLogoutClick = (e) => {
    UserProvider.logout()
  };
  openSettings = () => {
    let info = {
      sender: this,
      title: "Admin Controls",
      content: <AdminSettings />
    }
    AppDispatcher.postNotification("pop-it-up", info)
  }
  openHealthCheckDashboard = () => {
    AppDispatcher.postNotification("kOpenHealthDashboard", {})

  }
  openOAEToCPoC = () => {
    AppDispatcher.postNotification("kOpenToCPoC", { type: "oaep" })
  }
  openHNOToCPoC = () => {
    AppDispatcher.postNotification("kOpenToCPoC", { type: "hno" })
  }
  openQIKToCPoC = () => {
    AppDispatcher.postNotification("kOpenToCPoC", { type: "qik" })
  }
  openQIKAdmin = () => {
    AppDispatcher.postNotification("openQIKAdmin", { type: "qik" })
  }
  render() {
    var admin_control = ""
    var healthDashboard = ""
    var toCPoC1 = ""
    var toCPoC2 = ""
    var toCPoC3 = ""
    if (UserProvider.userIsAdmin()) {
      admin_control = <FlatButton
        labelStyle={{ textTransform: "none" }}
        label="SETTINGS"
        onClick={this.openSettings}
      />
      healthDashboard = <FlatButton
        labelStyle={{ textTransform: "none" }}
        label="HEALTH"
        primary={Boolean(this.props.viewing === "healthdashboard")}
        onClick={this.openHealthCheckDashboard}
      />
      toCPoC1 = <FlatButton
        labelStyle={{ textTransform: "none" }}
        label="OAEP ToC"
        primary={Boolean(this.props.viewing === "tocpoc:oaep")}
        onClick={this.openOAEToCPoC}
      />
      toCPoC2 = <FlatButton
        labelStyle={{ textTransform: "none" }}
        label="HNO ToC"
        primary={Boolean(this.props.viewing === "tocpoc:hno")}
        onClick={this.openHNOToCPoC}
      />
      toCPoC3 = <FlatButton
        labelStyle={{ textTransform: "none" }}
        label="QIK ToC"
        primary={Boolean(this.props.viewing === "tocpoc:qik")}
        onClick={this.openQIKToCPoC}
      />
    }
    
    return (
      <MuiThemeProvider>
        <div className="w3-top">
          <div className="w3-bar w3-light-grey w3-wide w3-padding w3-card-2">
            <p className="w3-bar-item"><b>Pulse: </b>Content Pipeline</p>
            <div className="w3-right w3-hide-small">
              {(UserProvider.isUserCanAccessQIKIKAdmin()) ?
                <FlatButton
                  labelStyle={{ textTransform: "none" }}
                  label="QIKIK Admin"
                  primary={Boolean(this.props.viewing === 'qikikAdmin:qik')}
                  onClick={this.openQIKAdmin}
                /> :
                ''}
              {toCPoC1}
              {toCPoC2}
              {toCPoC3}
              {healthDashboard}
              {admin_control}
              <FlatButton
                labelStyle={{ textTransform: "none" }}
                label="HELP GUIDE"
                target="_blank"
                href={"https://docs.google.com/presentation/d/1tW7XRmKdQLWc4nAxT-NqP93sHpVomNWKQyWdsS0-c9s/edit#slide=id.p4"}
              />
              <FlatButton
                labelStyle={{ textTransform: "none" }}
                label="BATCHES"
                primary={Boolean(this.props.viewing === "batches")}
                onClick={this.onOpenDashboard}
              />
              {
                UserProvider.userHasPermission("monitor_batch_status") && <FlatButton
                  labelStyle={{ textTransform: "none" }}
                  label="SA BATCHES"
                  primary={Boolean(this.props.viewing === "saBatches")}
                  onClick={this.onOpenSaDashboard}
                />
              }
              <FlatButton
                labelStyle={{ textTransform: "none" }}
                label={"logout [" + UserProvider.getCurrentUser().name + "]"}
                secondary={true}
                onClick={this.onLogoutClick}
              />
              {/* // <a href="#home" className="w3-bar-item w3-button" onClick={this.onOpenDashboard}>Batches | </a>
               <a href="#logout" className="w3-bar-item w3-button" onClick={this.onLogoutClick}>Logout [{UserProvider.getCurrentUser().name}]</a> */}
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  };
}

export default UserHeader;