import React from 'react';
import QikikAdminProvider from '../../providers/QikikAdminProvider';
import axios from 'axios';
import AssetsPreview from './AssetsPreview';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import Dialog from 'material-ui/Dialog';
import HawkHeaders from '../../../../utils/HawkHeaders'
import BatchesProvider from '../../../../Providers/BatchesProvider'

export default class CreateAssets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetType: this.props.assetType,
      canShowPreview: false,
      generateHTMLResponse: {},
      isBatchNotSuccessful: false,
      error: null,
      batch: {},
      course:{},
      tocCartridgeAssetsUID: "",
      assetId:""
   };
    this.isFileAdded = false;
    this.selectedFile = null;
    this.handleChange = this.handleChange.bind(this);
    this.onProcessFile = this.onProcessFile.bind(this);
    this.onFileAdded = this.onFileAdded.bind(this);
    this.generateUGCAuthorization = this.generateUGCAuthorization.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }
  onClose = () => {
    this.setState({canShowPreview: false})
  }

  getCourseById = (response) => {
    try {
      if(response){
        this.setState({
            course: response
        }, () => {
          console.log("course by Id", response);
        })
      }
    } catch(err){
      console.log(err);
    }
  }

//   createBatch = (response) => {
//       if(response.id){
//         this.setState({"tocCartridgeAssetsUID":response.metadata.assetdocxUID});
//         BatchesProvider.batchItemAndLinks(this.state.batch.id, toc_nodes, this.tocNodes)
//       }
//   }

  tocCartridgeAssets = (response) => {
    console.log("ParkingLot Provider:::", response);
    let toc_nodes = [{
        "type": "toc-nodes",
        "assetIds": response.progression.assetIds,
        "productModelId": `${this.props.node.productModelId}`,
        "tocId": `${this.props.node.tocId}`,
        "parentNodeId": `${this.props.node.nodeId}`
    }]
    this.setState({"tocCartridgeAssetsUID":response.uid});
    BatchesProvider.batchItemAndLinks(response.batchId, toc_nodes, this.tocNodes)
  }

  tocVideoCartridgeAssets = (response) => {
    console.log("ParkingLot Provider:::", response);
    let toc_nodes = [{
        "type": "toc-nodes",
        "assetUID": response.metadata.assetUID,
        "productModelId": `${this.props.node.productModelId}`,
        "tocId": `${this.props.node.tocId}`,
        "parentNodeId": `${this.props.node.nodeId}`
    }]
    this.setState({"tocCartridgeAssetsUID":response.uid});
    BatchesProvider.batchItemAndLinks(response.batchId, toc_nodes, this.tocNodes)
  }

  tocNodes = (response) => {
    let adapt_toc = [{
        "type": "adapt-toc",
        "tocNodesUID": `${response.uid}`,
        "tocCartridgeAssetsUID": `${this.state.tocCartridgeAssetsUID}`,
        "productModelId": `${this.props.node.productModelId}`,
        "tocId": `${this.props.node.tocId}`,
        "parentNodeId": `${this.props.node.nodeId}`
    }]
    BatchesProvider.batchItemAndLinks(response.batchId, adapt_toc, this.adaptToc)
  }

  adaptToc = (response) => {
    if(response){
      localStorage.setItem('batchId', response.batchId);
      console.log("reponse adapt toc", response);
      this.hideLoader();
      this.props.onCancel();
    }
  }

  postBatch = () => {
    try {
      const batch_header = this.generatePulseAuthorization(`/v1/batch`, 'POST');
      let batch_create_data = {
          "batch": {
              "name": `${this.props.courseId}`,
              "createdBy": 'qikik-dashboard',
              "description": 'qikik-description',
              "type": "generic",
              "tags": [`${this.props.courseId}`]
          }
      }
      return axios.post(process.env.REACT_APP_PARKINGLOT_PLA_URL + '/v1/batch', batch_create_data, batch_header).then( (res) => {
      let { data } = res;

      this.setState({ batch: data }, () => {
        localStorage.setItem('batchId', data.id);
        console.log("id:", data.id);
      });
    });  
    } catch(err){
      console.log(err);
    }
  };

  async onProcessFile(event) {
    event.preventDefault();
    console.log('this.state.assetType', this.state.assetType);
    if (this.state.assetType == 'addAsset' && this.isFileAdded) {
      this.showLoader();
      const data = new FormData();
      data.append('file', this.selectedFile);
      let filename = data.get('file');
      console.log(filename.name);
      localStorage.setItem('docxFileName', filename.name);
        const config = { headers: { 'content-type': 'multipart/form-data'}}
        axios.post('/api/upload', data, config).then((response) =>{
          console.log('response', response);
          if (response && response.data && response.data.status === 'success') {
          //this.setState({fileName: response.data.filename})
          // TODO: Set the correct countrycode value
          axios.post('/api/generateHtml', {filename: response.data.filename, countryCode: 'IN'}).then((response) => {
            console.log('RESPONSE ---> ', response);
            this.hideLoader();
            if (response && response.data && response.data.status == 'failed') {
            this.setState({error: response.data.error});    
            this.setState({isBatchNotSuccessful: true, message: response.data.error});
            } else {

            this.setState({canShowPreview: true, generateHTMLResponse: response.data});
            }
          })
          } else {
          this.setState({error: response.data.desc});
          }
        })
    } else if (this.state.assetType == 'addExistingAsset') {
      if(this.state.assetId != ""){
      this.showLoader();
      await QikikAdminProvider.getCourseById(this.props.courseId, this.getCourseById);
      let assetIds = this.state.assetId.split(',');
      const authorization_header = await HawkHeaders.generateMacHeader(process.env.REACT_APP_ARS_URL, '/v1/pulse/asset/fetch', process.env.REACT_APP_ARS_HAWK_ID, process.env.REACT_APP_ARS_HAWK_SECRET, 'POST');
      var authOptions = {
        method: 'POST',
        url: process.env.REACT_APP_ARS_URL+'/v1/pulse/asset/fetch',
        data: {
          "assetIds": assetIds
        },
        headers: {
            'Authorization': authorization_header,
        },
        json: true
      };
      let response = await axios(authOptions);
          if(response.data.length == assetIds.length){

            if(localStorage.getItem("batchId") == null || localStorage.getItem("batchId") == undefined || localStorage.getItem("batchId") == ""){
              await this.postBatch();
            }            
            let batch_toc_cartridge_assets1 = [{
              "type": "toc-cartridge-assets",
              "assetIds": assetIds,
              "cartridgeId": `${this.state.course.cartridgeIds[0]}`
            }]
            BatchesProvider.batchItemAndLinks(localStorage.getItem("batchId"), batch_toc_cartridge_assets1, this.tocCartridgeAssets);
             
          } else {
            alert("Assets not found...");
            this.hideLoader();
            return false;
          }
        } else {
          alert("Must be enter any asset");
          return false;
        } 
        //this.props.setDisablePublish(false);
    } else if (this.state.assetType == 'addAssetViaURL') {
        // Enable the Publish button if the user is adding the asset manually
        this.props.setDisablePublish(false);
    } else if (this.state.assetType == 'uploadVideo') {
        if(!this.selectedFile){
          alert("Please select a video file for upload");
              this.hideLoader();
              return false;
        }
        this.showLoader();
        await QikikAdminProvider.getCourseById(this.props.courseId, this.getCourseById);
        const data = new FormData();
        var allowedExtensions = /(\.mp4|\.zip)$/i;
        if(!allowedExtensions.exec(this.selectedFile.name)){
            alert('Please upload file having extensions .mp4/.zip only.');
            this.selectedFile = null;
            return false;
        }
        if(this.selectedFile.size/1024/1024 > 30){
            alert('Please upload file less than 30 MB');
            this.selectedFile = null;
            this.hideLoader();
            return false;
        }
        data.append('file', this.selectedFile);
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        let response = await axios.post('/api/upload', data, config);
        console.log(response);
        config = {
            headers: {
                'content-type': 'application/json'
            }
        };
        let resp = await axios.post('/api/create/docx/upload', {filename: response.data.filename}, config);
        console.log(resp);

        let fileType = this.selectedFile.type.split("/");
        let assetField = {};
        assetField.metadata = {};
        assetField.type = "asset";
        assetField.createdBy = "qikik";
        assetField.metadata.name =  this.state.videoname;
        assetField.metadata.sourceUrl = resp.data.Location;
        assetField.metadata.countryCode = "IN";
        assetField.metadata.fileType = fileType[1];
        assetField.metadata.contentTypeValue = fileType[0];
        assetField.metadata.isRaaSEnabled = "TRUE";
        assetField.metadata.purposes = ["learn"];
        assetField.metadata.contentUrn = this.state.videoassetid;
        assetField.metadata.thumbnail = this.state.videothumbnail;


        if(localStorage.getItem("batchId") == null || localStorage.getItem("batchId") == undefined || localStorage.getItem("batchId") == ""){
          await this.postBatch();
        }
        
        let batchItemdata = {
          "items": [assetField]
        }

        const batchItemHeader = await this.generatePulseAuthorization(`/v1/batch/${localStorage.getItem("batchId")}/items`, 'POST');
        let responseBatchItem = await axios.post(process.env.REACT_APP_PARKINGLOT_PLA_URL + `/v1/batch/${localStorage.getItem("batchId")}/items`, batchItemdata, batchItemHeader);
        let batch_toc_cartridge_assets1 = [{
            "type": "toc-cartridge-assets",
            "assetUID": `${responseBatchItem.data.created[0].uid}`,
            "cartridgeId": `${this.state.course.cartridgeIds[0]}`
        }]
        BatchesProvider.batchItemAndLinks(responseBatchItem.data.created[0].batchId, batch_toc_cartridge_assets1, this.tocVideoCartridgeAssets);
            
        
                //   console.log(resp, "response upload video");
                //   console.log(assetField, "aassertield");

                //     if (resp.data && resp.data.status == 'failed') {
                //         this.setState({
                //             isBatchNotSuccessful: true,
                //             batchId: resp.data.batchId
                //         });
                //         this.props.setDisablePublish(true);
                //     } else {
                //         this.props.setDisablePublish(false);
                //     }
                //     console.log('create asset response', resp);
                // });
    }
}

  generatePulseAuthorization = (uri, method) => {
    const authorization_header = HawkHeaders.generateMacHeader(process.env.REACT_APP_PARKINGLOT_PLA_URL, uri, process.env.REACT_APP_PARKINGLOT_PLA_HAWK, process.env.REACT_APP_PARKINGLOT_PLA_HAWK_SECRET, method);
    const config = { headers: { 'Content-Type': 'application/json','Authorization': authorization_header}}
    return config;
  }
  
  generateUGCAuthorization = (uri) => {
    const authorization_header = HawkHeaders.generateMacHeader(process.env.REACT_APP_UGC_URL, uri, process.env.REACT_APP_UGC_URL_HAWK_ID, process.env.REACT_APP_UGC_URL_HAWK_SECRET, 'POST');
    const config = { headers: { 'Content-Type': 'multipart/form-data','Authorization': authorization_header}}
    return config;
  }



  setIsBatchNotSuccess = (batchId) => {
    let message = `Assets are getting processed, your batchId is ${batchId}, you can find the batch status under the Batches Tab and use the assetIds once it's completed to map it to TOC`
    this.setState({isBatchNotSuccessful: true, batchId, message})
    this.props.setDisablePublish(true);
  }

  onFileAdded(e) {
    this.isFileAdded = true;
    const files = e.target.files;
    if (files && files[0]) {
      this.selectedFile = files[0];
    } 
  }

  addExistingAssetId(e){
    e.preventDefault();
    this.setState({assetId:e.target.value}, () => {
      console.log("Updating the value:::", e.target.value);
    })
  }

  hideMe = () => {
    this.setState({isBatchNotSuccessful: false, batchId: '', message: ''});
  }

  hideLoader() {
    document.getElementById("loader").style.display = "none";
  }

  showLoader() {
    document.getElementById("loader").style.display = "block";
  }

  render() {
    let {error, isBatchNotSuccessful, canShowPreview, generateHTMLResponse, batchId, message} = this.state;
    return (
      <div>
      { canShowPreview && <AssetsPreview courseId={this.props.courseId} node={this.props.node} data={generateHTMLResponse} open={true} onClose={this.onClose} setIsBatchNotSuccess={this.setIsBatchNotSuccess} setDisablePublish={this.setDisablePublish} postBatch={this.postBatch} /> }
      { isBatchNotSuccessful && <ShowPopup  message={message} open={true} hideMe={this.hideMe}/> }
      { 
        this.state.assetType == 'addAsset' ?
        <div className="p-20">
          <h3>Add Asset (Docx)</h3>
          <div className="w-50">
            <form  onSubmit={this.onProcessFile}>
              <br /> 
              Upload docx File : 
              <input type="file" name="docxFile" id="docxFile" onChange={this.onFileAdded} />
              
              <br />
              <input type="submit" value="Submit" />
          </form>
          </div>
          <div className="button-container">
            <button  onClick={this.props.onCancel} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Cancel</button>
          </div>
        </div> : this.state.assetType == 'addExistingAsset' ? 
        <div className="p-20">
          <h3>Add Existing Asset</h3>
          <div className="w-50">
            <form  onSubmit={this.onProcessFile}>
              <br /> 
              <label for="assetId">Add Asset Id</label>
              <input type="text" name="assetId" value={this.state.assetId} onChange={this.handleChange} />              
              <br />
              <input type="submit" value="Submit" />
          </form>
          </div>
          <div className="button-container">
            <button  onClick={this.props.onCancel} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Cancel</button>
          </div>
        </div> : this.state.assetType == 'addAssetViaURL' ? 
        <div className="p-20">
          <h3>Add Asset Via URL</h3>
          <div className="w-50">
            <form  onSubmit={this.onProcessFile}>
              <br /> 
              <label for="assetViaURL">Add Asset URL</label>
              <input type="text" name="assetViaURL" value='' />
              
              <br />
              <input type="submit" value="Submit" />
          </form>
          </div>
          <div className="button-container">
            <button  onClick={this.props.onCancel} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Cancel</button>
          </div>
        </div> : this.state.assetType == 'uploadVideo' ? 
        <div className="p-20">
          <h3>Upload Video</h3>
          <div className="w-50">
            <form  onSubmit={this.onProcessFile}>
              <br /> 
              <label for="videoname">Name</label>
              <input type="text" name="videoname" value={this.state.videoname} onChange={this.handleChange} />              
              <br />
              <label for="videothumbnail">Thumbnail</label>
              <input type="text" name="videothumbnail" value={this.state.videothumbnail} onChange={this.handleChange} />              
              <br />
              <label for="videoassetid">Asset Id</label>
              <input type="text" name="videoassetid" value={this.state.videoassetid} onChange={this.handleChange} />              
              <br /> 
              Upload video File : 
              <input type="file" name="videoFile" id="videoFile" onChange={this.onFileAdded} />
              
              <br />
              <input type="submit" value="Submit" />
          </form>
          </div>
          <div className="button-container">
            <button  onClick={this.props.onCancel} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Cancel</button>
          </div>
        </div> : ''
      }
      </div> 
    );
  }
}


const ShowPopup = ({message, hideMe}) => {
  return (
      <MuiThemeProvider>
        <div>
          <Dialog
          title={"Batch Status"}
          modal={true}
          actions={<button  onClick={hideMe} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Close</button>}
          style={{whiteSpace:"pre", fontFamily: "monospace"}}
          contentStyle={{width: "90%", maxWidth: "none", height:"100%", maxHeight:"100%"}}
          autoDetectWindowHeight={true}
          open={true}
          onRequestClose={hideMe}
          autoScrollBodyContent={true}        >
            <div>
              {message}
            </div>
        </Dialog>
        </div>
        </MuiThemeProvider>    
  )
}
