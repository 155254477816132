import WebUtil from '../utils/WebUtil'
import HawkHeaders from '../utils/HawkHeaders'
import { Promise } from 'bluebird';

let apipaths = {
    assignmentsByNodeId: "/v2/{tenantTag}/assignments/tocNode/{nodeId}/fetch",
    assignmentsLinks :"/v2/{tenantTag}/nodelinks/{nodeId}/fetch",
    assignmentAssets:"/v2/{tenantTag}/assignments/{assignmentId}/assignmentassets/fetch"
}

class AAPI {

    fetchAssignments = (tenantTag, nodeId) => {
        var path = apipaths.assignmentsByNodeId.replace("{tenantTag}", tenantTag).replace("{nodeId}", nodeId);

        const headers = {
            authorization: HawkHeaders.generateMacHeader(AAPI_url(), path, hawkId(), hawkSecret(), 'GET')
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "GET",
            url: AAPI_url() + path,
            headers: headers
        }
        return new Promise((resolve, reject) => {



            WebUtil.execute(request.method, request.url, request.headers, request.body, function (statusCode, responseString) {

                var response = null
                var error = null
                if (statusCode == 200) {
                    try {
                        response = JSON.parse(responseString);
                        return resolve(response)
                    }
                    catch (err) {
                        error = err
                        return reject(error);
                    }
                } else {
                    return reject(responseString)
                }
            })
        })
    }
    fetchLink =( tenantTag, nodeId) => {

        var path = apipaths.assignmentsLinks.replace("{tenantTag}", tenantTag).replace("{nodeId}", nodeId);

        const headers = {
            authorization: HawkHeaders.generateMacHeader(AAPI_url(), path, hawkId(), hawkSecret(), 'GET')
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "GET",
            url: AAPI_url() + path,
            headers: headers
        }
        return new Promise((resolve, reject) => {
            WebUtil.execute(request.method, request.url, request.headers, request.body, function (statusCode, responseString) {
                var response = null
                var error = null
                if (statusCode == 200) {
                    try {
                        response = JSON.parse(responseString);
                        return resolve(response)
                    }
                    catch (err) {
                        error = err
                        return reject(error);
                    }
                } else {
                    return reject(responseString)
                }
            })
        })

    }

    fetchAssignmentAssets =( tenantTag, assignmentId) => {

        var path = apipaths.assignmentAssets.replace("{tenantTag}", tenantTag).replace("{assignmentId}", assignmentId);

        const headers = {
            authorization: HawkHeaders.generateMacHeader(AAPI_url(), path, hawkId(), hawkSecret(), 'GET')
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };

        let request = {
            method: "GET",
            url: AAPI_url() + path,
            headers: headers
        }
        return new Promise((resolve, reject) => {
            WebUtil.execute(request.method, request.url, request.headers, request.body, function (statusCode, responseString) {
                var response = null
                var error = null
                if (statusCode == 200) {
                    try {
                        response = JSON.parse(responseString);
                        return resolve(response)
                    }
                    catch (err) {
                        error = err
                        return reject(error);
                    }
                } else {
                    return reject(responseString)
                }
            })
        })

    }

 

}

export default new AAPI();

function AAPI_url() { return process.env.REACT_APP_AAPI_URL }
function hawkId() { return process.env.REACT_APP_AAPI_HAWK_ID }
function hawkSecret() { return process.env.REACT_APP_AAPI_HAWK_SECRET }