import WebUtil from '../utils/WebUtil'
import HawkHeaders from '../utils/HawkHeaders'
const uuidV4 = require('uuid/v4')
var Promise = require('bluebird')
// const Q = require("q")

let apipaths = {
    pipelineHealth: '/pipeline/health/status',
    environmentPath: '/environment'
}

class HealthAPI {
    setCronState = (token, url) => {
        let deferred = Promise.pending()
        const headers = {
            token: token
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };
        let request = {
            method: "GET",
            url: url,
            headers: headers
        }
        WebUtil.execute(request.method, request.url, request.headers, request.body, function (statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            deferred.resolve(response)
        })     
        return deferred.promise          
    }

    getDispatchWorkers = (token) => {
        let deferred = Promise.pending()
        let path = apipaths.environmentPath
        const headers = {
            // authorization: HawkHeaders.generateMacHeader(dispatcher_url(), path, hawkId(), hawkSecret(), 'GET')
            token: token
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };
        let request = {
            method: "GET",
            url: dispatcher_url() + path,
            headers: headers
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function (statusCode, responseString) {
            var response = null
            var error = null
            var workersConfig = null
            try {
                response = JSON.parse(responseString);
                workersConfig = response.sails.WorkersConfig
            }
            catch (err) {
                error = err
            }
            deferred.resolve(workersConfig)

        })

        return deferred.promise        
    }

    getPipelineHealth = () => {
        let deferred = Promise.pending()
        let path = apipaths.pipelineHealth
        const headers = {
            authorization: HawkHeaders.generateMacHeader(dispatcher_url(), path, hawkId(), hawkSecret(), 'GET')
            , Accept: "application/json"
            , "user-agent": "ingestion-ui"
            , "accept-language": "en"
        };
        let request = {
            method: "GET",
            url: dispatcher_url() + path,
            headers: headers
        }

        WebUtil.execute(request.method, request.url, request.headers, request.body, function (statusCode, responseString) {
            var response = null
            var error = null
            try {
                response = JSON.parse(responseString);
            }
            catch (err) {
                error = err
            }
            deferred.resolve(response)

        })

        return deferred.promise

    }

}

export default new HealthAPI();

function dispatcher_url() { return process.env.REACT_APP_DISPATCHER_URL }
function hawkId() { return process.env.REACT_APP_PARKINGLOT_PLA_HAWK }
function hawkSecret() { return process.env.REACT_APP_PARKINGLOT_PLA_HAWK_SECRET }