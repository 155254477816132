
import React from 'react';
import UserProvider from '../../Providers/UserProvider';
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import UserHeader from "../UserHeader/UserHeader"
import OAEPToC from "../ToCPoCDashboard/OAEPToCDashboard"
import HNOToC from "../ToCPoCDashboard/HNOToCDashboard"
import QIKToC from "../ToCPoCDashboard/QikToCDashboard"
import BatchesDashboard from "../BatchesDashboard/BatchesDashboard"
import SaBatchesDashboard from "../SaBatchesDashboard/SaBatchesDashboard"
import BatchDetail from "../BatchDetail/BatchDetail"
import HealthCheckDashboard from "../HealthCheckDashboard/HealthCheckDashboard"
import QIKAdmin from "../qikikAdminUI/QikAdminDashboard"
import createCourse from "../qikikAdminUI/QikAdminDashboard"

class Dashboard extends React.Component {
  state = {
    viewing: "batches",
    courseList: true,
    editTOC: false,
    showTOC: false,
    createCourse: false, 
    editCourse: false,
  };

  componentDidMount = () => {
    // AppDispatcher.addObserver("kOpenBookshelf", this, this.onOpenBookshelfNotification)
    AppDispatcher.addObserver("kOpenBatch", this, this.onOpenBatchNotification)
    AppDispatcher.addObserver("kOpenDashboard", this, this.onOpenDashboardNotification)
    AppDispatcher.addObserver("kOpenSaDashboard", this, this.onOpenSaDashboardNotification)
    AppDispatcher.addObserver("kOpenHealthDashboard", this, this.onOpenHealthDashboardNotification)
    AppDispatcher.addObserver("kOpenToCPoC", this, this.onOpenToCPoCNotification)
    AppDispatcher.addObserver("openQIKAdmin", this, this.onOpenQIKAdmin)  
  };

  componentWillUnmount =() => {
    // AppDispatcher.removeObserver("kOpenBookshelf", this, this.onOpenBookshelfNotification)
    AppDispatcher.removeObserver("kOpenToCPoC", this, this.onOpenToCPoCNotification)
    AppDispatcher.removeObserver("kOpenHealthDashboard", this, this.onOpenHealthDashboardNotification)
    AppDispatcher.removeObserver("kOpenDashboard", this, this.onOpenDashboardNotification)
    AppDispatcher.removeObserver("kOpenSaDashboard", this, this.onOpenSaDashboardNotification)
    AppDispatcher.removeObserver("kOpenBatch", this, this.onOpenBatchNotification)
    // AppDispatcher.removeObserver("kOpenCourse", this, this.onOpenCourseNotification)
  };


  onOpenHealthDashboardNotification = (notification) => {
    this.setState({
      viewing: "healthdashboard",
      batch: null
    })

  }

  onOpenQIKAdmin = (notification) => {
    let type = notification.info.type
    this.setState({
      viewing: "qikikAdmin" + ":" + type,
      batch: null,
      courseList: true,
      editTOC: false,
      showTOC: false,
      createCourse: false, 
      editCourse: false,
    })
  }

  
  onOpenToCPoCNotification = (notification) => {
    let type = notification.info.type
    this.setState({
      viewing: "tocpoc" + ":" + type,
      batch: null
    })
  }
  onOpenDashboardNotification = (notification) => {
    this.setState({
      viewing: "batches",
      batch: null
    })
  };
  onOpenSaDashboardNotification = (notification) => {
    this.setState({
      viewing: "saBatches",
      batch: null
    })
  };

  onOpenBatchNotification = (notification) => {
    this.setState({
      viewing: "batchDetail",
      batch: notification.info
    })
  }

  setCourseItems = ({ editTOC, courseList, createCourse, editCourse, showTOC }) => {
    this.setState({ editTOC, courseList, createCourse, editCourse, showTOC });
  }

  render() {
    console.log("My states:::::::::::::", this.state)
    console.log("env: ", process.env.NODE_ENV)
    var content = null
    if (["batches"].indexOf(this.state.viewing) >= 0) {
      content = <BatchesDashboard />
    }
    else if (["saBatches"].indexOf(this.state.viewing) >= 0) {
      content = <SaBatchesDashboard />
    }
    else if ("batchDetail" === this.state.viewing) {
      content = <BatchDetail batch={this.state.batch} />
    }
    else if ("healthdashboard" === this.state.viewing) {
      content = <HealthCheckDashboard />
    }
    else if ("tocpoc:oaep" === this.state.viewing) {
      content = <OAEPToC />
    }
    else if ("tocpoc:hno" === this.state.viewing) {
      content = <HNOToC />
    }
    else if ("tocpoc:qik" === this.state.viewing) {
      content = <QIKToC />
    }
    else if (this.state.viewing === 'qikikAdmin:qik'){
       content = <QIKAdmin showTOC={this.state.showTOC} editTOC={this.state.editTOC} courseList={this.state.courseList} createCourse={this.state.createCourse} editCourse={this.state.editCourse} setCourseItems={this.setCourseItems} />
    }
    
    return (
      <div id="home">
      {<UserHeader />}
      <br/><br/><br/>
      {content}
      {/* <UserFooter /> */}
      </div>
    );
  }
}


export default Dashboard
