import React from 'react';
import QikikAdminProvider from '../../providers/QikikAdminProvider';
import {getThemes} from '../../utils/utils'

export default class ConceptPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tocId: this.props.node.tocId, 
      nodeId: this.props.node.nodeId,
      parentNodeId: this.props.node.parentNodeId,
      name: this.props.node.name.en, 
      description: this.props.node.description.en,
      concept: 'concept',
      topicname: '',
      topicdescription: '',
      topiccolorTheme: '',
      topicthumbnail: '',
      topictags:'',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    if(this.state.concept == "concept"){
    let reqBody = {
        "name": {
              "en": this.state.name
          },
          "description": {
            "en": this.state.description
          }
        }
        this.props.showLoader();
        QikikAdminProvider.updatePanel(this.state.tocId, this.state.nodeId, reqBody, this.onUpdateConceptPanel);
    } else {
      if(!this.state.topicname){
        alert("Topic Name is required");
        return false
      }
      if(!this.state.topicdescription){
        alert("Topic Description is required");
        return false;
      }
      this.state.topictags = this.state.topictags == "" ? [] : this.state.topictags.split("|")

      let reqBody = {
        "name": {
            "en": this.state.topicname
        },
        "description": {
          "en": this.state.topicdescription
        },
        "type": "container",
        "label": "topic",
        "metadata": {
          "themes": this.state.topiccolorTheme,
          "thumbnail": this.state.topicthumbnail
        },
        "tags": this.state.topictags
      }
      this.props.showLoader();
      QikikAdminProvider.insertNode(this.state.tocId, this.state.nodeId, reqBody, this.onAddTopicPanel);
    } 
  }

  onUpdateConceptPanel = (response) => {
    if(response && response.id){
        this.props.updateToc(response.tocId);
    }
  }

  onAddTopicPanel = (response) => {
    if(response && response.id){
        this.onCancel();
        this.props.updateTopicToc(response.id);
    }
  }

  addConcept = (event) => {
   event.preventDefault();
   let reqBody = {
                   "name": {
                       "en": this.state.name
                   },
                   "description": {
                     "en": this.state.description
                   },
                   "type": "container",
                   "label": "concept"
                 }
   QikikAdminProvider.insertNode(this.state.tocId, this.state.parentNodeId, reqBody, this.onAddConceptPanel);
  }

  addTopic = (event) => {
    event.preventDefault();
    this.setState({
      'concept':'topic'
    })
  }

  onAddConceptPanel = (response) => {
    if(response && response.id){
        this.props.updateToc(this.props.structureId);
    }
  }

  onCancel = () => {
    this.setState({
      'concept':'concept'
    })
  }

  render() {
    return (
      <div className="p-20">
        <div className="w-50">
        { this.state.concept == 'concept' ?   
          <form  onSubmit={this.handleSubmit}>
          <h3>Concept Panel</h3>
            <label for="fname">Name</label>
            <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
            
            <label for="fname">Description</label>
            <input type="text" name="description" value={this.state.description} onChange={this.handleChange} />
            <br />
            <input type="submit" value="Submit" />
          </form> : <form  onSubmit={this.handleSubmit}> 
            <h3>Add Topic</h3>      
            <label for="fname">Name</label>
            <input type="text" name="topicname" value={this.state.topicname} onChange={this.handleChange} />

            <label for="lname">Description</label>
            <input type="text" name="topicdescription" value={this.state.topicdescription} onChange={this.handleChange} />

            <label for="colorTheme">Color Theme</label>
            <select id="colorTheme" name="topiccolorTheme" value={this.state.topiccolorTheme} onChange={this.handleChange}>
              {getThemes()}
            </select>

            <label for="fname">Thumbnail</label>
            <input type="text" name="topicthumbnail" value={this.state.topicthumbnail} onChange={this.handleChange} />
            
            <label for="fname">Tags</label>
            <input type="text" name="topictags" value={this.state.topictags} onChange={this.handleChange} />
            <br />
        
            <input type="submit" value="Submit" />
          </form>}
        </div>
        { this.state.concept == 'concept' ?   
        <div className="button-container">
          {/* <button id="addConcept" onClick={this.addConcept} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton"> Add Concept </button> */}
          <button id="addTopic" onClick={this.addTopic} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton"> Add Topic </button>
        </div> : 
        <div className="button-container">
          <button  onClick={this.onCancel} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Cancel</button>
        </div> }

      </div>
    );
  }
}