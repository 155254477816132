import React from 'react';
import {getThemes} from './utils/utils'
import QikikAdminProvider from './providers/QikikAdminProvider'
import Select from "react-select";
import _ from "lodash";

export default class EditCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryList: [],
      selectedoption:'',
      EditCourseCountry:''
    };
    this.state = {...this.props.courseData,
      startDates: this.props.courseData.products[0].startDate.split("T")[0],
      endDates: this.props.courseData.products[0].endDate.split("T")[0],
      product_id: this.props.courseData.products[0].id
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleIsexpiresChange = this.handleIsexpiresChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    this.setState({
      structureId: nextProps.courseData.structureId
     });
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value}, () => console.log(this.state.name));
  }

  handleStartDateChange(event) {
    this.setState({ startDates: event.target.value}, () => console.log(this.state.startDates));
  }

  handleEndDateChange(event) {
    this.setState({ [event.target.name]: event.target.value}, () => console.log(this.state.endDates));
  }

  handleIsexpiresChange() {
    let radios = document.getElementsByName("isEnabled");
    let expires = radios[0].checked == true ? true : false;
    this.setState({ expires})
  }

  componentDidMount() {
    QikikAdminProvider.getCountries(this.setCountries);
  }

  setCountries = (res) => {
    const countryList = res.map((data, index) => {
      return { key: index, label: data.name, value: data.code };
    });

    this.setState({ countryList: countryList });
    const EditCountries = this.props.courseData.countries
     var newCountry = EditCountries.map((data) => {
      const updatedCountry = (this.state.countryList||[]).filter(
        (el) => el.value == data
      );
      return updatedCountry;
    });
    const x =_.flattenDeep(newCountry)
    this.setState({selectedoption:x})

  }

  validateForm = () => {
    if (this.state.selectedoption.length == 0) {
      alert("Please Select Country!");
      return false;
    } else if (this.state.subject == '') {
      alert("Please Provide Subject!");
      return false;
    } else if (this.state.grade == '') {
      alert("Please Provide Grade!");
      return false;
    } else if (this.state.levelId == '' ||  !isUUID(this.state.levelId) ) {
      alert("Please Provide Level Id in uuid format");
      return false;
    } else if (this.state.subjectId == '' ||  !isUUID(this.state.subjectId) ) {
      alert("Please Provide Subject Id in uuid format");
      return false;
    } else if (this.state.name == '') {
      alert("Please Provide Name!");
      return false;
    } else if (this.state.description == '') {
      alert("Please Provide Description!");
      return false;
    } else if (this.state.colorCode == '') {
      alert("Please Provide themes!");
      return false;
    } else if (this.state.thumbnail == '') {
      alert("Please Provide thumbnail!");
      return false;
    } else if (this.state.startDates == '') {
      alert("Please Provide start Date!");
      return false;
    } else if (this.state.endDates == '') {
      alert("Please Provide end Date!");
      return false;
    }
    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.validateForm()) return;
    let reqBody = {
      "name": this.state.name,
      "description":  this.state.description,
      "subject":  this.state.subject,
      "levelId": this.state.levelId,
      "subjectId": this.state.subjectId,
      "grade":  this.state.grade,
      "products": [
        {
          "startDate": this.state.startDates,
          "endDate": this.state.endDates,
          "id": this.state.product_id
        }
      ],
      "countries": this.state.selectedoption.map((data) => data.value),
      "colorCode":  this.state.colorCode,
      "thumbnail":  this.state.thumbnail,
      "expires": this.state.expires,
      "startDate": this.state.startDates,
      "endDate": this.state.endDates
    }
    QikikAdminProvider.editCourse(reqBody, this.state.id, this.onEditCourse);
  }

  onEditCourse = (response) => {
    if(response && response.update) {
        alert("Course has been updated!!");
    }
  }

  onCountryChange = (e) => {
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({countries: value});
  }

  showToC = (e) => {
    this.props.showTOC(this.state.structureId)
  }

  handleChangeDropDown = (selectedoption) => {
    this.setState({ selectedoption });
  };

  render() {
    
    return (
    <div className="p-20">
      <h3>Edit Course</h3>
      <div className="w-50">
        <form  onSubmit={this.handleSubmit}>
          <label htmlFor="country">Country</label>
          <Select
            className="country"
            id="country"
            value={this.state.selectedoption}
            onChange={this.handleChangeDropDown}
            options={this.state.countryList}
            isMulti={true}
            closeMenuOnSelect={false}
          />

          <label htmlFor="subject">Subject</label>
          <input type="text" id="subject" name="subject" value={this.state.subject} onChange={this.handleChange} />

          <label htmlFor="grade">Grade</label>
          <input type="text" name="grade" value={this.state.grade} onChange={this.handleChange} />
          <label htmlFor="levelId">Level Id</label>
          <input type="text" name="levelId" value={this.state.levelId} onChange={this.handleChange} />

          <label htmlFor="subjectId">Subject Id</label>
          <input type="text" name="subjectId" value={this.state.subjectId} onChange={this.handleChange} />
          
          <label htmlFor="name">Name</label>
          <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />

          <label htmlFor="description">Description</label>
          <input type="text" id="description" value={this.state.description} name="description" onChange={this.handleChange} />

          <label htmlFor="colorCode">Color Theme</label>
          <select id="colorCode" name="colorCode" value={this.state.colorCode} onChange={this.handleChange}>
              {getThemes()}
          </select>

          <label htmlFor="thumbnail">Thumbnail</label>
          <input type="text" id="thumbnail" name="thumbnail" value={this.state.thumbnail} onChange={this.handleChange} />

          <label htmlFor="startDates">Start Date</label>
          <input type="text" id="startDates" name="startDates" value={this.state.startDates} onChange={this.handleStartDateChange} />

          <label htmlFor="endDates">End Date</label>
          <input type="text" id="endDates" name="endDates" value={this.state.endDates} onChange={this.handleEndDateChange} />

          <label htmlFor="isEnabled">Expires</label>
          <input type="radio" name="isEnabled" value="true" checked={this.state.expires} onChange={this.handleIsexpiresChange} /> True
          <input type="radio" name="isEnabled" value="false" checked={!this.state.expires} onChange={this.handleIsexpiresChange} /> False
          <br />
      
          <input type="submit" value="Submit" />

        </form>
          { 
            this.state.structureId ?
              <button onClick={this.showToC} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Show TOC</button> : 
              <button  onClick={this.props.editTOC} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Link TOC</button>
          }
          <button  onClick={this.props.onCancel} className="w3-button w3-tiny w3-left-align w3-indigo w3-hover-blue assetButton">Cancel</button>
      </div>
    </div>
    );
  }
}
function isUUID ( uuid ) {
  let s = "" + uuid;

  s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
  if (s === null) {
    return false;
  }
  return true;
}
