import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import AppDispatcher from '../../utils/dispatcher/AppDispatcher'
import FlatButton from 'material-ui/FlatButton';
import csvToJson from 'csvtojson'
import XLSX from 'xlsx';
import ToCRenderer from './ToCRenderer/ToCRenderer2'
import { IconButton } from 'material-ui';
import ActionInfo from 'material-ui/svg-icons/action/info-outline';


class HNOToCDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        selectedFile: {}
    };
    this.onFileAdded = this.onFileAdded.bind(this)
    this.onProcessFile = this.onProcessFile.bind(this)

  };

  onFileAdded = (e) => {
    const files = e.target.files;
    if (files && files[0]) this.setState({ selectedFile: files[0] });
  };

  onProcessFile = (e) => {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
      /* Get first worksheet */
      console.log(JSON.stringify(wb.SheetNames))
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const tocCSV =  XLSX.utils.sheet_to_csv(wb.Sheets["toc"])
      const unitsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["units"])
      const sectionsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["sections"])
      const topicsCSV = XLSX.utils.sheet_to_csv(wb.Sheets["topics"])
      const tasksCSV = XLSX.utils.sheet_to_csv(wb.Sheets["tasks"])

      console.log("toc \n", tocCSV)

      this.setState({
          tocCSV: tocCSV,
          unitsCSV: unitsCSV,
          sectionsCSV: sectionsCSV,
          topicsCSV: topicsCSV,
          tasksCSV: tasksCSV
      }, () => {
          this.parseToC()
      })

    };
    if (rABS) {
        reader.readAsBinaryString(this.state.selectedFile);
      } else {
        reader.readAsArrayBuffer(this.state.selectedFile);
      };

    };

  parseToC = () => {
    let tsvPayload = this.state.tocCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            tocJSON: itemsFromCSV
        }, () => {
            this.parseUnits()
        })
        
    })             
  }

  parseUnits = () => {
    let tsvPayload = this.state.unitsCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            unitsJSON: itemsFromCSV
        }, ()=> {
            this.parseSections()
        })
        
    })             
  }

  parseSections = () => {
    let tsvPayload = this.state.sectionsCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            sectionsJSON: itemsFromCSV
        }, ()=> {
            this.parseTopics()
        })

    })             
  }

  parseTopics = () => {
    let tsvPayload = this.state.topicsCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
          topicsJSON: itemsFromCSV
        }, ()=> {
            this.parseTasks()
        })

    })             
  }

  parseTasks = () => {
    let tsvPayload = this.state.tasksCSV
    csvToJson({
        noheader:false,
        output: "json",
        ignoreEmpty : false,
        delimiter: ","
    })
    .fromString(tsvPayload)
    .then((itemsFromCSV)=>{ 
        this.setState({
            tasksJSON: itemsFromCSV,
        }, () => {
            this.putItTogether()
        })
    })             
  }

  putItTogether = () => {
      let toc = this.state.tocJSON[0]
      let units = this.state.unitsJSON
      let sections = this.state.sectionsJSON
      let topics = this.state.topicsJSON
      let tasks = this.state.tasksJSON
      if (!toc || !units || !sections || !topics || !tasks) {
          alert("missing data")
          return
      }

      let unitsDic = {}
      units.forEach(function(aUnit) {
        if (aUnit.tag) {
          unitsDic[aUnit.tag] = aUnit
          if (!toc.nodes) {
              toc.nodes = []
          }
          toc.nodes.push(aUnit)
          delete aUnit.Duplicate
          delete aUnit.tag
        }
      })

      let sectionsDic = {}
      sections.forEach(function(aSection) {
        if (aSection.tag && aSection.unitTag && aSection.sectionTag) {
          sectionsDic[aSection.sectionTag] = aSection
          let thisUnit = unitsDic[aSection.unitTag]
          if (!thisUnit.nodes) {
              thisUnit.nodes = []
          }
          thisUnit.nodes.push(aSection)

          delete aSection.Duplicate
          delete aSection.tag
          delete aSection.unitTag
          delete aSection.sectionTag
        }
      })

      let topicsDic = {}
      topics.forEach(function(aTopic) {
        if (aTopic.tag && aTopic.sectionTag && aTopic.topicTag) {
          topicsDic[aTopic.topicTag] = aTopic
          let thisSection = sectionsDic[aTopic.sectionTag]
          if (!thisSection.nodes) {
              thisSection.nodes = []
          }
          thisSection.nodes.push(aTopic)

          delete aTopic.Duplicate
          delete aTopic.tag
          delete aTopic.sectionTag
          delete aTopic.topicTag
        }
      })


      tasks.forEach(function(aTask) {
        if (aTask.tag && aTask.topicTag) {
          let thisTopic = topicsDic[aTask.topicTag]
          if (!thisTopic.nodes) {
              thisTopic.nodes = []
          }
          thisTopic.nodes.push(aTask)
          delete aTask.tag
          delete aTask.topicTag
        }
      })


      this.setState({
        toc: toc
      }, () => {
        // this.showToCDetails(toc)
      })
  }

  showToCDetails = (toc) => {
    let strJson = JSON.stringify(toc, null, 4)
    let info = {
      sender: this, 
      title: "ToC",
      content: strJson
    }

    AppDispatcher.postNotification("pop-it-up", info)

  }



  render() {

    let toc = this.state.toc ? <ToCRenderer toc={this.state.toc} /> : ""

    return (
      <MuiThemeProvider>
        <div className="w3-left" style={{width:"100%"}}>
        <br/><br/>
        <div className="w3-left" >
        Upload HNO ToC XLSX (<a href="https://docs.google.com/spreadsheets/d/1CjKs1VBFQBBxnTFK4rIPLvCVy8Us5rgURsUGtvRYOd4/edit?usp=sharing">master template</a>):

            <input type="file" className="form-control" id="file" onChange={this.onFileAdded} />
            <input type='submit'  value="Process ToC" onClick={this.onProcessFile} />
            <IconButton style={{zIndex:"0"}} tooltip="show info" key="show-info" onClick={function() {
                this.showToCDetails(this.state.toc)
            }.bind(this)}>
              <ActionInfo />
            </IconButton>


        </div>
        <br/><br/>
        {toc}
        </div>

        </MuiThemeProvider>
    );
  }

}

export default HNOToCDashboard;