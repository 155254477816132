import React, { Component } from 'react';
import logo from '../../logo.svg';
import './App.css';
import Login from '../Login/Login';
import Dashboard from '../Dashboard/Dashboard';
import UserProvider from '../../Providers/UserProvider';
import AppDispatcher from '../../utils/dispatcher/AppDispatcher';
import injectTapEventPlugin from 'react-tap-event-plugin';
import PopupPresenter from '../PopupPresenter/PopupPresenter'

class App extends Component {
  state = {
    isLoggedIn: false,
    popupPresenterOpen: false
  };
  componentDidMount = () => {
    injectTapEventPlugin();

    UserProvider.initialize()


    this.setState({
      isLoggedIn: UserProvider.isUserLoggedIn()
    })
    AppDispatcher.addObserver(UserProvider.userAuthChangedNotification(), this, this.onAuthChangedNotification)
    AppDispatcher.addObserver("pop-it-up", this, this.onPopItUpNotification)
  };

  componentWillUnmount =() => {
    AppDispatcher.removeObserver(UserProvider.userAuthChangedNotification(), this, this.onAuthChangedNotification)
    AppDispatcher.removeObserver("pop-it-up", this, this.onPopItUpNotification)
  };


  onAuthChangedNotification = (notification) => {
    this.setState({
      isLoggedIn: UserProvider.isUserLoggedIn()
    })
  };

  onPopItUpNotification = (notification) => {
    this.setState({
      popupPresenterOpen: true,
      popupPayload: notification.info
    })
  }


  render() {

    let content = this.state.isLoggedIn ? <Dashboard/> : <Login/>;
    return (
      <div className="App">

        {content}
        <PopupPresenter 
                title={this.state.popupPayload ? this.state.popupPayload.title : ""}
                show={this.state.popupPresenterOpen} 
                content={this.state.popupPayload ? this.state.popupPayload.content : ""}
                onClose={function() {
                  this.setState({
                    popupPresenterOpen: false
                  })
                }.bind(this) }
                />

      </div>
    );
  }

}

export default App;
